import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { path } from 'ramda';
import axios from 'axios';
import { useMutation } from '@apollo/react-hooks';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ExpandMore } from '@material-ui/icons';

import { restEndpoints } from 'constants/domain';
import { compressImage, convertTiffToPng } from 'components/fileuploader/ImageUploader2';
import { KeycloakContext } from 'components/Secured';
import InputLabel from 'components/inputfields/InputLabel';
import { mapChatGptDataToFill, removeNilProperty } from 'components/vision/tabs/chatGPT';
import IProduct, { IRecognizeImage } from 'models/product';
import { S3_SIGNED_REQUEST_MUTATION } from 'graphql/mutations';
import { encodeLastPathUrl } from 'utils/imgix';
import { isBlank } from 'utils/helpers-ts';
import { getUrlParams, updateUrlParams } from 'utils/urlUtils';
import productInfoIcon from 'assets/images/product-info-icon.png';
import useViewport from 'hooks/useViewports';
import { isNewFood } from '../fields/category';
import { IFoodlaCategoryOption } from 'models/category';
import IProducer from 'models/producer';
import ImageTranscribe, { Accordion, AccordionDetails, AccordionSummary, TImageTranscriber } from './ImageTranscriber';
import UploadImageGuideLabels from './UploadImageGuideLabels';
import UploadImageGuideExample from './UploadImageGuideExample';

import correctExampleImg from 'assets/images/upload-image-autofill-correct-example.png';
import wrongExampleImg from 'assets/images/upload-image-autofill-wrong-example.png';
import COLORS from 'constants/colors';

export const ALLOWED_IMAGE_FILE_TYPE_LIST = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/png'];

interface FillProductByImageProps {
  rootCategory?: IFoodlaCategoryOption;
  producerData?: IProducer;
  isFood?: boolean;
  isCreatingNew?: boolean;
  isChangedBrand?: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  onChangedState: () => void;
}

const grid = 4;

const getImageStyle = ({ recognized }: { recognized?: boolean }): React.CSSProperties => ({
  display: 'inline-flex',
  borderRadius: 2,
  height: 120,
  boxSizing: 'border-box',
  cursor: 'pointer',
  opacity: recognized ? 0.3 : 1,
});

const getItemStyle = (
): React.CSSProperties => ({
  maxHeight: 400,
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  borderRadius: 2,
  display: 'flex',
  border: 'none',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: "white",
});

type TSignedRequest = {
  signedRequest: string;
  url: string;
  pictureUrl: string;
  picturePath: string;
  fileName: string;
};

type TSignedRequestResponse = {
  getS3SignRequest: {
    requests: TSignedRequest[];
  };
};

const API_TIMEOUT = 60 * 1000;

interface IRecognizeImageData extends Partial<IProduct> {
  recognizeData?: boolean;
}

interface IRecognizeCategoryData {
  categoryID?: string;
  categoryName?: string;
  categoryTree?: string;
  text?: string;
}

const recognizeImage = async (token: string, imageUrls: string[]) => {
  try {
    if (isBlank(token) || !imageUrls.length) {
      return;
    }
  
    const formData = new FormData();
    imageUrls.forEach((imageUrl) => {
      formData.append('imageUrls', imageUrl);
    });
  
    const { data } = await axios.post<{ data: IRecognizeImageData }>(restEndpoints.aiImage, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = data?.data;
    return result;
  } catch (error: any) {
    console.error('Product info recognition error:', error);
    return;
  }
};

const recognizeImageTranscriber = async (token: string, imageUrls: string[]) => {
  try {
    if (isBlank(token) || !imageUrls.length) {
      return [];
    }
  
    const formData = new FormData();
    imageUrls.forEach((imageUrl) => {
      formData.append('imageUrls', imageUrl);
    });
  
    const { data } = await axios.post<{ data: TImageTranscriber[] }>(restEndpoints.aiImageTranscriber, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = data?.data || [];
    return result;
  } catch (error: any) {
    console.error('Image transcriber error:', error);
    return [];
  }
};

const recognizeCategory = async (token: string, imageUrls: string[]) => {
  try {
    if (isBlank(token) || !imageUrls.length) {
      return;
    }
  
    const formData = new FormData();
    imageUrls.forEach((imageUrl) => {
      formData.append('imageUrls', imageUrl);
    });
  
    const { data } = await axios.post<{ data: IRecognizeCategoryData }>(restEndpoints.aiCategory, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = data?.data;
    return result;
  } catch (error: any) {
    console.error('Category recognition error:', error);
    return;
  }
};

const FillProductByImage: FC<FillProductByImageProps> = ({
  rootCategory,
  producerData,
  isFood,
  isCreatingNew,
  isChangedBrand,
  state,
  setState,
  onChangedState,
}) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx) as string;
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
  const { smUp } = useViewport();

  const keepRef = useRef({ state });
  keepRef.current.state = state;

  const [convertingImage, setConvertingImage] = useState(false);
  const [loadingRecognize, setLoadingRecognize] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modifiedCount, setModifiedCount] = useState(0);
  const [files, setFiles] = useState<{ file: File, recognized?: boolean }[]>([]);
  const [images, setImages] = useState<IRecognizeImage[]>(state.recognizeImages || []);
  const [imageTranscribers, setImageTranscribers] = useState<TImageTranscriber[]>(state.recognizedTexts || []);
  const [isRecognizedImage, setIsRecognizedImage] = useState(false);
  
  const [s3SignedRequest] = useMutation<TSignedRequestResponse>(S3_SIGNED_REQUEST_MUTATION, { refetchQueries: [] });
  const producerName = state?.producerUsername || state?.producerName || '';
  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_IMAGE_FILE_TYPE_LIST.join(', '),
    multiple: true,
    disabled: convertingImage || loadingRecognize,
    onDrop: async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;

      setIsError(false);
      setConvertingImage(true);
      setIsRecognizedImage(false);
      setModifiedCount(0);

      let files = await Promise.all(acceptedFiles.map(async (rawFile) => {
        let file = await convertTiffToPng(rawFile);
        if (!file) return;
  
        file = await compressImage(file, 10);
        return file;
      }))

      files = files.filter(file => !!file);

      if (files.length) {
        setFiles(oldState => [...oldState, ...files.map(file => ({ file: file!, recognized: false }))]);
      }

      setConvertingImage(false);
    },
  });

  const displayFiles = useMemo(() => {
    const list: { name?: string, url?: string, recognized?: boolean }[] = [];
    (state.recognizeImages || []).forEach(({ name, url }) => {
      list.push({ name, url, recognized: true });
    });
    files.forEach(({ file, recognized }) => {
      list.push({ name: file.name, url: URL.createObjectURL(file), recognized })
    });

    return list;
  }, [state.recognizeImages, files]);

  const handleUploadImages = async () => {
    const newFiles = files.filter((item) => !item.recognized);

    const s3SignedRequestInput = {
      ean: keepRef.current.state?.EAN || '',
      producerName: producerName,
      files: newFiles.map(({ file }) => ({ fileName: file.name, fileType: file.type })),
      renameFiles: true,
    };
    console.log('s3SignedRequestInput', s3SignedRequestInput);
    const { data: signedRequestData, errors: signedRequestErrors } = await s3SignedRequest({
      variables: {
        input: s3SignedRequestInput,
      },
    });

    let signedRequests: { file: File, signedRequest: TSignedRequest }[] = [];
    if (signedRequestErrors) {
      console.error('Get Signed Request error:', signedRequestErrors);
    } else {
      const requests = signedRequestData?.getS3SignRequest?.requests || []
      signedRequests = requests.map((signedRequest, index) => {
        return { signedRequest, file: newFiles[index]?.file }
      });
    }

    let imageUrls = await Promise.all(
      signedRequests.map(async ({ signedRequest, file }) => {
        if (!signedRequest || !file) return '';

        let newImageUrl = '';

        try {
          await axios.put(signedRequest.signedRequest, file, {
            timeout: API_TIMEOUT,
            headers: { 'Content-Type': file.type },
          });
          const url = encodeLastPathUrl(signedRequest.pictureUrl);
          if (url) {
            const oldImages = images || [];
            oldImages.push({ url, name: file.name });
            setImages(oldImages);
            newImageUrl = url;
          }
        } catch (error) {
          console.error('Error uploading to S3:', error);
        }

        const { w: imageWidthParams } = getUrlParams(newImageUrl);
        if (imageWidthParams && Number(imageWidthParams) < 1024) {
          return newImageUrl;
        } else {
          return updateUrlParams(newImageUrl, { w: 1024 });
        }
      })
    );

    // Filter out any empty URLs
    return imageUrls.filter(url => !!url);
  }

  const handleRecognizeCategory = async (imageUrls: string[]) => {
    let isFoodAfterUpdate = isFood;

    if (isBlank(keepRef.current.state?.foodlaCategory?.id)) {
      const recognizeCategoryData = await recognizeCategory(token, imageUrls);
      if (!isBlank(recognizeCategoryData?.categoryID) && !isBlank(recognizeCategoryData?.categoryName)) {
        const category = {
          id: recognizeCategoryData!.categoryID!,
          name: recognizeCategoryData!.categoryName!,
        };
        isFoodAfterUpdate = isNewFood(category, rootCategory);
        setState({ ...keepRef.current.state, foodlaCategory: category });
      }
    }

    return { isFoodAfterUpdate };
  }

  const handleRecognizeImagesAndCategory = async (imageUrls: string[]) => {
    const [recognizeImageData, { isFoodAfterUpdate }] = await Promise.all([
      recognizeImage(token, imageUrls),
      handleRecognizeCategory(imageUrls),
    ]);

    if (recognizeImageData?.recognizeData) {
      let { data: updatingState, modifiedCount: count } = mapChatGptDataToFill(
        keepRef.current.state,
        recognizeImageData,
        {
          isFood: isFoodAfterUpdate,
          isChangedBrand,
          producer: producerData,
          isCreatingNew,
        },
      );
      updatingState = removeNilProperty(updatingState);
      if (count) {
        onChangedState();
      }
      setState({ ...keepRef.current.state, recognizeImages: images, ...updatingState });
      setFiles([]);
      setIsError(false);
      setModifiedCount(count);
    } else {
      setIsError(true);
      setModifiedCount(0);
    }

    setIsRecognizedImage(true);
  }

  const handleRecognizeImageTranscribers = async (imageUrls: string[]) => {
    const recognizeImageTranscribers = await recognizeImageTranscriber(token, imageUrls);

    setImageTranscribers(recognizeImageTranscribers.filter( i => !!i.all_text_and_digits));
  }

  const handleRunAI = async () => {
    setLoadingRecognize(true);
    setIsRecognizedImage(false);

    const imageUrls = await handleUploadImages();

    if (imageUrls.length === 0) {
      setIsError(true);
      setLoadingRecognize(false);
      return;
    }

    await Promise.all([
      handleRecognizeImagesAndCategory(imageUrls),
      handleRecognizeImageTranscribers(imageUrls),
    ]);

    setLoadingRecognize(false);
  };

  useEffect(() => {
    if (imageTranscribers.length) {
      const recognizedTexts = imageTranscribers.map((imageTranscriber) => {
        const newTranscribed = {...imageTranscriber};
        if (newTranscribed && typeof newTranscribed.confidence_score === 'string') {
          newTranscribed.confidence_score = parseFloat(newTranscribed.confidence_score);
        }
        return newTranscribed;
      });
      setState({ ...state, recognizedTexts });
    }
  }, [imageTranscribers]);

  const disabled = loadingRecognize || !files.some((item) => !item.recognized);

  return (
    <Box my={2}>
      <InputLabel heading="Bild på produktinformation" />
      <p>
        Ladda upp en bild med produktinformation. t. ex. baksidan av produkt, en skärmdump eller etikett. Foodla autofyller fält, kategoriserar och ger förslag på en bra produktbeskrivning! Tänk på att ladda upp en så skarp bild som möjligt, den får gärna vara tagen i ett så ljust rum som möjligt.
      </p>
      {(isStore || isProducer) && (
        <UploadImageGuideLabels
          title='Riktlinjer för produktinformationsbilder'
          labels={['Skarp & tydlig text', 'Hela etiketten synlig', 'Rak vinkel', 'Inga reflektioner eller skuggor']}
        />
      )}
      <div
        {...getRootProps({
          className: clsx('dropzone-custom dropzone-secondary fill-product-by-image-bg', !!files.length && 'has-item'),
          style: { minHeight: !files.length ? '160px' : undefined },
        })}
      >
        <input {...getInputProps()} />
        <div className={clsx('dropzone-text', (convertingImage || loadingRecognize) && 'dropzone-loading')}>
          {(convertingImage || loadingRecognize) ? (
            <span className='loader'>
              <CircularProgress size={30} />
              {loadingRecognize && (
                <span style={{ marginTop: 8, maxWidth: 500 }}>
                  {isRecognizedImage
                    ? 'Foodla har fyllt i informationen från bilden - nu analyserar vi bildkvaliteten för att säkerställa att allt stämmer.'
                    : 'Foodla läser nu av all information vi kan se på bilden'}
                </span>
              )}
            </span>
          ) : (
            <>
              <div className='dropzone-icon'>
                <img src={productInfoIcon} alt='' />
              </div>
              {!images?.length && (
                <div>
                  {smUp
                    ? 'Dra och släpp en bild här eller klicka för att välja en från din dator (Stödda format: JPG, JPEG, PNG, TIF).'
                    : 'Klicka här för att öppna kameran eller välja en bild från ditt bibliotek'}
                </div>
              )}
            </>
          )}
        </div>

        {!!displayFiles.length && (
          <div className='list-items' style={{ overflow: 'auto' }} onClick={(event) => event.stopPropagation()}>
            {displayFiles.map(({ url, name, recognized }) => {

              return (
                <div
                  style={getItemStyle()}
                >
                    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <img
                      src={url}
                      style={getImageStyle({ recognized })}
                      alt="Not available"
                      onClick={() => window.open(url, '_blank')}
                    />
                    </div>
                  <b>{name}</b>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Button
        fullWidth
        disabled={disabled}
        type="button"
        variant="contained"
        color="primary"
        style={{ backgroundColor: !disabled ? COLORS.mainGreen : undefined, marginBottom: 8 }}
        onClick={handleRunAI}
      >
        <span>AUTOFYLL PRODUkTDATA</span>
      </Button>

      {!!modifiedCount && (
        <Box id="ProductForm-FillProductByImage-Message-Success" mt={1} color="green" fontWeight={700}>
          Foodla har hittat produktinformation på bilden du laddade upp och vi har hjälpt dig att fylla i informationen från bilden i fälten nedan!
        </Box>
      )}

      {isError && (
        <Box id="ProductForm-FillProductByImage-Message-Error" mt={1} color="orangered" fontWeight={700}>
          Det verkar inte som om att vi kunde hitta produktinformationen på bilden du laddade upp. Testa gärna ladda upp
          en bild där produktinformationen är synlig så gör vi ett försök till!
        </Box>
      )}

      {imageTranscribers.length === 1 ? (
        <Box mt={2}>
          <ImageTranscribe data={imageTranscribers[0]} />
        </Box>
      ) : (
        imageTranscribers.map((imageTranscriber) => {
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                {imageTranscriber.image_name}
              </AccordionSummary>
              <AccordionDetails borderless>
                <ImageTranscribe hideHeader data={imageTranscriber} />
              </AccordionDetails>
            </Accordion>
          );
        })
      )}

      {(isStore || isProducer) && (
        <UploadImageGuideExample
          tipTitle='Tips för bättre scanning av etiketter'
          tipList={[
            'Fotografera etiketten rakt framifrån, inte i vinkel',
            'Se till att belysningen är jämn utan reflexer',
            'Undvik skuggor som kan dölja text',
            'Ta bilden så nära att texten är tydligt läsbar (se till att texten inte är suddig)',
            'Hela etiketten måste vara med i bilden',
          ]}
          correctImageSrc={correctExampleImg}
          wrongImageSrc={wrongExampleImg}
        />
      )}
    </Box>
  );
};

export default FillProductByImage;
