import { FC, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export type CopyEmailData = {
  to: string;
  cc: string;
  subject: string;
  body: string;
};

interface CopyEmailInviteProducerProps {
  open: boolean;
  data: CopyEmailData;
  onClose: () => void;
  onCopySuccess?: () => void; // Add optional callback for copy success
}

const CopyEmailInviteProducer: FC<CopyEmailInviteProducerProps> = ({ 
  open, 
  data, 
  onClose,
  onCopySuccess 
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`Till: ${data.to}\nCC: ${data.cc}\nÄmne: ${data.subject}\n\n${data.body}`)
      .then(() => {
        setCopySuccess(true);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  // Reset copySuccess state when dialog opens
  useEffect(() => {
    setCopySuccess(false);
  }, [open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle>Kopiera e-postmall</DialogTitle>

      <DialogContent>
        <div>
          <p style={{ marginTop: 0 }}>
            Vi har genererat ett e-postmeddelande åt dig nedan. Du kan kopiera innehållet och klistra in det i din
            föredragna e-postklient.
          </p>
        </div>

        <Box fontSize={16} mb={1}>
          E-postmall
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gridGap={8}
          bgcolor="#f8f9fa"
          p={2}
          border="1px solid #eee"
          borderRadius={4}
        >
          <Box>
            <b>Till:</b> {data.to}
          </Box>
          <Box>
            <b>CC:</b> {data.cc}
          </Box>
          <Box>
            <b>Ämne:</b> {data.subject}
          </Box>

          <div style={{ background: '#fff', border: '1px solid #ccc', whiteSpace: 'pre-line', padding: 8 }}>
            {data.body}
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box width="100%" display="flex" flexDirection="column" px={2} py={1}>
          {copySuccess && (
            <p
              style={{ 
                color: '#3A6F6E', 
                background: '#E7F1F1', 
                borderRadius: 4, 
                fontWeight: 600, 
                padding: '8px', 
                marginBottom: '16px',
                width: '100%',
                textAlign: 'center'
              }}
            >
              E-postmallen har kopierats. Klistra in den i ditt e-postprogram och skicka den!
            </p>
          )}
          
          <Box display="flex" justifyContent="flex-end" gridGap={16} width="100%">
            <Button variant="outlined" onClick={onClose}>
              Stäng
            </Button>

            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleCopy}
              startIcon={<FileCopyIcon />}
            >
              KOPIERA
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CopyEmailInviteProducer;