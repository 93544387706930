import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import TagLabel from 'components/TagLabel';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row', // Always row
    flexWrap: 'wrap',
    gap: 8,
    margin: '8px 0 16px 0',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap', // Remove wrap on mobile for horizontal scroll
      marginLeft: -20,
      marginRight: -20,
      paddingLeft: 20,
      paddingRight: 20,
      scrollPadding: '20px',
      scrollSnapType: 'x mandatory',
      overflowX: 'auto', // Enable horizontal scrolling
      // Hide scrollbar
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    }
  },
  tag: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'max-content',
      scrollSnapAlign: 'start',
    }
  }
}));

interface UploadImageGuideLabelsProps {
  title: string;
  labels: string[];
}

const UploadImageGuideLabels: FC<UploadImageGuideLabelsProps> = ({ title, labels }) => {
  const classes = useStyles();

  return (
    <div>
      <p style={{ fontWeight: 600, marginBottom: 4 }}>{title}</p>
      <div className={classes.wrapper}>
        {labels.map((label, index) => {
          return (
            <div key={index} className={classes.tag}>
              <TagLabel 
                type="info" 
                label={label} 
                icon={<InfoOutlined fontSize="small" />} 
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadImageGuideLabels;