// Dependencies
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

// Constants
import COLORS from '../../constants/colors';

interface IStockToggleButtonProps {
  handleCheck?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  // quantity_in_stock: number;
  inactive?: boolean;
}

export const StockToggleButton = ({ handleCheck, inactive }: IStockToggleButtonProps) => (
  <Typography component="div" style={{ position: 'relative', zIndex: 20 }}>
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid
        item
        style={{ color: inactive ? 'grey' : COLORS.mainGreen }}
      >
        Aktiv
      </Grid>
      <Grid item>
        <Switch checked={inactive} onChange={handleCheck} name="inactive" />
      </Grid>
      <Grid
        item
        style={{ color: inactive ? COLORS.mainRed : 'grey' }}
      >
        Inaktiv
      </Grid>
    </Grid>
  </Typography>
);

export default StockToggleButton;
