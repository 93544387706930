import React, { useCallback, useRef, useState } from 'react';

import InputTextField from '../../../inputfields/InputTextField';
import TemperatureField from 'components/inputfields/TemperatureField';

import IProduct from '../../../../models/product';
import { isBlank } from 'utils/helpers-ts';

interface ITemperatureProps {
  state: IProduct;
  setState: React.Dispatch<React.SetStateAction<IProduct>>;
  approve?: boolean;
}

export const Temperature = ({
  state,
  setState,
  approve,
}: ITemperatureProps) => {
  const isNotStore = !state?.producerIsStore;
  const [shouldAutoPopulate, setShouldAutoPopulate] = useState(false);

  const hasMaxTemp = state?.max_temperature !== undefined && state?.max_temperature !== null;

  const [isMinTransportValueChanged, setIsMinTransportValueChanged] = useState(false);
  const [isMaxTransportValueChanged, setIsMaxTransportValueChanged] = useState(false);

  const keepRef = useRef({ maxTeperature: state.max_temperature });

  // TODO any
  const handleChange = ({ target: { name, value } }: any) => {
    if (state) {
      setShouldAutoPopulate(false);
      if (name === 'trade_item_temperature_information') {
        setState({ ...state, [name]: value });
      }
    }
  };

  React.useEffect(() => {
    setShouldAutoPopulate(keepRef.current.maxTeperature !== state.max_temperature);
    keepRef.current.maxTeperature = state.max_temperature;
  }, [state.max_temperature]);

  React.useEffect(() => {
    if (shouldAutoPopulate && hasMaxTemp) {
      const text = `Förvaras vid högst ${state.max_temperature && state.max_temperature >= 0 ? '+' : ''}${state.max_temperature}°C`;
      setState((oldState) => {
        const oldValue = oldState.trade_item_temperature_information;
        if (isBlank(oldValue)) {
          return {...oldState, trade_item_temperature_information: text};
        }
        if (oldValue?.trim()?.startsWith('Förvaras vid högst')) {
          return {...oldState, trade_item_temperature_information: text};
        }
        return oldState;
      });
      setShouldAutoPopulate(false);
    }
  }, [shouldAutoPopulate, hasMaxTemp, state.max_temperature, setState]);

  const onTemperatureChange = (value: number, name: string): void => {
    setState({ ...state, [name]: value });
  };

  const onMinTemperatureChange = (value: number, name: string) => {
    if (setState) {
      if (isNotStore) {
        setState({...state, [name]: value, transportMinTemperature: isMinTransportValueChanged ? state?.transportMinTemperature : value});
      } else {
        setState({...state, [name]: value });
      }
    }
  };

  const onMaxTemperatureChange = (value: number, name: string) => {
    if (setState) {
      if (isNotStore) {
        setState({...state, [name]: value, transportMaxTemperature: isMaxTransportValueChanged ? state?.transportMaxTemperature : value});
      } else {
        setState({...state, [name]: value });
      }
    }
  };

  const setTempError = useCallback((value: boolean) => {
    setState && setState((oldState) => ({ ...oldState, showTempError: value }));
  }, [setState]);

  return (
    <>
      <TemperatureField
        inputLabelProps = {{
          heading: "Förvaringstemperatur",
          text: "Ange den lägsta och högsta temperaturen som produkten tål utan påverkan på produktsäkerhet eller kvalitet. Glöm inte att ange “-” eller “+” framför gradantalet.",
          required: true,
        }}
        minValueProps = {{
          inputName: "min_temperature",
          defaultValue: state?.min_temperature,
          value: state?.min_temperature,
          placeholder: "Ex. +0",
          onChange: onMinTemperatureChange,
        }}
        maxValueProps = {{
          inputName: "max_temperature",
          defaultValue: state?.max_temperature,
          value: state?.max_temperature,
          placeholder: "Ex. +10",
          onChange: onMaxTemperatureChange,
        }}
        setTempError={isNotStore ? setTempError : undefined}
      />
      {isNotStore && (
        <TemperatureField
          inputLabelProps = {{
            heading: "Transporttemperatur",
            text: "Ange den lägsta och högsta temperaturen som produkten tål under transport utan påverkan på produktsäkerhet eller kvalitet. Glöm inte att ange “-” eller “+” framför gradantalet.",
          }}
          minValueProps = {{
            inputName: "transportMinTemperature",
            value: state?.transportMinTemperature,
            placeholder: "Ex. +0",
            onChange: onTemperatureChange,
            setIsChanged: setIsMinTransportValueChanged,
          }}
          maxValueProps = {{
            inputName: "transportMaxTemperature",
            value: state?.transportMaxTemperature,
            placeholder: "Ex. +8",
            onChange: onTemperatureChange,
            setIsChanged: setIsMaxTransportValueChanged,
          }}
          setTempError={setTempError}
        />      
      )}
      <InputTextField
        style={{ marginTop: 7 }}
        placeholder="Ex. Förvaras vid högst +4°C"
        multiline
        inputName="trade_item_temperature_information"
        onChange={handleChange}
        value={state?.trade_item_temperature_information}
        required
        setState={setState}
        approve={approve}
        state={state}
        multilineRows={1}
        multilineMax={4}
        withLabel
        labelOptions={{
          heading: 'Förvaringsanvisning',
          text: 'För produkter med sista förbrukningsdatum ska även förvaringsanvisningar finnas på förpackningen. För livsmedel, krävs det annars bara om det är nödvändigt för hållbarhet. Ibland behöver du ange hur länge ett livsmedel är hållbart i öppnad förpackning.',
          link: 'https://kontrollwiki.livsmedelsverket.se/artikel/42/forvaringsanvisning',
        }}
      />
    </>
  );
};

export default Temperature;
