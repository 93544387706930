import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  path,
} from 'ramda';

import InputLabel from '../../../inputfields/InputLabel';
import {
  SUPERUSER_APPROVAL,
  APPLICATION_ROLES,
} from '../../../constants';
import COLORS from '../../../../constants/colors';
import useStyles from '../useStyles';

export const getMenuItemStyle = adminStatus => {
  const approved = { color: COLORS.mainGreen, fontWeight: 'bolder' };
  const awaiting = { color: COLORS.mainOrange, fontWeight: 'bolder' };
  const notApproved = { color: COLORS.mainRed, fontWeight: 'bolder' };

  switch (adminStatus) {
    case SUPERUSER_APPROVAL.ADMIN_APPROVED:
      return approved;
    case SUPERUSER_APPROVAL.ADMIN_DISAPPROVED:
      return notApproved;
    default:
      return awaiting;
  }
};

const getStatusConfig = adminStatus => {
  const style = getMenuItemStyle(adminStatus);
  switch (adminStatus) {
    case SUPERUSER_APPROVAL.AWAITING_APPROVAL: {
      return { message: 'Under granskning', style };
    }
    case SUPERUSER_APPROVAL.ADMIN_APPROVED: {
      return { message: 'Godkänd', style };
    }
    case SUPERUSER_APPROVAL.ADMIN_DISAPPROVED: {
      return { message: 'Behöver åtgärdas', style };
    }
    default: {
      return {
        message: 'Uknown. Contact Foodla.',
        style: { color: COLORS.mainRed, fontWeight: 'bolder' },
      };
    }
  }
};

export const Review = ({ state, setState, userRole, isCreatingNew, product, isAiChangedState }) => {
  const classes = useStyles();
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;
  const isProducer = userRole === APPLICATION_ROLES.PRODUCER || APPLICATION_ROLES.STORE;
  const currentStatusConfig = getStatusConfig(path(['adminStatus'], product));
 
  const handleChange = event => {
    // Enforce float if descriptive_size_amount
    const stateName = event.target.name;
    let stateValue = event.target.value;
    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    setState({ ...state, [stateName]: stateValue });
  };
  return (
    <>
      {isSuperuser && (
        <>
          <h3>Review Status</h3>
          <FormControl variant="outlined" className={classes.formSelectControl}>
            <InputLabel id="adminStatusLabel">Status</InputLabel>
            <Select
              labelId="adminStatusLabel"
              id="adminStatus"
              name="adminStatus"
              value={state.adminStatus}
              style={getMenuItemStyle(state.adminStatus)}
              onChange={handleChange}
              label="Approval"
            >
              <MenuItem
                style={getMenuItemStyle(SUPERUSER_APPROVAL.ADMIN_APPROVED)}
                value={SUPERUSER_APPROVAL.ADMIN_APPROVED}
              >
                Approved Review
              </MenuItem>
              <MenuItem
                style={getMenuItemStyle(SUPERUSER_APPROVAL.AWAITING_APPROVAL)}
                value={SUPERUSER_APPROVAL.AWAITING_APPROVAL}
              >
                Under Review
              </MenuItem>
              <MenuItem
                style={getMenuItemStyle(SUPERUSER_APPROVAL.ADMIN_DISAPPROVED)}
                value={SUPERUSER_APPROVAL.ADMIN_DISAPPROVED}
              >
                Failed Review
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      {isProducer && !isCreatingNew && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 20,
            textAlign: 'center',
          }}
        >
          <p
            style={{
              fontSize: 18,
              margin: 5,
            }}
          >
            Produktens status är:{' '}
            <span
              style={{
                ...currentStatusConfig.style,
                fontSize: 20,
              }}
            >
              {currentStatusConfig.message}
            </span>
          </p>
          <h5 style={{ margin: 3 }}>Produktens status kommer sättas till “Under granskning” när du uppdaterar den.</h5>
        </div>
      )}

      {!isSuperuser && !!isAiChangedState && (
        <h5 style={{ margin: 3, textAlign: 'center' }}>
          Genom att skapa eller uppdatera produkten intygar du att du har kontrollerat att den uppladdade produktinformationen är korrekt och överensstämmer med din bästa bedömning.
        </h5>
      )}
    </>
  );
};

export default Review;
