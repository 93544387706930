import { v4 as uuid } from 'uuid';
import * as dateFns from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash';

import { IImageFileSource, IProductExcel } from '../types';
import { fileToDataUrl } from 'utils/imgix';
import { getDataType, checkEnumType, getEnumValue, convertStringToBoolean, isValidEANValue } from './excel';
import IndexedDB from 'utils/indexedDB';

import { TExcelDataRow } from 'models/excel';
import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import IProducer from 'models/producer';
import { countries } from 'constants/domain';
import {
  PRODUCT_EAN_FIELDS,
  PRODUCT_BOOLEAN_FIELD,
  PRODUCT_BOOLEAN_FIELD_VALUES,
  PRODUCT_DATE_FIELD,
  PRODUCT_ENUM_FIELD,
  PRODUCT_ENUM_FIELD_VALUES,
  PRODUCT_NUMBER_FIELD,
} from 'components/constants-ts';
import {
  isBeverages,
  isMagazine,
  isNewFood,
  isSnusOrTobacco,
} from 'components/product/ProductUpdateForm/fields/category';
import { processImage } from 'components/fileuploader/ImageUploader2';
import { getBrand } from 'components/product/ProductUpdateForm/utils';
import {
  DB_NAME,
  SAVED_FILE_ID,
  SAVED_FILE_DATA_ID,
  OBJECT_STORAGE_NAME,
  SAVED_PRODUCT_IMAGE,
  ALLOWED_IMAGE_FILE_TYPE_LIST,
  PARSE_IMAGE_BY,
  STEP_PATHS,
  LOCAL_STORE_ITEMS,
} from '../constants';
import { getCountryName } from 'utils/helpers-ts';

const isProductionMode = process.env.REACT_APP_AUTH_ENV === 'prod';

export const getStepPath = (step?: string | number) => {
  const savedStep = localStorage.getItem(LOCAL_STORE_ITEMS.STEP) || '1';
  return STEP_PATHS[String(step)] || STEP_PATHS[savedStep];
};

export const getStepNumber = (stepPath?: string): number | null => {
  let result: number | null = null;
  Object.entries(STEP_PATHS).forEach(([step, path]) => {
    if (path === stepPath) result = Number(step);
  });
  return result;
};

export const parseFileName = (fileName: string) => {
  const splitedName = fileName.split('.');
  let extension = splitedName.pop() || '';
  const name = splitedName.join('.');

  return { name, extension };
};

export const parseImageFile = async (params: {
  file: File;
  token: string;
  startCallback?: () => void;
}): Promise<IImageFileSource> => {
  const { file, token, startCallback } = params || {};

  let fileName = file.name || '';
  let { name, extension } = parseFileName(fileName);
  let convertedFile = file;

  startCallback?.();
  const newConvertedFile = await processImage(file, token, !isProductionMode);
  if (newConvertedFile) {
    convertedFile = newConvertedFile;
    fileName = newConvertedFile.name;
    if (newConvertedFile.type === 'image/png') {
      extension = 'png';
    }
  }

  const source = await fileToDataUrl(convertedFile);
  return { id: uuid(), source, fileName, name, extension, file, convertedFile };
};

export const createIndededDB = async () => {
  const instance = new IndexedDB({
    databaseName: DB_NAME,
    objectStoreNameList: [OBJECT_STORAGE_NAME],
  });
  const { data: database } = await instance.openDatabase();
  return database;
};

export const readExcelDataFromIndexedDB = async (indexedDB: IndexedDB) => {
  if (!indexedDB) return { excelFile: null, excelFileData: [] };

  const [fileResponse, fileDataResponse] = await Promise.all([
    indexedDB!.read<File>(OBJECT_STORAGE_NAME, SAVED_FILE_ID),
    indexedDB!.read<TExcelDataRow[]>(OBJECT_STORAGE_NAME, SAVED_FILE_DATA_ID),
  ]);

  return {
    excelFile: fileResponse?.data?.value || null,
    excelFileData: fileDataResponse?.data?.value || [],
  };
};

export const readImageFileDataFromIndexedDB = async (indexedDB: IndexedDB): Promise<IImageFileSource[]> => {
  if (!indexedDB) return [];

  try {
    const { data } = await indexedDB.read<IImageFileSource[]>(OBJECT_STORAGE_NAME, SAVED_PRODUCT_IMAGE);
    return (data?.value || []).filter(({ url }) => !!url);
  } catch {
    return [];
  }
};

export const writeImageFileDataFromIndexedDB = async (
  indexedDB: IndexedDB,
  imageFileDataList: IImageFileSource[]
): Promise<void> => {
  if (!indexedDB) return;

  try {
    await deleteImageFileDataFromIndexedDB(indexedDB);
    await indexedDB.write<IImageFileSource[]>(OBJECT_STORAGE_NAME, {
      id: SAVED_PRODUCT_IMAGE,
      value: imageFileDataList
        .filter(({ url }) => !!url)
        .map(({ file, convertedFile, source, ...fileData }) => ({
          ...fileData,
          source: '',
          generateAiError: '',
          uploadAiError: '',
          uploadError: '',
        })),
    });
  } catch {}
};

export const deleteImageFileDataFromIndexedDB = async (indexedDB: IndexedDB): Promise<void> => {
  if (!indexedDB) return;

  try {
    await indexedDB.delete(OBJECT_STORAGE_NAME, SAVED_PRODUCT_IMAGE);
  } catch {}
};

export const filterAllowedImages = (files: File[]) => {
  return files.filter(file => ALLOWED_IMAGE_FILE_TYPE_LIST.includes(file.type));
};

export const testImageRegexString = (regex: string, parseBy: keyof IProduct, value: string) => {
  if (!regex || !value) return false;

  let regexStr = `^${regex}$`;
  if (parseBy === PARSE_IMAGE_BY.ARTICLE) regexStr = `^${regex}`;

  const [checkingValue] = value.split('_') || [''];

  try {
    if (regexStr && new RegExp(regexStr).test(checkingValue)) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const mapUploadProductList = (
  productList: IProduct[],
  options: { rootCategory: IFoodlaCategoryOption; producer: IProducer }
) => {
  const { rootCategory, producer } = options || {};
  if (!productList?.length) return [];

  let newProductList = _.cloneDeep(productList);

  return newProductList.map(product => {
    const newProduct = _.cloneDeep(product);
    const isFood = isNewFood(newProduct.foodlaCategory, rootCategory);
    const isSnusOrTobaccoCategory = isSnusOrTobacco(newProduct.foodlaCategory, rootCategory);
    const isMagazineCategory = isMagazine(newProduct.foodlaCategory, rootCategory);
    const isBeveragesCategory = isBeverages(newProduct.foodlaCategory, rootCategory);

    // foodlaCategory
    if (newProduct.foodlaCategory) {
      const { id, name } = newProduct.foodlaCategory;
      newProduct.foodlaCategory = { id, name };
    }

    // country_of_manufacturing_string
    if (newProduct.country_of_manufacturing_string && newProduct.country_of_manufacturing_string?.trim() !== '') {
      const country = countries.find(c => newProduct.country_of_manufacturing_string === c.sv);
      if (country) {
        newProduct.country_of_manufacturing = country.code;
      } else {
        if (newProduct.country_of_manufacturing_string === 'Beligen') {
          newProduct.country_of_manufacturing = 56;
        } else if (newProduct.country_of_manufacturing_string === 'China') {
          newProduct.country_of_manufacturing = 156;
        } else if (newProduct.country_of_manufacturing_string === 'Holland') {
          newProduct.country_of_manufacturing = 528;
        }
      }
    }
    delete newProduct.country_of_manufacturing_string;

    // transportMinTemperature, transportMaxTemperature
    if (_.isNil(newProduct.transportMinTemperature)) {
      newProduct.transportMinTemperature = newProduct.min_temperature;
    }
    if (_.isNil(newProduct.transportMaxTemperature)) {
      newProduct.transportMaxTemperature = newProduct.max_temperature;
    }

    // basicDataTaxClassification
    if (_.isNil(newProduct.basicDataTaxClassification)) {
      if (isFood) {
        if (isSnusOrTobaccoCategory) {
          newProduct.basicDataTaxClassification = 25;
        } else if (isBeveragesCategory && newProduct.alcoholPercentage && Number(newProduct.alcoholPercentage) > 0) {
          newProduct.basicDataTaxClassification = 25;
        } else {
          newProduct.basicDataTaxClassification = 12;
        }
      } else {
        if (isMagazineCategory) {
          newProduct.basicDataTaxClassification = 6;
        } else {
          newProduct.basicDataTaxClassification = 25;
        }
      }
    }

    // brand, brand_food
    let brand = getBrand({ brand: newProduct.brand || newProduct.brand_food }, producer);
    newProduct.brand_food = brand;
    newProduct.brand = brand;

    // EAN fields
    PRODUCT_EAN_FIELDS.forEach(eanField => {
      (newProduct[eanField] as any) = undefined;
      if (isValidEANValue({ field: eanField }, product)) {
        (newProduct[eanField] as any) = _.toString(product[eanField]);
      }
    });

    // article
    newProduct.article = _.toString(product.article);

    // purchasingDataSupplierArticleNo
    newProduct.purchasingDataSupplierArticleNo = _.toString(product.purchasingDataSupplierArticleNo);

    // responsibleLabelingApprovalNumber
    newProduct.responsibleLabelingApprovalNumber = _.toString(product.responsibleLabelingApprovalNumber);

    // date fields
    PRODUCT_DATE_FIELD.forEach(field => {
      (newProduct[field] as any) = undefined;
      if (!product) return;

      const value = product[field] as Date;
      if (dateFns.isDate(value)) {
        (newProduct[field] as any) = utcToZonedTime(value, 'Europe/Stockholm').getTime();
      }
    });

    // ceMarking
    newProduct.ceMarking = !!newProduct.specifyCEStandards?.trim();

    // **** ENUM ****
    PRODUCT_ENUM_FIELD.forEach(field => {
      (newProduct[field] as any) = getEnumValue(
        {
          _id: '',
          isMandatory: true,
          title: '',
          field,
          dataType: 'string',
          isEnumType: true,
        },
        { ...newProduct, _excelData: {}, _prasedExcelData: {} }
      );
    });

    // enum: descriptive_size_unit
    if (
      newProduct.EAN?.startsWith('23')
      || newProduct.EAN?.startsWith('2095')
      || newProduct.EAN?.startsWith('2096')
      || newProduct.EAN?.startsWith('2097')
      || newProduct.EAN?.startsWith('2000')
    ) {
      newProduct.descriptive_size_unit = 'GRAM_APPROXIMATE';
    }

    // **** BOOLEAN ****
    // boolean fields
    PRODUCT_BOOLEAN_FIELD.forEach(field => {
      const isEnumType = checkEnumType(field);
      if (isEnumType) return;

      (newProduct[field] as any) = undefined;
      if (isEnumType || !product || _.isNil(product[field])) return;

      const convertedValue = convertStringToBoolean(String(product[field]), field);
      if (!_.isNil(convertedValue)) {
        (newProduct[field] as any) = convertedValue;
      }
    });

    // number fields
    PRODUCT_NUMBER_FIELD.forEach(field => {
      if (product) {
        let value = product[field];
        if (!_.isNil(value) && typeof value !== 'number') {
          if (typeof value === 'string') {
            value = value.trim().replace(',', '.');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const regex = /^([-+]?\d+(?:[,.]\d+)?)$/gimu;
            const matched = value.match(regex);
            if (matched?.[0] && matched[0].trim() !== '') {
              value = matched[0];
            } else {
              value = null;
            }
            if (value) {
              (newProduct[field] as number) = parseFloat(value);
            } else {
              (newProduct[field] as any) = undefined;
            }
          }
        }
      }
    });

    return newProduct;
  });
};

export const reorderImages = (imageList: IImageFileSource[], isAiMain: Boolean) => {
  const orderedImageList: IImageFileSource[] = [];

  imageList.forEach(image => {
    if (!image.name.includes('AIGENERATED')) {
      if (!isAiMain) orderedImageList.push(image);
      const aiImage = imageList.find(({ name }) => name.startsWith(image.name) && name.endsWith('AIGENERATED'));
      if (aiImage) orderedImageList.push(aiImage);
      if (isAiMain) orderedImageList.push(image);
    }
  });

  imageList.forEach(image => {
    if (!orderedImageList.find(({ id }) => id === image.id)) {
      orderedImageList.push(image);
    }
  });

  return orderedImageList;
};

export const getImageDataFileName = (imageData?: IImageFileSource) => {
  if (!imageData) return '';
  if (!imageData?.url) return imageData?.name || '';
  const [, ...names] = imageData?.name?.split('_') || [];
  return names.join('_');
};

export const sortImagesByName = (imageDataList: IImageFileSource[]) => {
  const result = [...imageDataList];
  result.sort((prev, next) => {
    const prevName = getImageDataFileName(prev);
    const nextName = getImageDataFileName(next);
    const prevOrder = prevName?.split('_')?.[1] || -Infinity;
    const nextOrder = nextName?.split('_')?.[1] || -Infinity;

    if (isNaN(Number(prevOrder)) && isNaN(Number(nextOrder))) return 0;
    if (isNaN(Number(prevOrder))) return 1;
    if (isNaN(Number(nextOrder))) return -1;
    return Number(prevOrder) - Number(nextOrder);
  });
  return reorderImages(result, true);
};

export const getImageDataListToUpload = (
  productImageDataSet: Record<string, IImageFileSource[]>,
  dataList: IProductExcel[]
) => {
  const uploadingImageDataList: IImageFileSource[] = [];

  Object.entries(productImageDataSet).forEach(([productId, imageDataList]) => {
    const product = dataList.find(({ id }) => id === productId);
    if (!product) return;

    // [!name.endsWith('AIGENERATED')]: remove ai image
    const sortedImageDataList = sortImagesByName(imageDataList);
    const filteredImageDataList = sortedImageDataList.filter(({ url, name }) => !url && !name.endsWith('AIGENERATED'));

    filteredImageDataList.forEach((imageData, index) => {
      // original image was uploaded, don't modify it
      if (imageData.url) {
        uploadingImageDataList.push(imageData);
        return;
      }

      let name = `${Math.ceil(Date.now() / 1000)}_${product.EAN || product.EAN2 || ''}`;
      if (filteredImageDataList.length > 1) name += `_${index + 1}`;
      const extension = imageData.extension;
      const fileName = `${name}.${extension}`;
      uploadingImageDataList.push({ ...imageData, assignProductId: product.id, name, fileName });
    });
  });

  return uploadingImageDataList;
};

export const revertProduct = (product: IProduct) => {
  const convertedSavedProduct = _.cloneDeep(product);

  Object.entries(convertedSavedProduct).forEach(entry => {
    const [field, value] = entry as [keyof IProduct, any];
    const dataType = getDataType(field);
    const isEnumType = checkEnumType(field);
    if (dataType === 'boolean' && !isEnumType) {
      const booleanFieldValue = PRODUCT_BOOLEAN_FIELD_VALUES[field]?.find(item => item.convertTo === value);
      if (booleanFieldValue) {
        (convertedSavedProduct[field] as string) = booleanFieldValue.valueList[0];
      } else {
        (convertedSavedProduct[field] as string) = value ? 'Yes' : 'No';
      }
    }
    if (dataType === 'date' && !_.isNil(value)) {
      (convertedSavedProduct[field] as Date) = new Date(value);
    }
    if (checkEnumType(field)) {
      const enumFieldValue = PRODUCT_ENUM_FIELD_VALUES[field]?.find(item => item.convertTo === value);
      if (enumFieldValue) {
        (convertedSavedProduct[field] as string) = enumFieldValue.value;
      }
    }
  });

  // ItemCategoriesOnline
  const productNotes = (convertedSavedProduct as any).productNotes as {
    fieldName: string;
    value: (string | number)[];
  }[];
  const productNote = productNotes.find(({ fieldName }) => fieldName === 'ItemCategoriesOnline');
  if (productNote?.value?.length) {
    convertedSavedProduct.ItemCategoriesOnline = _.toString(productNote.value[0]);
  }

  // country_of_manufacturing
  if (convertedSavedProduct.country_of_manufacturing) {
    convertedSavedProduct.country_of_manufacturing_string =
      getCountryName(convertedSavedProduct.country_of_manufacturing) || undefined;
  }

  return convertedSavedProduct;
};
