import IProduct from 'models/product';
import { checkEANValue } from 'components/product/ProductUpdateForm';
import { isBlank } from 'utils/helpers-ts';
import { IReviewColumn, TValidatorResult } from '../type';
import { getCellValue, isEmptyCell, isErrorCell } from './cell.utils';
import { checkDecimal } from 'components/product/ProductUpdateForm/fields/gross-dimensions';
import { checkIngredientStatementError, getAllergens } from 'components/product/ProductUpdateForm/fields/ingredients';

// **********************************************
// Validators for All fields
// **********************************************
export const commonValidator = (product: IProduct, column: IReviewColumn): TValidatorResult => {
  const errorMessages: string[] = [];

  if (!isErrorCell(column, product) || column.field === 'image_src') {
    return { errorMessages };
  }

  if (isEmptyCell(column, product)) {
    errorMessages.push('Missing value');
    return { errorMessages };
  }

  if (['gross_weight_num', 'gross_weight', 'gross_height', 'gross_width', 'gross_depth'].includes(column.field)) {
    const value = getCellValue(column, product);
    if (checkDecimal(value as string | number)) {
      errorMessages.push('Use only whole numbers');
      return { errorMessages };
    }
  }

  if (column.field === 'basicDataTaxClassification') {
    errorMessages.push('Value must be 6, 12, or 25');
    return { errorMessages };
  }

  return { errorMessages: ['Invalid value'] };
};

// **********************************************
// Validators for Special fields
// **********************************************
export const eanValidator = (product: IProduct): TValidatorResult => {
  const { eanLengthError, eanControlError, eanWrongCoopStoreError, nonUniqError, eanNotOnlyDegit } = checkEANValue(
    product,
    true
  );

  const errorMessages: string[] = [];

  if (isBlank(product.EAN)) errorMessages.push('Vänligen fyll i detta fält.');
  if (eanLengthError) errorMessages.push(eanLengthError);
  if (eanControlError) errorMessages.push(eanControlError);
  if (eanWrongCoopStoreError) errorMessages.push(eanWrongCoopStoreError);
  if (nonUniqError) errorMessages.push(nonUniqError);
  if (eanNotOnlyDegit) errorMessages.push('Only digits allowed');

  return { errorMessages };
};

export const ingredientValidator = (product: IProduct): TValidatorResult => {
  const errorMessages: string[] = [];

  const allergens = getAllergens(product.ingredient_statement);
  const { error, ingredient } = checkIngredientStatementError(product.ingredient_statement);
  if (allergens.length) {
    errorMessages.push(
      `Det verkar som att ${allergens.value} är ${
        allergens.length === 1 ? 'en allergen' : 'allergener'
      }. Om detta stämmer, var god skriv ${allergens.length === 1 ? 'denna' : 'dessa'} i VERSALER`
    );
  }

  if (error) {
    errorMessages.push(
      `Det verkar som att "${ingredient}" behöver anges med en mängdangivelse. Om detta stämmer, var god ange mängd för "${ingredient}"`
    );
  }

  return { errorMessages };
};
