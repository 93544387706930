import React from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from 'styled-components';

import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

import ErrorBox from '../../../components/Error';
import COLORS from "../../../constants/colors";
import {BasicTitle} from '../../../components/typography/Title';
import { INonUniqErrors, ISuperuserErrors, SUPERUSER_NON_UNIQ_ERRORS, SUPERUSER_PRODUCTS_ERRORS } from "./queries";
import ProductsErrors from './items';
import { transformCategoryRead } from "utils/products";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);


const StyledBasicTitle = styled(BasicTitle)`
  padding-top: 20px;
  padding-bottom: 20px;
`;



const SuperuserErrors = () => {
  const { data, loading, error, refetch, networkStatus } = useQuery<ISuperuserErrors>(SUPERUSER_PRODUCTS_ERRORS, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: nonUniqData, loading: nonUniqLoading, error: nonUniqError, refetch: nonUniqRefetch, networkStatus: nonUniqNetworkStatus } = useQuery<INonUniqErrors>(SUPERUSER_NON_UNIQ_ERRORS, {
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (refetch) refetch();
    if (nonUniqRefetch) nonUniqRefetch();
  }, [nonUniqRefetch, refetch]);
  const classes = useStyles();
  const products = data?.superuserProductsErrors?.map(transformCategoryRead);
  const nonUniqProducts = nonUniqData?.superuserNonUniqErrors;

  const [showNotApproved, setShowNotApproved] = React.useState(false);
  React.useEffect(() => {
    if (refetch) {
      if (showNotApproved) {
        refetch({ showNotApproved: true });
      } else {
        refetch({ showNotApproved: false });
      }
    }
  }, [refetch, showNotApproved]);

  return (
    <Paper>
      <StyledBasicTitle>
        Products with errors
      </StyledBasicTitle>
      <FormControlLabel
        control={
          <Switch
            checked={showNotApproved}
            onChange={() => setShowNotApproved(!showNotApproved)}
            name="shownotapproved"
            color="primary"
          />
        }
        label={showNotApproved ? 'Show only approved products' : 'Show not approved products and producers'}
      />
      {/* TODO any */}
      {(error || networkStatus === 8) && (
        <ErrorBox message={`${error ? (error as any).message : 'Network error'}`} />
      )}
      {(nonUniqError || nonUniqNetworkStatus === 8) && (
        <ErrorBox message={`${nonUniqError ? (nonUniqError as any).message : 'Network error (non uniq EAN)'}`} />
      )}
      {/* TODO add loading and error */}
      {(!loading && !error && networkStatus !== 8) && (!products || products.length < 1) 
      && (!nonUniqLoading && !nonUniqError && nonUniqNetworkStatus !== 8) && (!nonUniqProducts || nonUniqProducts.length < 1) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span
            style={{ fontSize: 18, textAlign: 'center', marginTop: 20, color: COLORS.mainGreen, fontWeight: 'bold' }}
          >
            All products without errors. Nothing to do here! :)
          </span>
        </div>
      )}
      {(loading || nonUniqLoading) && (!error && networkStatus !== 8) && (!nonUniqError && nonUniqNetworkStatus !== 8) && (
          <div
              style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 20,
                  paddingBottom: 20,
              }}
          >
              <CircularProgress size={70} variant="indeterminate" />
          </div>
      )}
      {(!loading && !error && networkStatus !== 8 && products && products.length > 0)
      && (!nonUniqLoading && !nonUniqError && nonUniqNetworkStatus !== 8 && products && products.length > 0) 
      && ((products && products.length > 0) || (nonUniqProducts && nonUniqProducts.length > 0)) && (
        <div className={classes.root}>
          <ProductsErrors
            products={products}
            title="Can't map units"
            filter="isDifferentUnits"
            errorField={["descriptive_size_amount", "descriptive_size_unit", 'package_size', 'package_size_unit']}
            description="This error indicates that it's not possible to move package size values to descriptive size. There are such fields in this error: descriptive_size, descriptive_size_unit, package_size, package_size_unit. ITEM unit is equal to st/styck."
          />
          <ProductsErrors
            products={products}
            title="Empty category"
            filter="isEmptyCategory"
            // errorField="foodlaCategory"
          />
          <ProductsErrors
            products={products}
            title="Wrong category mapping"
            filter="isWrongCategoryMapping"
            errorField="foodlaCategory"
            description="It seems category not exists or deprecated"
          />
          <ProductsErrors
            products={products}
            title="No Coop category defined"
            filter="isWrongCoopCategory"
            errorField="foodlaCategory"
            description="Check Coop Online ID column in category tree table"
          />
          <ProductsErrors
            products={products}
            title="No Apotea category defined"
            filter="isWrongApoteaCategory"
            errorField="foodlaCategory"
            description="Check Apotea ID column in category tree table"
          />
          <ProductsErrors
            products={products}
            title="No ICA category defined"
            filter="isWrongIcaCategory"
            errorField="foodlaCategory"
            description="Check ICA category in ICA category tree table"
          />

          <ProductsErrors
            products={products}
            title="Empty title"
            filter="isEmptyTitle"
          />
          <ProductsErrors
            products={products}
            errorField="brand"
            title="Field “Produktnamn” contains any part from field “Varumärke” and/or field “Namn som visas” (producer name)"
            filter="isTitleContainsBrand"
          />
          <ProductsErrors
            products={products}
            title="Field “Produktnamn)” contains word “EKO” (all capital letters)"
            errorField="title"
            description="Replace EKO with Eko"
            filter="isTitleContainsEKO"
          />

          <ProductsErrors
            products={products}
            title="No images"
            filter="isEmptyImage"
          />

          <ProductsErrors
            products={products}
            title="EAN is empty"
            filter="isEmptyEAN"
          />
          <ProductsErrors
            products={products}
            title="Wrong EAN for store products (“2197…”)"
            filter="is2197EAN"
            errorField="EAN"
          />
          <ProductsErrors
            products={products}
            title="Wrong EAN for Coop store products"
            filter="isWrongCoopStoreEAN"
            errorField="EAN"
          />
          <ProductsErrors
            products={products}
            title="Wrong EAN length"
            filter="isWrongEANLength"
            errorField="EAN"
            description="Wrong EAN length. EAN must have 8 or 13 digits. Remove whitespace, zero digit on start, store id"
          />
          <ProductsErrors
            products={products}
            title="Wrong EAN control digit"
            errorField="EAN"
            filter="isWrongControlDigit"
          />
          <ProductsErrors
            products={products}
            title="Wrong EAN symbols"
            filter="isWrongEANSymbols"
            errorField="EAN"
            description="EAN must contains only digits (also check whitespace)"
          />
          <ProductsErrors
            products={products}
            errorField="EAN"
            title="Second EAN field for EAN codes that start with “23”, “2000”, “2095”, “2096”, “2097” is empty"
            filter="isEAN2Empty"
          />
          <ProductsErrors
            groupedProducts={nonUniqProducts}
            errorField="EAN"
            title="Non uniq EAN"
            filter="isNonUniqEANs"
            groupBy="EAN"
          />

          <ProductsErrors
            products={products}
            title="Field “Nettokvantitet” (food) is empty"
            filter="isDescriptiveSizeAmountEmpty"
          />
          <ProductsErrors
            products={products}
            title="Wrong unit"
            filter="isWrongUnit"
            errorField="descriptive_size_unit"
            description="Nettokvantitet unit is not gram ungefärlig vikt (GRAM_APPROXIMATE)"
          />


          {/* <ProductsErrors
            products={products}
            title="Field “Förpackningsstorlek”(non food) is empty"
            filter="isPackageSizeEmpty"
          /> */}

          <ProductsErrors
            products={products}
            title="Temperature fields are empty"
            filter="isNoTemperature"
          />
          <ProductsErrors
            products={products}
            title="Field “Förvaringsanvisning” has a digit but not character “°” and/or letter “C” or “K”"
            errorField="trade_item_temperature_information"
            filter="isNoDegreeCharacter"
          />

          <ProductsErrors
            products={products}
            title="New field for “Tillverkningsland” (drop down field) is empty"
            filter="isEmptyCountryOfManufacturing"
          />

          <ProductsErrors
            products={products}
            title="Bruttovikt field are empty"
            filter="isGrossWeightEmpty"
          />
          <ProductsErrors
            products={products}
            title="Fields for “Konsumentförpackningens dimensioner” are empty"
            filter="isGrossDimensionsEmpty"
          />


          <ProductsErrors
            products={products}
            title="Empty store id"
            filter="isEmptyStore"
          />

          {/* <ProductsErrors
            products={products}
            title="Coop online categories are empty"
            filter="isItemCategoriesOnlineEmpty"
          /> */}

          <ProductsErrors
            products={products}
            title="Missing nutrient declaration"
            filter="isEmptyNutritional"
            errorField="energi_kj"
          />
          {/* <ProductsErrors
            products={products}
            title="b2bPrice (comparativePriceData) or comparativePriceText fields has no value"
            filter="isWrongAssortment"
            description="Gå in på produkten i ert butikssystem och ange jämförelsefaktor samt jämförelsetext för att aktivera den online"
          /> */}
        </div>
      )}
    </Paper>
  );
};

export default SuperuserErrors;
