import { FC, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { CircularProgress } from '@material-ui/core';

import Button from 'components/button';
import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import { MUTATION_SUPERUSER_APPROVE } from 'graphql/mutations';
import { getProductMutationInput } from 'components/product/ProductUpdateForm/utils';
import { APPLICATION_ROLES } from 'components/constants-ts';

interface SaveButtonProps {
  savedProducts: IProduct[];
  products: IProduct[];
  rootCategory?: IFoodlaCategoryOption;
  refetch: () => Promise<unknown>;
}

const SaveButton: FC<SaveButtonProps> = ({ savedProducts, products, rootCategory, refetch }) => {
  const [updateProductMutation] = useMutation(MUTATION_SUPERUSER_APPROVE);

  const [uploading, setUploading] = useState(false);

  const prepareDataToUpload = () => {
    const uploadingDataList = products.map(product => {
      const newProduct: IProduct = {
        ...product,
        title: product.title || '',
        location: product.location || '',
        short_text: product.short_text || '',
      };

      return newProduct;
    });

    return uploadingDataList;
  };

  const uploadProduct = async (product: IProduct) => {
    const savedProduct = savedProducts.find(({ id }) => id === product.id);

    const result = { dataId: product.id, productId: '', error: '' };

    if (!savedProduct) return { ...result, error: 'Can not find product' };

    // ** generate variables **
    const mutationInput = getProductMutationInput(
      savedProduct,
      product,
      APPLICATION_ROLES.SUPERUSER,
      [],
      false,
      rootCategory
    );

    // ** update product **
    try {
      await updateProductMutation(mutationInput);
    } catch (error: any) {
      result.error = error.message;
    }
    return result;
  };

  const handleSave = async () => {
    setUploading(true);

    const uploadingDataList = prepareDataToUpload();

    const uploadProductPromises = uploadingDataList.map(async product => {
      await uploadProduct(product);
    });

    await Promise.all(uploadProductPromises);

    await refetch();

    setUploading(false);
  };

  return (
    <Button variant="contained" onClick={handleSave}>
      {uploading ? <CircularProgress size={16} style={{ margin: 4 }} /> : 'Spara ändringar'}
    </Button>
  );
};

export default SaveButton;
