import { FC, ReactNode, useMemo } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

import IProduct from 'models/product';

import { STATUS_COLORS, STATUS_COLORS_OPACITY } from 'components/product/BulkImport/constants';
import ErrorInfoIcon, { TErrorInfoData } from 'components/product/BulkImport/components/ErrorInfoIcon';

import { IReviewColumn } from '../../type';
import { commonValidator } from '../../utils/fieldValidator';

// Styles
const useStyles = makeStyles(() => ({
  idCell: {
    borderLeft: '3px solid transparent',
    height: '100%', // set height: 0 to "td" to make div height: 100% work
  },
  idCellContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
}));

interface IdColumnProps {
  columns: IReviewColumn[];
  product: IProduct;
  children: ReactNode;
}

export const IdCell: FC<IdColumnProps> = ({ columns, product, children }) => {
  const classes = useStyles();

  const errorList = useMemo(() => {
    const result: TErrorInfoData[] = [];

    columns.forEach(column => {
      const commonErrorMessages = commonValidator(product, column).errorMessages;
      const errorMessages = column.valueValidator?.(product)?.errorMessages || [];
      // EAN's [valueValidator] already checked
      if (column.field !== 'EAN') {
        errorMessages.push(...commonErrorMessages);
      }

      if (errorMessages.length) {
        result.push({ label: column.columnName || column.field, messages: errorMessages });
      }
    });

    return result;
  }, [columns, product]);

  let borderColor = STATUS_COLORS.SUCCESS;
  let background = STATUS_COLORS_OPACITY.SUCCESS;
  if (errorList.length) {
    borderColor = STATUS_COLORS.ERROR;
    background = STATUS_COLORS_OPACITY.ERROR;
  }

  return (
    <TableCell className={classes.idCell} style={{ background, borderLeftColor: borderColor }}>
      <div className={classes.idCellContent}>
        {children}
        {!!errorList.length && <ErrorInfoIcon clickToTrigger messageButtlet errors={errorList} />}
      </div>
    </TableCell>
  );
};
