import { ROUTES, MENU_ITEMS } from '../permissions';
import { extractURLParam } from './urlUtils';

/**
 * Checks URL path to decide if navItem should be the one selected.
 * Returns the [MANU_ITEMS] title responding.
 * 
 * @param {String} urlPath the path do check.
 */
export const getSelectedMenuItemTitle = (urlPath, isStore = false, isSuperuser = false) => {
    const isNonFood = extractURLParam('nonfood', window.location) === "1";
    // Order is important.
    if (urlPath.includes('/me/products')) return MENU_ITEMS.storeProducts.title;
    if(urlPath === ROUTES.dashboard.path) return MENU_ITEMS.dashboard.title;
    if(urlPath === ROUTES.producerData.path) return MENU_ITEMS.producerData.title;
    if(urlPath === ROUTES.superuserData.path) return MENU_ITEMS.superuserData.title;
    if(urlPath === ROUTES.superuserProducers.path) return MENU_ITEMS.superuserProducers.title;
    if(urlPath.includes(ROUTES.superuserBulkReview.path)) return MENU_ITEMS.superuserBulkReview.title;
    if(urlPath === ROUTES.superuserOrganizations.path) return MENU_ITEMS.superuserAllOrganizations.title;
    // if(urlPath === ROUTES.superuserExportProducts.path && isNonFood) return MENU_ITEMS.superuserExportNonFoodProducts.title;
    // if(urlPath === ROUTES.superuserExportProducts.path) return MENU_ITEMS.superuserExportProducts.title;
    if(urlPath === ROUTES.superuserFailedProducts.path) return MENU_ITEMS.failedProducts.title;
    if(isSuperuser && (urlPath === '/producers' || urlPath.includes('/producers?'))) return MENU_ITEMS.superuserAllProducers.title;
    if(urlPath.includes('/producers')) return MENU_ITEMS.producers.title;
    if(urlPath.includes(ROUTES.me.path)) return MENU_ITEMS.me.title;
    if(urlPath.includes(ROUTES.superuserProducts.path)) return MENU_ITEMS.superuserProducts.title
    if((isStore || isSuperuser) && urlPath.includes(ROUTES.products.path)) return MENU_ITEMS.storeAllProducts.title;
    if(isSuperuser && urlPath.includes(ROUTES.superuserAllProducersList.path)) return MENU_ITEMS.superuserAllProducers.title;
    if(urlPath.includes(ROUTES.products.path)) return MENU_ITEMS.products.title;
    if(urlPath.includes(ROUTES.orders.path)) return MENU_ITEMS.orders.title;
    if(urlPath.includes('/order')) return MENU_ITEMS.orders.title;
    return '';
}