/* eslint-disable react-hooks/rules-of-hooks */
// Dependencies
import React, { useEffect, useContext, useState, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import FormControl from '@material-ui/core/FormControl';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import byteSize from 'byte-size';
import isNil from 'lodash/isNil';
import browserPath from 'path-browserify'
// import browserPath from 'path';

import { KeycloakContext } from '../../Secured';
import Vision from '../../vision';

import {
  append,
  compose,
  concat,
  filter,
  find,
  forEach,
  includes,
  isEmpty,
  map,
  path,
  pathOr,
  uniqBy,
  without,
} from 'ramda';

// Components
import InputTextField from '../../inputfields/InputTextField';
// import FloatInput from './FloatInput';
import ThreeWaySwitch from '../../inputfields/ThreeWaySwitch';
import ImageUploader from '../../fileuploader/ImageUploader';
import InputLabel from "../../inputfields/InputLabel";
import ListDropDown from "../../inputfields/ListDropDown";
import MultipleSelect from "../../inputfields/MultipleSelect"
import ImageSorter from '../../images/ImageSorter';
import CircularSpinner from '../../loadingIndicator/CircularSpinner';
import MarkedProduct from '../MarkedProduct';
// import StockToggleButton from '../StockToggleButton';
import { ResponseSnackBar } from '../../snackbar/SnackBar';

import Chat from "../../chat";

// Constants
import COLORS from '../../../constants/colors';
import { LINKS, PRODUCT_TAGSTATES } from '../../../constants/domain';
import { SUPERUSER_APPROVAL, APPLICATION_ROLES, PRODUCT_STATUS } from '../../constants';
import {
  requiresClassification,
  optionalAlcoholPercentage,
  preparedForm,
  additionalCharacteristics,
  categoryWeightloss
} from '../../constants-ts';

import useCategoriesData from 'hooks/useCategoriesData';

// Mutations
import {
  MUTATION_SUPERUSER_APPROVE,
  S3_SIGNED_REQUEST_MUTATION,
  MUTATION_ADD_PRODUCT,
  MUTATION_REMOVE_PRODUCT,
  MUTATION_RESTORE_PRODUCT,
  MUTATION_PRODUCER_UPDATE_PRODUCT,
} from '../../../graphql/mutations';

// Queries
import {
  // SUPERUSER_STATISTICS_QUERY,
  PRODUCT_INFO_FOR_UPDATE_QUERY,
  // ALL_PRODUCTS_QUERY,
  GET_PRODUCT_BY_ID,
  CHECK_UNIQ_EAN,
} from '../../../graphql/queries';

// Utils
import { isDefined, isNotDefined } from '../../../utils/helpers';
import {
  uploadImages,
  getImagesForSorter,
  getImageWithBGRemoved,
  downloadImageAsFile,
} from '../../../utils/images';
import { extractProductImagesToSingleArray } from 'utils/images2';

import {
  requiredNutritionalFields,
} from './constants';
import SubmitButton, { checkEanErrorForIcaStoreProducer } from './SubmitButton';
import useStyles from './useStyles';
import {
  getInitialState,
  getShowRed,
  getProductMutationInput,
} from './utils';
import Alerts from './alerts';

import Address from './fields/address';
import Brand from './fields/brand';
import Category,
{
  isNewFood,
  isNewNonFood,
  isFish,
  isFoodSupplements,
  isGoods,
  isJewelry,
  isElectric,
  isToy,
  isPharma,
  isChemical,
  isMedical,
  isComplementarySupplements,
  isDetergent,
  isSunscreen,
  isOTC,
  isFeedCategory,
  isCosmetic,
  isCmrHazardCategory,
  isSpecialFoodNutrition,
  isMeat,
  isBakeryProducts,
  isMilk,
  isCereals,
  isConfectionery,
  isConsumptionReadySnacks,
  isSweet,
  isFats,
  isIceCream,
  isEgg,
  isBeverages,
  isFruktOrGreen,
  isOtherFoodstuffs,
  isSpicesSoupsSalads,
  isOtherFoodGroup,
  isFeedMaterial,
  isFeedMixtures,
  isCompleteFeed,
  isMagazine,
  isParentCategoryChanged,
  removeUnnecessaryAprroveFields,
} from './fields/category';
import Descriptive from './fields/descriptive';
import Dimensions from './fields/dimensions';
import GrossWeight from './fields/gross-weight';
import Ingredients from './fields/ingredients';
import Labels from './fields/labels';
import NonFoodIngredients from './fields/non-food-ingredients';
import Nutrition from './fields/nutrition';
import Vitamins from './fields/vitamins';
// import PackageSize from './fields/package-size';
import ProducerInfo from './fields/producer';
import BulkImportFile from './fields/bulk-import-file';
import ProducerNotAccepted from './fields/producer-not-accepted';
import Review from './fields/review';
import Title from './fields/title';
import Temperature from './fields/temperature';
import GrossDimensions from './fields/gross-dimensions';
import SearchTerms from './fields/search-terms';
import GenericField from './fields/generic-field';
import CountryOfManufacturing from './fields/country-of-manufacturing';

import { isWrongCoopStoreEAN } from './alerts-check';
// import CoopProductJson from './coop-json';
// import ApoteaProductJson from './apotea-json';
import ApoteaXml from './apotea-xml';
import gql from 'graphql-tag';
import AlternativeTitle from './fields/alternative-title';
import Pallet from './fields/pallet';
import PalletLayer from './fields/pallet-layer';
import Transport from './fields/transport';
import Price from './fields/price';
import PurchasingData from './fields/purchasing-data';
import ShortSalesDescription from './fields/short-sales-description';
import FishFields from './fields/fish-fields';
import FeedFields from './fields/feed-fields';
import AnimalFields from './fields/animal-fields';
import HazardFields from './fields/hazard-fields';
import AdditionalGroupFields from './fields/additional-group-fields';
import FeedApprovalFields from './fields/feed-approval';
import SunScreenFields from './fields/sun-screen-fields';
import CmrFields from './fields/cmr-fields';
import ReachSubstancesFields from './fields/reach-substances-fields';
import HazardousSubstancesFields from './fields/hazardous-substances-fields';
import MedicalTechnologyFields from './fields/medical-technology-fields';
import QualityReviewFields from './fields/quality-review-fields';
import OrderableUnitFields from './fields/orderable-unit-fields';
import DespatchUnitFields from './fields/despatch-unit-fields';
import EANForStorePack from './fields/ean-for-store-pack-fields';
import QtyOfBaseItemFields from './fields/qty-of-base-item-fields';
import ConsumerPack from './fields/consumer-pack';
import StorePack from './fields/store-pack';
import BasicData from './fields/basic-data';
import ShortText from './fields/short-text';
// import Details from './fields/details';
import useUnpaidInvoiceData from 'hooks/useUnpaidInvoiceData';
import Approvements from './approvements';
import PurchaseRelatedFields from './fields/purchase-related-fields';
import IcaProductJson from './ica-json';
import CoopExportJson from './coop-export-json';
import useViewport from 'hooks/useViewports';
import FillProductByImage from './FillProductByImage';
import LogisticQuestionsWrapper from './LogisticQuestionsWrapper';
import ReachSubstance from './fields/reach-substance';
import { isBlank } from 'utils/helpers-ts';
import AIReviewer from './AIReviewer';
import TagLabel from 'components/TagLabel';

const ignoreTabsFields = [
  'title',
  'EAN',
  'short_text',
  'ingredient_statement',
  'trade_item_temperature_information',
  'place_of_item_activity',
  'additional_required_information',
  'search_terms',
  'brand_food',
  'location',

  'brand',
  'color',
  'material',
  'non_food_ingredients',
  'dimensions',
  'capacity',
  // 'country_of_origin',
];
  
const getControlDigit = (ean) => {
  if (ean) {
    const EAN = ean.trim();
    if (EAN.length === 13 || EAN.length === 12) {
      const total =
      (parseInt(EAN[0]) + parseInt(EAN[2]) + parseInt(EAN[4]) + parseInt(EAN[6]) + parseInt(EAN[8]) + parseInt(EAN[10]))
      + 3 * (parseInt(EAN[1]) + parseInt(EAN[3]) + parseInt(EAN[5]) + parseInt(EAN[7]) + parseInt(EAN[9]) + parseInt(EAN[11]));
      let control = 10 - total % 10;
      if (control === 10) control = 0;
      return control;
    }
  }
  return undefined;
};

export const checkEANValue = (state, uniqResult) => {
  const checkEAN = (ean) => {
    const EAN = ean.trim();
    if (EAN.length === 13) {
      const control = getControlDigit(ean);
      return control === parseInt(EAN[12]);
    }
    return true;
  };

  let eanLengthError;
  let eanControlError;
  let eanWrongCoopStoreError;
  let nonUniqError;
  if (state.EAN && state.EAN.trim() !== '' && !uniqResult) {
    if (state?.producerIsStore) {
      nonUniqError = "Det verkar som om att du redan använt denna EAN-kod på en av dina produkter i Foodla, vänligen ange en annan EAN-kod.";
    } else {
      nonUniqError = "Det verkar som om att EAN-koden du försöker ange redan existerar i Foodla, vänligen ange en annan EAN-kod. Kontakta oss på support@foodla.nu om det verkar som om någon annan har använt din produkts EAN-kod!";    
    }
  }

  if (state.EAN && state.EAN.trim() !== '' && state.EAN.trim().length !== 8 && state.EAN.trim().length !== 13) {
    eanLengthError = 'Vänligen ange konsumentförpackningens EAN-kod med 8 eller 13 siffror.';
  } else if (
    state.EAN
    && state.EAN.trim().length === 13
    && !state.EAN.trim().startsWith('23')
    && !state.EAN.trim().startsWith('2000')
    && !state.EAN.trim().startsWith('2095')
    && !state.EAN.trim().startsWith('2096')
    && !state.EAN.trim().startsWith('2097')
    && !checkEAN(state.EAN)
  ) {
    eanControlError = `Kontrollsiffran (sista siffran i EAN-koden) "${state.EAN[12]}" är felaktig.`;
  } else {
    eanLengthError = undefined;
    eanControlError = undefined;
  }

  if (state?.EAN && state.EAN.trim() !== '' && isWrongCoopStoreEAN(state)) {
    eanWrongCoopStoreError = 'Det verkar som ni har angett en EAN-kod som inte tillhör det range som Coop Online har godkänt för butiksproducerade artiklar online. För att vi ska kunna släppa igenom produkten till coop.se, vänligen vårda om produkten för försäljning på en EAN-kod som inleds med följande siffror och ange samma EAN-kod på produkten i Foodla: 20001, 20002, 20003, 20004, 20005, 20006, 20007, 20008, 200090000000, 20975, 20976, 20977, 20978, eller 20979. Om artikeln kommer från en extern leverantör får ni gärna meddela support@foodla.nu så hjälper vi till att få upp artikeln via leverantören. Informera oss då om leverantörens namn och om möjligt en kontaktperson hos leverantören.';
  } else {
    eanWrongCoopStoreError = undefined;
  }

  const eanNotOnlyDegit = !!state.EAN && /[^0-9]/.test(state.EAN);
  const icaStoreProcucerEanError = checkEanErrorForIcaStoreProducer(state);

  return {
    eanLengthError,
    eanControlError,
    eanWrongCoopStoreError,
    nonUniqError,
    eanNotOnlyDegit,
    icaStoreProcucerEanError,
  }
}

const ProductForm = ({
  showTitleHeader,
  producerData,
  isCreatingNew,
  product,
  userRole,
  approve,
  refetchQuery,
  refetchVariables,
  productLoading,
}) => {
  const { mdUp } = useViewport();
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);
  const isUserStore = keycloakCtx.keycloak.hasResourceRole('store');

  const location = useLocation();
  const itsMe = location?.pathname?.startsWith('/me/products');
  const isLocal = process.env.REACT_APP_AUTH_ENV === 'local';
  const isDev = process.env.REACT_APP_AUTH_ENV === 'dev';
  // const itsMe = match?.url?.startsWith('/me/products');
  const classes = useStyles();
  const allExistingProductImages = isCreatingNew ? [] : extractProductImagesToSingleArray(product);
  const imagesForSorter = getImagesForSorter(allExistingProductImages);
  
  const initialState = getInitialState(isCreatingNew ? {} : product, producerData);
  const isProducer = userRole === APPLICATION_ROLES.PRODUCER || userRole === APPLICATION_ROLES.STORE;
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const [state, setState] = React.useState(initialState);

  const [files, setFiles] = React.useState([]);
  const [uploadDone, setUploadDone] = React.useState(false);
  const [imageIsUploading, setImageIsUploading] = React.useState(false);
  const [imageUploadError, setImageUploadError] = React.useState(false);
  const [sortedImages, setImagesSorted] = React.useState(imagesForSorter);
  const [imagesToBeRemoved, setImagesToBeRemoved] = React.useState([]);
  const [loadingAIPicture, setLoadingAIPicture] = React.useState(false);
  const [failedAIRequest, setFailedAIRequest] = React.useState({
    error: false,
    message: '',
    imageName: '',
  });
  const [imagesRejected, setImagesRejected] = React.useState([]);
  const [largeFiles, setLargeFiles] = React.useState([]);
  const [processingLargeFiles, setProcessingLargeFiles] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [loadingTiff, setLoadingTiff] = React.useState(false);
  const [approved, setApproved] = React.useState(true);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(false);
  const [isDespatchUnitsChanged, setIsDespatchUnitsChanged] = React.useState(false);
  const [coopExportProduct, setCoopExportProduct] = React.useState({});
  const [isAiChangedState, setIsAiChangedState] = useState(false);
  const [isChangedBrand, setChangedBrand] = useState(false);

  const isCoopStoreProduct = !!state.producerIsStore && state.producerOrg?.startsWith('coop/');

  const unpaidInvoiceData = useUnpaidInvoiceData();

  const [restoreProductMutation] = useMutation(MUTATION_RESTORE_PRODUCT, {
    refetchQueries: refetchQueries(),
    awaitRefetchQueries: true,
  });

  const { loading: categoryLoading, error: categoryError, rootCategory, categories } = useCategoriesData();


  const [removeProductMutation] = useMutation(MUTATION_REMOVE_PRODUCT, {
    refetchQueries: refetchQueries(),
  });

  const isGranularCategory = () => {
    if (categories && state?.foodlaCategory?.id) {
      const category = categories.find((x) => x.parentCategoryId === state.foodlaCategory.id);
      if (category) return false;
    }
    return true;
  };
  const showCategoryError = isSuperuser && !categoryLoading && !productLoading && (!state?.foodlaCategory?.id || state.foodlaCategory.id.trim() === '' || !isGranularCategory());

  useEffect(() => {
    const showRed = getShowRed(state);
    setState({...state, showRed});
  }, requiredNutritionalFields.map(x => state[x]));

  React.useEffect(() => {
    if (state.approve) {
      console.log('!!!approve', state.approve);
    }
  }, [state?.approve]);

  useEffect(() => {
    setState(getInitialState(product, producerData));
    // if (product?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED) {
    //   setState({...state, approve: []});
    // }
  }, [product, producerData]);

  React.useEffect(() => {
    const event = isCreatingNew ? 'createProductView' : 'updateProductView';
    window.dataLayer.push({
      event: event,
      product: {
        id: state?.id,
        name: state?.title,
        foodlaCategory: state?.foodlaCategory?.name,
      },
    });
  }, [isCreatingNew]);

    // check approve status for product's fields (superuser)
  React.useEffect(() => {
    if (state && state.approve && state.approve.length > 0 && isSuperuser && approve) {
      const approveList = removeUnnecessaryAprroveFields(state, rootCategory);
      const hasNotApprovedField = approveList.some((x) => x.state !== true);
      setApproved(!hasNotApprovedField);
    } else {
      setApproved(true);
    }
  }, [state, state.approve, isSuperuser, approve, product, rootCategory]);

  React.useEffect(() => {
    if (state) {
      let ean;
      let disable = true;
      if (state.EAN) {
        ean = state.EAN.trim();
        checkUniqEAN({
          id: state.id, 
          EAN: state?.EAN,
          EAN2: state?.EAN2,
          storeId: state?.producerIsStore ? state?.producerId : null,
        });
      }
      if (
        ean
        && ean.length === 13
        && (
          ean.startsWith('23')
          || ean.startsWith('2095')
          || ean.startsWith('2096')
          || ean.startsWith('2097')
          || ean.startsWith('2000')
        )
      ) {
        if (ean[8] !== '0' || ean[9] !== '0' || ean[10] !== '0' || ean[11] !== '0') {
          let newEan = ean.substring(0, 8) + '0000';
          const control = getControlDigit(newEan);
          if (!isNil(control)) {
            newEan += control.toString();
          }
          disable = false;
          setState({...state, EAN2: newEan});
        } else if (ean[8] === '0' && ean[9] === '0' && ean[10] === '0' && ean[11] === '0') {
          const control = getControlDigit(ean);
          if (!isNil(control)) {
            if (control.toString() !== ean[12]) {
              const newEan = ean.substring(0, 12) + control.toString();
              disable = false;
              setState({...state, EAN2: newEan});
            }
          }
        }
      }
      if (disable) {
        setState({...state, EAN2: null});
      }
    }
  }, [state.EAN]);

  const history = useHistory();

  const gotoCreatedProduct = data => {
    const { createProduct: { productId, success } } = data;
    if (!success || isNotDefined(productId)) return;
    const url = itsMe
      ? `/me/products/${productId}/show`
      : `/products/${productId}/show`;
    history.push(url);
  };

  // ---------- MUTATIONS ---------- //

  const [s3SignedRequest, { data: requestData, error: requestError, loading: requestLoading }] = useMutation(
    S3_SIGNED_REQUEST_MUTATION,
    {
      refetchQueries: [],
      onCompleted: data => {
        const allExistingImages = extractProductImagesToSingleArray(product);
        const imagesForSorter = getImagesForSorter(allExistingImages, data, files);
        setImagesSorted(imagesForSorter);
      },
    }
  );

  // Using special mutation for images that is to large. We don't want to trigger the onCompleted function then.
  const [
    s3SignedRequestForLargeFiles,
    { data: largeFilesRequestData, loading: largeFilesRequestLoading },
  ] = useMutation(S3_SIGNED_REQUEST_MUTATION, {
    refetchQueries: [],
  });

  const isDownload = isSuperuser;
  const isEdit = isSuperuser;

  const refQuery = () => {
    if (!isCreatingNew && refetchQuery) {
      refetchQuery({ id: product.id });
    }
  };
  const [
    updateProduct,
    { data: updateProductData, loading: updateProductLoading, error: updateProductError, refetch: updateProductRefetch },
  ] = useMutation(isSuperuser ? MUTATION_SUPERUSER_APPROVE : MUTATION_PRODUCER_UPDATE_PRODUCT, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: data => {
      const allExistingProductImages = extractProductImagesToSingleArray(product);
      console.log('!!!allExistingProductImages.completed', allExistingProductImages);
      const imagesForSorter = getImagesForSorter(allExistingProductImages);
      console.log('!!!imagesForSorter.completed', imagesForSorter);
      setImagesSorted(imagesForSorter);
      if (data?.updateProduct?.success) {
        // setState({...state, product});
        if (refetchQuery) {
          refetchQuery({ id: product.id });
          // if (refetchVariables) {
          //   refetchQuery(refetchVariables);
          // } else {
          //   refetchQuery();
          // }
        }
      }
      // setState(data)
      // refetchQueries();
    },
  });

  const isNotStore = !state?.producerIsStore;
  const [
    createProductMutation,
    { data: createProductData, loading: createProductLoading, error: createProductError },
  ] = useMutation(MUTATION_ADD_PRODUCT, {
    refetchQueries: refetchQueries(),
    awaitRefetchQueries: true,
    onCompleted: data => {
      setFiles([]);
      setUploadDone(false);
      setImageIsUploading(false);
      setImageUploadError(false);
      setImagesSorted([]);
      setImagesToBeRemoved([]);
      setLoadingAIPicture(false);
      setFailedAIRequest({ error: false, message: '', imageName: '' });
    },
  });

  const { data: uniqEANResult, refetch: checkUniqEAN } = useQuery(CHECK_UNIQ_EAN, {
    variables: {
      id: state.id, 
      EAN: state?.EAN,
      EAN2: state?.EAN2,
      storeId: state?.producerIsStore ? state?.producerId : null,
    },
    // skip: true,
  });

  const productStatus = pathOr('', ['productStatus'], product);
  const markedForRemoval = productStatus === PRODUCT_STATUS.MARKED_FOR_REMOVAL;
  const markedDeleted = productStatus === PRODUCT_STATUS.DELETED;
  const showMarkedForRemovalComponent = isProducer && markedForRemoval;
  const showMarkedComponent = (isProducer && markedForRemoval) || markedDeleted;

  const showForm = !updateProductLoading && !imageIsUploading && !createProductLoading && !showMarkedComponent;
  const isFood = isNewFood(state?.foodlaCategory, rootCategory);
  const isNonFood = isNewNonFood(state?.foodlaCategory, rootCategory);

  const uniqResult = !isProducer || uniqEANResult?.checkUniqEAN;
  // console.log('!uniqResult', uniqResult);



  // ------------------------------- //

  const getNewName = (name) => {
    if (name) {
      const ext = browserPath.parse(name).ext;
      return `${Date.now()}_${state.EAN}${name.includes('AIGENERATED') ? '_AIGENERATED' : ''}${ext}`;
    }
    return name;
  };
  const getS3Requests = (images, isLargeFilesRequest) => {
    // console.log('!!!files1', images);
    // const files = images.map(i => ({ fileName: getNewName(i.name), fileType: i.type }));
    const files = images.map(i => ({ fileName: i.name, fileType: i.type }));
    // console.log('!!!files2', files);
    const mutationMethod = isLargeFilesRequest ? s3SignedRequestForLargeFiles : s3SignedRequest;

    mutationMethod({
      variables: {
        input: {
          files,
          producerName: isCreatingNew ? path(['username'], producerData) : path(['producer', 'username'], product),
          id: state?.id,
          ean: state?.EAN,
          renameFiles: true,
        },
      },
    });
  };


  // --------- LARGE FILES --------- //
  /*
  1. Check if there are large files present in state.
  2. If so, start processing. Set processing state to guard this section.
  3. Upload the files to S3.
  4. When uploading is complete, use the URL to retrieve a compressed version.
  5. Try to send it to removeBG.
  5. If successful, store the image amongst the others to later on be processed and uploaded.
  */

  if (!isEmpty(largeFiles) && isDefined(largeFilesRequestData) && !largeFilesRequestLoading && !processingLargeFiles) {
    setProcessingLargeFiles(true);
    setLoadingAIPicture(true);
    const largeFilesRequests = path(['getS3SignRequest', 'requests'], largeFilesRequestData);
    const largeImages = map(tlf => tlf.file, largeFiles);

    uploadImages(largeImages, largeFilesRequests)
      .then(() => {
        // Append compression and enhancement param on urls.
        const processList = map(r => {
          const downloadUrl = `${r.pictureUrl}?quality=100&auto=compress,enhance`;
          const orgFile = find(f => f.tempName === r.fileName, largeFiles);
          const fileName = isDefined(orgFile) ? orgFile.orgName : r.fileName;
          return { downloadUrl, orgFile, fileName };
        }, largeFilesRequests);

        // Access the URLs from which to download files. Store in promise array.
        const downloadPromises = map(
          // deepcode ignore Ssrf: fixed by ssrfFilter
          d => downloadImageAsFile(d.downloadUrl, d.fileName, path(['orgFile', 'type'], d)),
          processList
        );

        let downloadErrors = [];
        let removeBGErrors = [];
        // Resolve the promises.
        Promise.allSettled(downloadPromises).then(values => {
          // const removeBgPromises = map(v => getImageWithBGRemoved(v.value), values);
          const removeBgPromises = compose(
            map(v => getImageWithBGRemoved(v.value, token)),
            filter(v => v.status === 'fulfilled')
          )(values);

          const rejectedDownloads = filter(v => v.status === 'rejected', values);
          downloadErrors = rejectedDownloads;

          // Now access the RemoveBG API again with compressed images.
          Promise.allSettled(removeBgPromises)
            .then(bgValues => {
              // Access the retrieved files of the resolved promises when calling RemoveBG API.
              const removeBGFiles = compose(
                map(v => v.value),
                filter(v => v.status === 'fulfilled'),
                filter(v => !isDefined(v.value.error))
              )(bgValues);

              const rejectedRemoveBG = compose(
                map(v => path(['value'], v)),
                filter(v => isDefined(v.value.error))
              )(values);
              removeBGErrors = rejectedRemoveBG;

              const allFiles = concat(files, removeBGFiles);
              setFiles(allFiles);
              getS3Requests(allFiles);
            })
            .finally(() => {
              if (!isEmpty(removeBGErrors)) {
                // Error calling removeBG API.
                setFailedAIRequest({
                  error: true,
                  imageName: `${map(e => e.fileName, removeBGErrors)}`,
                  message: `${map(e => e.error, removeBGErrors)}`,
                });
              }

              if (!isEmpty(downloadErrors)) {
                // Error downloading images.
                setFailedAIRequest({
                  error: true,
                  imageName: 'Compressed image',
                  message: 'Could not complete image processing. Image(s) may still be too large after compression.',
                });
              }

              setLoadingAIPicture(false);
            });
        });
      })
      .finally(() => {
        // Done processing large files.
        // Remember, set the state of files to empty array, not to trigger this in infinite loop.
        setLargeFiles([]);
        setProcessingLargeFiles(false);
      });
  }

  // ------------------------------- //

  const onDelete = () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }

    removeProductMutation({
      variables: {
        productId: product?.id,
      },
    });

    setConfirmDelete(false);
  };

  const restoreProduct = () => {
    restoreProductMutation({
      variables: {
        productId: product?.id,
      },
    });
  };

  const onImageDropped = async images => {
    setLoadingAIPicture(true);

    const resolvedImages = [];
    const tooLargeImages = [];

    for (var i = 0; i < images.length; i++) {
      let im = images[i];
      // im = await setDpi(im);
      const type = im.type;

      const imageSize = byteSize(im.size);
      const orgFileName = im.name;
      const name = isDefined(im.name) ? im.name.split('.')[0] : '';

      im = new File([im], name, { type });
      im.path = name;

      // Check file size
      const { value, long } = imageSize;
      if (long === 'megabytes' && value > 11.9) {
        // Image too large.
        const tempName = `temporary_${i}`;
        const largeImage = new File([im], tempName, { type });
        tooLargeImages.push({ orgName: name, file: largeImage, tempName, type });
      } else {
        // Get AI images
        setFailedAIRequest({ error: false, imageName: '', message: '' });
        const iBg = await getImageWithBGRemoved(im, token);

        if (iBg.error) {
          setFailedAIRequest({
            error: true,
            imageName: isDefined(orgFileName) ? orgFileName : name,
            message: iBg.error,
          });
        } else {
          resolvedImages.push(iBg);
        }
      }
    }

    // Now handle images that where too large to process by RemoveBG.
    if (!isEmpty(tooLargeImages)) {
      setLargeFiles(tooLargeImages);
      getS3Requests(
        map(tl => tl.file, tooLargeImages),
        true
      );
    }

    // Append to existing images (images that's not too large)
    const allNewImages = concat(images, resolvedImages);
    const allImages = concat(files, allNewImages);

    // Done (apart from eventually too large images, those gets handled when data for their S3 requests arrives)
    setLoadingAIPicture(false);
    setFiles(allImages);
    getS3Requests(allImages);
  };

  const onImagesRejected = images => {
    setImagesRejected(images);
  };

  const onImageActionClicked = image => {
    if (includes(image, imagesToBeRemoved)) {
      const toBeRemoved = without([image], imagesToBeRemoved);
      setImagesToBeRemoved(toBeRemoved);
    } else {
      const toBeRemoved = append(image, imagesToBeRemoved);
      const uniq = uniqBy(i => i.pictureUrl, toBeRemoved);
      setImagesToBeRemoved(uniq);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    // Enforce float if descriptive_size_amount
    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  const handleBooleanChange = ({ target }, checked) => setState({ ...state, [target.name]: checked });

  const handleDropdownChange = (event) => {
    const stateName = event.target.name;
    const stateValue = event.target.value;

    const val = !stateValue || stateValue === '' ? null : stateValue;
    stateName && setState({ ...state, [stateName]: val });
  };

  const handleEANChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (event.nativeEvent.inputType === 'insertText') {
      if (event.nativeEvent.data && !/^\d+$/.test(event.nativeEvent.data)) {
        // when user type a character which is not a digit
        return;
      }
    } else if (event.nativeEvent.inputType === 'insertFromPaste') {
      // remove white space from paste
      value = value.replace(/\s+/g, '');
    }

    // Enforce float if descriptive_size_amount
    const newState = {...state, [name]: value};
    if (value && (value.trim().startsWith('23') || value.trim().startsWith('20'))) {
      if (isCreatingNew && (!state.descriptive_size_unit || state.descriptive_size_unit.trim() !== '')) {
        newState.descriptive_size_unit = 'GRAM_APPROXIMATE';
      }
    }
    setState(newState);
  };

  const handleChangePlaceOfActivity = ({ target: { name, value } }) => {
    const newState = {
      ...state
    };
    newState[name] = value;
    if (value && value.trim().includes('Sverige') && (!state.country_of_manufacturing || state.country_of_manufacturing === '')) {
      newState.country_of_manufacturing = 752;
    }
    setState(newState);
  };

    const isProductionMode = process.env.REACT_APP_AUTH_ENV === 'prod';

  const handleCheck = event => {
    setState({ ...state, quantity_in_stock: event.target.checked ? 0 : 1 });
  };

  async function submitApproval(event) {
    event.preventDefault();

    const evt = isCreatingNew ? 'createProduct' : 'updateProduct';
    window.dataLayer.push({
      event: evt,
      product: {
        id: state?.id,
        name: state?.title,
        foodlaCategory: state?.foodlaCategory?.name,
      },
    });

    if (isSuperuser && state.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && approved) {
      state.approve = [];
    }

    const signedRequests = path(['getS3SignRequest', 'requests'], requestData);
    console.log('!!!sortedImages - signedRequests', signedRequests);
    console.log('!!!sortedImages', sortedImages);

    const allImageUrls = map(i => i.pictureUrl, sortedImages);
    const allToBeDeleted = map(i => i.pictureUrl, imagesToBeRemoved);
    const imagesToSave = without(allToBeDeleted, allImageUrls);

    const requestsForImagesToSave = isDefined(signedRequests)
      ? filter(r => includes(r.pictureUrl, imagesToSave), signedRequests)
      : [];
    console.log('!!!sortedImages - requestsForImagesToSave', requestsForImagesToSave);

    const addArtwork = (image) => {
      if (image?.pictureUrl && image.pictureUrl.trim() !== '') {
        const updatedImage = {...image};
        const url = new URL(image.pictureUrl);
        if (image.isArtwork) {
          url.searchParams.set("ARTWORK", "1"); 
        } else {
          url.searchParams.delete("ARTWORK");
        }
        updatedImage.pictureUrl = decodeURI(url.href);
        return updatedImage;
      }
      return image;
    }
    const _allImageUrls = map(i => addArtwork(i).pictureUrl, sortedImages);
    const _allToBeDeleted = map(i => i.pictureUrl, imagesToBeRemoved);
    const _imagesToSave = without(_allToBeDeleted, _allImageUrls);


    let mutation = isCreatingNew ? createProductMutation : updateProduct;
    // const newProduct = product;
    // eslint-disable-next-line no-unused-expressions
    // newProduct?.approve?.map((x) => {
    //   x.__typename = 'Approve';
    // });
    console.log('!!!imagesToSave', imagesToSave);
    let mutationInput = getProductMutationInput(product, state, userRole, _imagesToSave, isCreatingNew, rootCategory);
    console.log('!!!mutationInput 1', mutationInput);

    const replaceTabs = true;
    if (replaceTabs) {
      const input = mutationInput?.variables?.input;
      if (input) {
        const keys = Object.getOwnPropertyNames(input);
        keys.map((key) => {
          if (ignoreTabsFields.includes(key)) {
            const value = input[key];
            if (isDefined(value)) {
              mutationInput.variables.input[key] = value.replace(/\t/g, "  ");
              console.log('!!!mutationInput 2', mutationInput);
            }
          }
        });
      }
    }

    console.log('!!!files', files);
    // console.log('!!!mutationInput', mutationInput);

    if (isCreatingNew) {
      // A few components are using "useState(state.{name})"
      // We have to reset state here, but not in "onCompleted",
      //    before these components are mounted again (when "showForm" become True)
      const initialState = getInitialState({}, producerData);
      setState({ ...initialState });
    }

    // Three cases:
    // 1. No images has been added.
    // 2. Images added but error when requesting S3 request.
    // 3. Images added and shall be uploaded.

    // 1.
    if (requestsForImagesToSave.length === 0) {
      mutation(mutationInput);
      console.log('!!!mutationInput 3', mutationInput);
      setFiles([]);
      setImagesSorted([]);
      return;
    }

    // 2.
    if (requestError) {
      // console.log('!!!save images 2');
      mutation(mutationInput);
      console.log('!!!mutationInput 4', mutationInput);
      return;
    }
    // 3.
    else if (requestsForImagesToSave.length > 0 && requestData && !requestError && !requestLoading) {
      setImageIsUploading(true);
      try {
        await uploadImages(files, requestsForImagesToSave);
        mutation(mutationInput);
        console.log('!!!mutationInput 5', mutationInput);
      } catch (e) {
        console.error('Error uploading images', e);
        mutationInput = isCreatingNew
          ? getProductMutationInput(product, state, userRole, [], isCreatingNew, rootCategory)
          : getProductMutationInput(product, state, userRole, allExistingProductImages, false, rootCategory);

        mutation(mutationInput);
        console.log('!!!mutationInput 6', mutationInput);
      }

      setFiles([]);
      setImageUploadError(false);
      setImageIsUploading(false);
      setUploadDone(true);
      setImagesSorted([]);
      setImagesRejected([]);
      setImagesToBeRemoved([]);
    }
  }

  function refetchQueries() {
    let queries = [];
    // let queries = [{ query: ALL_PRODUCTS_QUERY, variables: { filter: { tagState: PRODUCT_TAGSTATES.PENDING } } }];
    if (isSuperuser) {
      queries = queries.concat(queries, [
        // { query: SUPERUSER_STATISTICS_QUERY },
        // { query: DELETED_PRODUCTS_DETAILED_QUERY },
      ]);
    }
    const result = !isCreatingNew
      ? concat(queries, [
          { query: PRODUCT_INFO_FOR_UPDATE_QUERY, variables: { id: path(['id'], product) } },
          { query: GET_PRODUCT_BY_ID, variables: { id: path(['id'], product) } },
        ])
      : queries;
    return result;
  }

  const failedAI = path(['error'], failedAIRequest);
  const failedAIFileName = path(['imageName'], failedAIRequest);
  const failedAIMessage = path(['message'], failedAIRequest);

  const errorMessage = isCreatingNew ? 'Failed to create product...' : 'Failed to update product...';
  let feedback = <></>;

  // --------------- FEEDBACK ON ACTION ---------------- //
  // Loading state
  if (updateProductLoading || imageIsUploading || createProductLoading) {
    feedback = (
      <div className={classes.feedbackContainer}>
        <CircularSpinner />
      </div>
    );
  }
  // Response received
  else if (createProductData || updateProductData || createProductError || updateProductError) {
    let feedbackMessage;
    let success;

    const additionalSuccess = !imageUploadError;
    let additionalMessage = imageUploadError
      ? 'Failed to upload images. Contact support for help.'
      : uploadDone
      ? 'Images uploaded!'
      : '';

    if (isCreatingNew) {
      if (createProductError) feedback = <ResponseSnackBar success={false} message="Failed to create new product" />;
      else
        feedback = (
          <ResponseSnackBar
            success={true}
            message="Done! Click to go to product."
            onSnackBarClicked={() => gotoCreatedProduct(createProductData)}
          />
        );
    } else {
      feedbackMessage = pathOr(errorMessage, ['updateProduct', 'message'], updateProductData);
      success = path(['updateProduct', 'success'], updateProductData);

      feedback = (
        <ResponseSnackBar
          success={success}
          message={feedbackMessage}
          additionalSuccess={additionalSuccess}
          additionalMessage={additionalMessage}
        />
      );
    }
  }

  let imageRejection = <></>;
  if (!isEmpty(imagesRejected)) {
    let message = '';
    forEach(ir => {
      message = message.concat(` ${path(['file', 'path'], ir)} (w: ${ir.width}, h: ${ir.height}) `);
    }, imagesRejected);

    imageRejection = (
      <ResponseSnackBar
        success={false}
        message={message}
        onClosedCallback={() => setImagesRejected([])}
        title={
          imagesRejected.length > 1
            ? 'Images too small, must be at least 900x900'
            : 'Image too small, must be at least 900x900'
        }
        duration={15000}
      />
    );
  }

  // const [ingredient, setIngredient] = React.useState(undefined);

  // const [dimensions, setDimensions] = React.useState({});
  // getDimensions(sortedImages[0]?.pictureUrl).then(d => setDimensions(d));

  const {
    eanLengthError,
    eanControlError,
    eanWrongCoopStoreError,
    nonUniqError,
    eanNotOnlyDegit,
    icaStoreProcucerEanError,
  } = checkEANValue(state, uniqResult);

  let expandVitamins = isSuperuser && (
    state.vitaminA
    || state.vitaminD
    || state.vitaminE
    || state.vitaminK
    || state.vitaminC
    || state.tiamin
    || state.riboflavin
    || state.niacin
    || state.vitaminB6
    || state.folsyra
    || state.vitaminB12
    || state.biotin
    || state.pantotensyra
    || state.kalium
    || state.klorid
    || state.kalcium
    || state.fosfor
    || state.magnesium
    || state.jarn
    || state.zink
    || state.koppar
    || state.mangan
    || state.fluorid
    || state.selen
    || state.krom
    || state.molybden
    || state.jod
  );

  const producerPage = isSuperuser ? `/superuser/producer/${state?.producerId}/edit` : `/me/${state?.producerId}/edit`;

  const isReachSubstanceRequired = (isToy(state?.foodlaCategory, rootCategory)
  || isElectric(state?.foodlaCategory, rootCategory)
  || isJewelry(state?.foodlaCategory, rootCategory)
  || isChemical(state?.foodlaCategory, rootCategory)
  || isDetergent(state?.foodlaCategory, rootCategory)
  || isCosmetic(state?.foodlaCategory, rootCategory)
  || isSunscreen(state?.foodlaCategory, rootCategory));

  // Check EAN required
  const [focusedEAN, setFocusedEAN] = useState(false);

  const requireEANError = useMemo(() => {
    if (isCreatingNew && !focusedEAN && !state.isDraft) {
      return '';
    }

    if (isBlank(state.EAN)) {
      return 'Vänligen fyll i detta fält eller spara som utkast.';
    }
    return '';
  }, [focusedEAN, state, isCreatingNew]);

  return (
    <>
      {isSuperuser && showForm && (
        <Alerts
          state={state}
          sortedImages={sortedImages}
          nonUniq={uniqResult}
          isCoopStoreProduct={isCoopStoreProduct}
          isNoHasUnpaidInvoices={!unpaidInvoiceData.error && !unpaidInvoiceData.list.length}
          categories={categories}
        />
      )}
      {imageRejection}
      {feedback}
      {showMarkedComponent && (
        <MarkedProduct
          product={product}
          showActionButton={isSuperuser}
          onActionButtonPressed={restoreProduct}
          actionButtonTitle="Restore product"
        />
      )}
      {showForm && (
        <>
          {isSuperuser && (
            <ProducerNotAccepted
              product={product}
            />
          )}

          <form
            className={classes.form} 
            noValidate onSubmit={e => submitApproval(e)} 
            id={isCreatingNew ? 'create-product-form' : 'update-product-form'}
          >
            <Card className={classes.card}>
              {showTitleHeader && product?.title && (
                <h3 style={{ marginBottom: 0, textAlign: 'center' }}>
                  {product.title}
                  <p style={{ fontWeight: 400, margin: 0 }}>{state.producerName}</p>
                </h3>
              )}
              <div
                id="images"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  minHeight: isFood && isSuperuser && approve && state?.approve?.find((x) => x.field === 'images') && 270,
                }}
              >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  {(loadingAIPicture || loadingTiff) ? (
                    <div className={classes.feedbackContainer} style={{ margin: 10 }}>
                      <CircularSpinner />
                      <p
                        style={{
                          fontSize: 12,
                          textAlign: 'center',
                        }}
                      >
                        Foodla tar nu automatiskt bort bakgrunden från din bild
                      </p>
                    </div>
                  ) : (
                    <>
                    <ImageUploader
                      // containerStyle={{flex: 0.9}}
                      newUi
                      images={sortedImages}
                      withOutImagePreview
                      onImageDropped={onImageDropped}
                      withLabel
                      labelOptions={{
                        heading: 'Produktbild för e-handel',
                        text:
                          'Första bilden i listan blir visningsbild i listor hos retailer. För vad som bör tänkas på för foton, se vår foto-guide.',
                        link: 'https://foodla.nu/en/foodla-faq-fota-en-produktbild/',
                        required: true,
                      }}
                      withDimensionCheck
                      onImagesRejected={onImagesRejected}
                      setLoadingTiff={setLoadingTiff}
                    >
                      <ImageSorter
                        newUi
                        images={sortedImages}
                        onDeleteOrKeepImagePressed={onImageActionClicked}
                        onOrderChanged={setImagesSorted}
                        imagesToBeRemoved={imagesToBeRemoved}
                        showMetadata={true}
                        isDownload={isDownload}
                        isEdit={isEdit}
                        shouldShowArtwork={true}
                        disableEdit={isCreatingNew}
                        isMagazine={state?.isMagazine}
                      />
                    </ImageUploader>
                    {failedAI && (
                      <>
                        <p id='ProductForm-AutoGenerateImage-Message-Error' className={classes.failedAIText}>
                          Failed to auto generate image for {failedAIFileName}
                        </p>
                        <p className={classes.failedAIMessagesText}>Reason: {`${failedAIMessage}`}</p>
                        <p className={classes.imageGuideText}>
                          Tip: See the Foodla Foto Guide{' '}
                          <a href={LINKS.IMAGE_GUIDE} target="_blank" rel="noopener noreferrer">
                            here
                          </a>{' '}
                          for help with optimal product photos!
                        </p>
                      </>
                    )}
                    </>
                  )}
              </div>
              {isSuperuser && approve && state?.approve?.find((x) => x.field === 'images') && (
                <div style={{flex: 0.1, maxHeight: 24, alignSelf: 'center'}}>
                    <ThreeWaySwitch
                      state={state}
                      setState={setState}
                      name="images"
                    />
                    {isFood && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={state?.imageUnderProcess}
                            onChange={() => setState({...state, imageUnderProcess: !(state?.imageUnderProcess)})}
                            name="imageUnderProcess"
                            color="primary"
                          />
                        }
                        style={{marginLeft: 5, marginTop: 5}}
                        label="Picture under manual process"
                      />
                    )}
                </div>
              )}
              </div>
              {isSuperuser && (
                <Vision
                  isFood={isFood}
                  images={sortedImages}
                  state={state}
                  setState={setState}
                />
              )}

              <FillProductByImage
                rootCategory={rootCategory}
                producerData={producerData}
                isChangedBrand={isChangedBrand}
                isCreatingNew={isCreatingNew}
                isFood={isFood}
                state={state}
                setState={setState}
                onChangedState={() => setIsAiChangedState(true)}
              />
            </Card>

            <Card className={classes.card}>
              <h3>Produktinformation</h3>

              <Category
                product={product}
                isSuperuser={isSuperuser}
                isUserStore={isUserStore}
                userRole={userRole}
                state={state}
                setState={setState}
                approve={approve && isSuperuser}
                rootCategory={rootCategory}
                categories={categories}
                categoryError={categoryError}
                categoryLoading={categoryLoading}
              />

              <Title
                isCreatingNew={isCreatingNew}
                userRole={userRole}
                state={state}
                setState={setState}
                approve={approve && isSuperuser}
              />

              {isSuperuser && state?.isMagazine && (
                <AlternativeTitle
                  state={state}
                  setState={setState}
                />
              )}

              <Grid container={true}>
                <Grid item={true} xs={state?.EAN2 && state.EAN2.trim() !== '' && mdUp ? 6 : 12}>
                  <InputTextField
                    style={{ marginTop: 7, marginRight: 5 }}
                    label="EAN/GTIN kod på konsumentförpackningen"
                    inputName="EAN"
                    id="EAN"
                    onChange={handleEANChange}
                    onFocus={() => setFocusedEAN(true)}
                    setState={setState}
                    approve={isSuperuser && approve}
                    state={state}
                    disabled={state?.isMagazine}
                    forceRed={(!!eanLengthError && !state.ignoreEANLengthError) || !!eanControlError || !!eanWrongCoopStoreError || !!nonUniqError || !!eanNotOnlyDegit || !!requireEANError || !!icaStoreProcucerEanError}
                    value={state.EAN}
                    withLabel
                    inputClassName="data-hj-allow"
                    labelOptions={{
                      heading: 'EAN-kod (13 eller 8 siffror)',
                      text: 'EAN/GTIN som konsumentförpackningen är märkt med. Normalt EAN/GTIN-13 alternativt EAN/GTIN-8',
                      required: true,
                    }}
                    errorText={requireEANError}
                    helpText="Obligatoriskt för att aktivera produkten, men kan sparas som utkast utan att fyllas i."
                  />
                  {!!eanNotOnlyDegit && (
                  <span style={{ fontWeight: 'bold', color: 'orangered' }}>
                    Only digits allowed
                  </span>
                )}
                </Grid>
                {(eanLengthError || state?.ignoreEANLengthError) && isSuperuser && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state?.ignoreEANLengthError}
                        onChange={(event, checked) => setState({...state, ignoreEANLengthError: checked})}
                        name="ignoreEANLengthError"
                        color="primary"
                        style={{ paddingLeft: 0 }}
                      />
                    }
                    labelPlacement="end"
                    label="Ignore EAN length error"
                  />
                )}
                {state?.EAN2 && state.EAN2.trim() !== '' && (
                <Grid item={true} xs={mdUp ? 6 : 12}>
                    <InputTextField
                      style={{ marginTop: 7, marginLeft: 5 }}
                      label="EAN med justerade vikt/pris-siffror och/eller justerad kontrollsiffra"
                      inputName="EAN2"
                      // onChange={handleEANChange}
                      // setState={setState}
                      // approve={isFood && isSuperuser && approve}
                      // state={state}
                      // required
                      // forceRed={!!eanError || !!eanError2}
                      value={state.EAN2}
                      disabled={true}
                      withLabel
                      shrink={true}
                      labelOptions={{
                        heading: ".",
                      }}
                    />
                  </Grid>
                  )}
              </Grid>
              {((eanLengthError && !state.ignoreEANLengthError) || eanControlError || eanWrongCoopStoreError || icaStoreProcucerEanError || nonUniqError) && (
                <span style={{ fontWeight: 'bold', color: 'orangered' }}>
                  {eanLengthError || eanControlError || eanWrongCoopStoreError || icaStoreProcucerEanError || nonUniqError}
                </span>
              )}
              {!!eanControlError && (
                <TagLabel
                  space
                  type="info"
                  icon={<InfoOutlinedIcon />}
                  onClick={() => {
                    setState(oldState => ({
                      ...oldState,
                      EAN: `${state?.EAN?.slice(0, 12)}${getControlDigit(state?.EAN || '')}`,
                    }))
                  }}
                >
                  <span style={{ marginRight: 2 }}>Rätt EAN-kod bör vara:</span>
                  <strong style={{ marginRight: 2 }}>
                    {`${state?.EAN?.slice(0, 12)}${getControlDigit(state?.EAN || '')}`}.
                  </strong>
                  <span>Klicka här för att uppdatera</span>
                </TagLabel>
              )}
              
              <PurchasingData state={state} setState={setState} />

              {isEgg(state?.foodlaCategory, rootCategory) && (
                <>
                  <InputLabel
                    heading="Godkännandenummer för äggpackeri"
                    text="Godkännande nummer för äggpackeri måste finnas på förpackningen."
                    link="https://www.livsmedelsverket.se/livsmedel-och-innehall/mat-och-dryck/kott-kyckling-och-agg/hur-ska-agg-markas"
                    required
                  />
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <InputTextField
                      state={state}
                      setState={setState}
                      inputName="eggPackerApprovalNumber"
                      value={state.eggPackerApprovalNumber}
                      placeholder="Ex. SE001"
                      onChange={handleChange}
                    />
                    {isSuperuser &&
                      approve &&
                      state?.approve?.find(x => x.field === 'eggPackerApprovalNumber') && (
                        <ThreeWaySwitch
                          state={state}
                          style={{ flex: 0.1, height: 56, marginBottom: 4 }}
                          setState={setState}
                          name="eggPackerApprovalNumber"
                        />
                      )}
                  </div>
                </>
              )}

              <ShortText
                state={state}
                setState={setState}
                approve={isSuperuser && approve}
                sortedImages={sortedImages}
                coopExportProduct={coopExportProduct}
                rootCategory={rootCategory}
              />

              {/* {!isUserStore && (
                <ShortSalesDescription state={state} setState={setState} disableSubmitButton={setIsSubmitButtonDisabled}/>
              )} */}

              { isFood &&
                <Ingredients
                  state={state}
                  setState={setState}
                  approve={isFood && approve && isSuperuser}
                  userRole={userRole}
                  rootCategory={rootCategory}
                />
              }

              { isFish(state?.foodlaCategory, rootCategory, state) &&
                <FishFields approve={approve && isSuperuser} state={state} setState={setState}/>
              }

              { isFeedCategory(state?.foodlaCategory, rootCategory) &&
                <FeedFields
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                  approve={approve && isSuperuser}
                />
              }

              { isFeedCategory(state?.foodlaCategory, rootCategory) &&
                <AnimalFields
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                  approve={approve && isSuperuser}
                />
              }

              {(isToy(state?.foodlaCategory, rootCategory) ||
                isElectric(state?.foodlaCategory, rootCategory) ||
                isJewelry(state?.foodlaCategory, rootCategory) ||
                isGoods(state?.foodlaCategory, rootCategory) ||
                isMedical(state?.foodlaCategory, rootCategory) ||
                isChemical(state?.foodlaCategory, rootCategory) ||
                isDetergent(state?.foodlaCategory, rootCategory) ||
                isCosmetic(state?.foodlaCategory, rootCategory) ||
                isSunscreen(state?.foodlaCategory, rootCategory) ||
                isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
                isOTC(state?.foodlaCategory, rootCategory)) &&
                <NonFoodIngredients
                  approve={isSuperuser && approve}
                  userRole={userRole}
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                />
              }

              <Labels
                state={state}
                userRole={userRole}
                setState={setState}
                approve={isSuperuser && approve}
              />

              { isFood &&
                <Nutrition
                  state={state}
                  setState={setState}
                  approve={isFood && isSuperuser && approve}
                />
              }
            {/*
              { isNonFood &&
                <GrossWeight
                  state={state}
                  setState={setState}
                  name="gross_weight"
                />
              } */}

              { isFood &&
                <Vitamins
                  state={state}
                  setState={setState}
                  expand={expandVitamins}
                  approve={isFood && isSuperuser && approve}
                />
              }

              { 
                (isGoods(state?.foodlaCategory, rootCategory) ||
                isJewelry(state?.foodlaCategory, rootCategory) ||
                isToy(state?.foodlaCategory, rootCategory) ||
                isElectric(state?.foodlaCategory, rootCategory)) &&
                <GenericField
                  state={state}
                  setState={setState}
                  label='Färg'
                  field='color'
                  required={false}
                />
              }

              { 
                (isGoods(state?.foodlaCategory, rootCategory) ||
                isJewelry(state?.foodlaCategory, rootCategory) ||
                isToy(state?.foodlaCategory, rootCategory) ||
                isElectric(state?.foodlaCategory, rootCategory) ||
                isMedical(state?.foodlaCategory, rootCategory)) &&
                (
                  <>
                    <GenericField
                      state={state}
                      setState={setState}
                      label='Material'
                      field='material'
                      required={false}
                    />
                    <Dimensions
                      state={state}
                      setState={setState}
                    />
                    <GenericField
                      state={state}
                      setState={setState}
                      label='Volym'
                      field='capacity'
                      required={false}
                    />
                  </>
                )
              
              }

              <Descriptive
                state={state}
                setState={setState}
                userRole={userRole}
                approve={approve && isSuperuser}
                isCreatingNew={isCreatingNew}
                isFood={isFood}
                isCoopStoreProduct={isCoopStoreProduct}
              />

              {!isCoopStoreProduct && (
                <GrossWeight
                  approve={isSuperuser && approve}
                  state={state}
                  setState={setState}
                  showLabel={true}
                />
              )}

              {!isCoopStoreProduct && (
                <GrossDimensions
                  approve={isSuperuser && approve}
                  state={state}
                  setState={setState}
                />
              )}

              {/* { isNonFood &&
                <PackageSize
                  state={state}
                  setState={setState}
                />
              } */}

              <Temperature
                state={state}
                setState={setState}
                approve={isSuperuser && approve}
              />


              {(isFish(state?.foodlaCategory, rootCategory, state) ||
                isSpecialFoodNutrition(state?.foodlaCategory, rootCategory) ||
                isBeverages(state?.foodlaCategory, rootCategory) ||
                isEgg(state?.foodlaCategory, rootCategory) ||
                isFruktOrGreen(state?.foodlaCategory, rootCategory) ||
                isOtherFoodstuffs(state?.foodlaCategory, rootCategory) ||
                isMeat(state?.foodlaCategory, rootCategory) ||
                isBakeryProducts(state?.foodlaCategory, rootCategory) ||
                isMilk(state?.foodlaCategory, rootCategory) ||
                isCereals(state?.foodlaCategory, rootCategory) ||
                isConfectionery(state?.foodlaCategory, rootCategory) ||
                isConsumptionReadySnacks(state?.foodlaCategory, rootCategory) ||
                isSweet(state?.foodlaCategory, rootCategory) ||
                isSpicesSoupsSalads(state?.foodlaCategory, rootCategory) ||
                isFats(state?.foodlaCategory, rootCategory) ||
                isIceCream(state?.foodlaCategory, rootCategory)) &&
              (
                <>
                  <InputLabel
                    heading="Tillagningsanvisning"
                    text="För vissa produkter är en tillagningsanvisning särskilt viktig av säkerhetsskäl, till exempel för kyckling."
                  />
                  <InputTextField
                    state={state}
                    setState={setState}
                    inputName="preparationInstructions"
                    value={state.preparationInstructions}
                    placeholder="Ex. Produkten måste värmas upp grundligt före konsumtion"
                    onChange={handleChange}
                  />
                </>
              )}

              {(isPharma(state?.foodlaCategory, rootCategory) ||
                isDetergent(state?.foodlaCategory, rootCategory) ||
                isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
                isSpecialFoodNutrition(state?.foodlaCategory, rootCategory) ||
                isFoodSupplements(state?.foodlaCategory, rootCategory)) &&(
                <>
                  <InputLabel
                    heading="Beredningsform"
                    text="Beredningsform beskriver den formen produkten tar för att tas eller ges till en person."
                    required={isMedical(state?.foodlaCategory, rootCategory) || isOTC(state?.foodlaCategory, rootCategory)}
                  />
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <ListDropDown
                      disableLabel={true}
                      listData={preparedForm}
                      value={state.preparedForm}
                      targetName="preparedForm"
                      handleChange={handleDropdownChange}
                      displayEmpty={true}
                      style={{ flexGrow: 1, overflow: 'hidden' }}
                    />
                    {approve && state?.approve?.find(x => x.field === 'preparedForm') && (
                      <ThreeWaySwitch
                        state={state}
                        style={{ height: 56, marginBottom: 10 }}
                        setState={setState}
                        name="preparedForm"
                      />
                    )}
                  </div>
                </>
              )}

              {(isNonFood ||
                isSpecialFoodNutrition(state?.foodlaCategory, rootCategory) ||
                isFoodSupplements(state?.foodlaCategory, rootCategory)) && (
                <>
                  <InputLabel
                    heading="Användningsinstruktioner och dosering"
                    text="Ex. Blanda pulvret med 200 ml vatten och värm därefter till ät-temperatur (max 60 °C). Använd inte produkten som din enda näringskälla längre än 8 veckor eller upprepade gånger under kortare perioder, utan medicinsk rådgivning."
                    required={(isMedical(state?.foodlaCategory, rootCategory)
                      || isChemical(state?.foodlaCategory, rootCategory)
                      || isDetergent(state?.foodlaCategory, rootCategory)
                      || isCosmetic(state?.foodlaCategory, rootCategory)
                      || isSunscreen(state?.foodlaCategory, rootCategory)
                      || isFeedMaterial(state?.foodlaCategory, rootCategory)
                      || isFeedMixtures(state?.foodlaCategory, rootCategory)
                      || isCompleteFeed(state?.foodlaCategory, rootCategory)
                      || isOTC(state?.foodlaCategory, rootCategory)
                      || isFoodSupplements(state?.foodlaCategory, rootCategory)
                    )}
                  />
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <InputTextField
                      state={state}
                      setState={setState}
                      multiline
                      multilineRows={1}
                      multilineMax={20}
                      inputName="dosageAndUsageInstructions"
                      value={state.dosageAndUsageInstructions}
                      placeholder="Användningsinstruktioner och dosering, inklusive eventuella försiktighetsåtgärder"
                      onChange={handleChange}
                    />
                    {approve && state?.approve?.find(x => x.field === 'dosageAndUsageInstructions') && (
                      <ThreeWaySwitch
                        state={state}
                        style={{ height: 56, marginBottom: 2 }}
                        setState={setState}
                        name="dosageAndUsageInstructions"
                      />
                    )}
                  </div>
                </>
              )}

              {isNonFood && !isFeedCategory(state?.foodlaCategory, rootCategory) &&
                <HazardFields
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                  approve={approve && isSuperuser}
                />
              }

              {/* { isNonFood &&
                <GenericField
                  state={state}
                  setState={setState}
                  label='Tillverkningsland'
                  field='country_of_origin'
                />
              } */}

              {(isFood || isFeedCategory(state?.foodlaCategory, rootCategory)) &&
                <InputTextField
                  style={{ marginTop: 7 }}
                  label="Ursprungsland och härkomstplats"
                  multiline
                  inputName="place_of_item_activity"
                  onChange={handleChangePlaceOfActivity}
                  setState={setState}
                  approve={isSuperuser && approve && !!state?.approve?.find(x => x.field === 'place_of_item_activity')}
                  state={state}
                  multilineRows={1}
                  multilineMax={20}
                  value={state.place_of_item_activity}
                  variant="outlined"
                  withLabel
                  labelOptions={{
                    heading: 'Ursprungsland och härkomstplats',
                    text:
                      'Notera att om den primära ingrediensen (utgör mer än 50% av livsmedlet) har annat ursprung än livsmedlet som helhet så krävs även uppgift om dess ursprung här.',
                    link: 'http://kontrollwiki.livsmedelsverket.se/artikel/47/ursprungsmarkning',
                  }}
                />
              }

              {(isNonFood ||
                isFish(state?.foodlaCategory, rootCategory, state) ||
                isSpecialFoodNutrition(state?.foodlaCategory, rootCategory) ||
                isBeverages(state?.foodlaCategory, rootCategory) ||
                isFoodSupplements(state?.foodlaCategory, rootCategory) ||
                isFruktOrGreen(state?.foodlaCategory, rootCategory) ||
                isOtherFoodstuffs(state?.foodlaCategory, rootCategory) ||
                isMeat(state?.foodlaCategory, rootCategory) ||
                isBakeryProducts(state?.foodlaCategory, rootCategory) ||
                isMilk(state?.foodlaCategory, rootCategory) ||
                isCereals(state?.foodlaCategory, rootCategory) ||
                isConfectionery(state?.foodlaCategory, rootCategory) ||
                isConsumptionReadySnacks(state?.foodlaCategory, rootCategory) ||
                isSweet(state?.foodlaCategory, rootCategory) ||
                isFats(state?.foodlaCategory, rootCategory) ||
                isSpicesSoupsSalads(state?.foodlaCategory, rootCategory) ||
                isIceCream(state?.foodlaCategory, rootCategory)) &&
                <CountryOfManufacturing
                  approve={isSuperuser && approve}
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                />
              }

              {(isNonFood) && (
                <>
                  <InputLabel
                    heading="Ytterligare egenskaper"
                    text="Ytterligare egenskaper som konsumenten kan använda för att söka efter produkter online listas här. Du kan välja flera alternativ."
                  />
                  <MultipleSelect
                    disableLabel={true}
                    listData={additionalCharacteristics}
                    value={state?.additionalCharacteristics ?? []}
                    targetName="additionalCharacteristics"
                    handleChange={handleDropdownChange}
                  />
                </>
              )}

              {/* TODO:
              { isFood &&
                <InputTextField
                  style={{ marginTop: 7 }}
                  label="Ytterligare obligatoriska uppgifter för vissa typer eller kategorier av livsmedel"
                  multiline
                  inputName="additional_required_information"
                  onChange={handleChange}
                  setState={setState}
                  approve={isFood && isSuperuser && approve}
                  state={state}
                  multilineRows={2}
                  multilineMax={20}
                  value={state.additional_required_information}
                  variant="outlined"
                  withLabel
                  labelOptions={{
                    heading: 'Ytterligare obligatoriska uppgifter för vissa typer eller kategorier av livsmedel',
                    text: 'Ange här om det finns mer nödvändig information som måste anges gällande denna produkt.',
                  }}
                />
              } */}

              {/* {state.unit && state.unit.length > 0 && <Typography>{state.unit}</Typography>} */}

              {/* <StockToggleButton quantity_in_stock={state.quantity_in_stock} handleCheck={handleCheck} /> */}

              <Brand
                approve={isSuperuser && approve}
                state={state}
                setState={setState}
                isFood={isFood}  
                onChangedBrand={() => setChangedBrand(true)}
              />
              
              <SearchTerms
                approve={isSuperuser && approve}
                state={state}
                userRole={userRole}
                setState={setState}
              />
            </Card>

            <Card className={classes.card}>
              {isNotStore && (
                <LogisticQuestionsWrapper state={state}>
                  <OrderableUnitFields
                    state={state}
                    setState={setState}
                    isDespatchUnitsChanged={isDespatchUnitsChanged}
                  />

                  <DespatchUnitFields
                    state={state}
                    setState={setState}
                    setChanged={setIsDespatchUnitsChanged}
                  />

                  <EANForStorePack
                    uniqResult={!!uniqResult}
                    state={state}
                    setState={setState}
                  />
              
                  <QtyOfBaseItemFields
                    state={state}
                    setState={setState}
                  />

                  <PurchaseRelatedFields
                    state={state}
                    setState={setState}
                  />

                </LogisticQuestionsWrapper>
              )}

              {isNotStore && (
                <Address
                  isCreatingNew={isCreatingNew}
                  state={state}
                  setState={setState}
                  product={product}
                />
              )}

              <BasicData
                state={state}
                setState={setState}
                isFood={isFood}
                rootCategory={rootCategory}
              />

              {/* {isSuperuser && (
                <Details state={state} setState={setState} />
              )} */}
              {/*{isSuperuser && (
                <ConsumerPack state={state} setState={setState} />
              )}

              {isSuperuser && (
                <StorePack state={state} setState={setState} />
              )}

              {isSuperuser && (
                <Transport state={state} setState={setState} />
              )}

              {isSuperuser && (
                <PalletLayer state={state} setState={setState} />
              )}

              {isSuperuser && (
                <Pallet state={state} setState={setState} />
              )}

              {isSuperuser && (
                <Price state={state} setState={setState} />  
              )} */}

              {isSpecialFoodNutrition(state?.foodlaCategory, rootCategory) && (
                <>
                  <InputLabel
                    heading="Ange kategori om produkten är för viktminskning"
                    text="Livsmedel för viktkontroll har en speciell sammansättning för användning i energibegränsade dieter och produceras för att ersätta måltider under dagen."
                    link="https://kontrollwiki.livsmedelsverket.se/artikel/77/komplett-kostersattning-for-viktkontroll"
                  />
                  <ListDropDown
                    disableLabel={true}
                    listData={categoryWeightloss}
                    value={state?.categoryWeightloss}
                    targetName="categoryWeightloss"
                    handleChange={handleDropdownChange}
                    displayEmpty={true}
                  />
                </>
              )}

              {isFeedCategory(state?.foodlaCategory, rootCategory) &&
                <FeedApprovalFields
                  state={state}
                  setState={setState}
                  userRole={userRole}
                  approve={approve && isSuperuser}
                />
              }

              <AdditionalGroupFields state={state} setState={setState} rootCategory={rootCategory}/>

              {(isCosmetic(state?.foodlaCategory, rootCategory) ||
                isSunscreen(state?.foodlaCategory, rootCategory)) &&
                <SunScreenFields state={state} setState={setState} rootCategory={rootCategory} />
              }

              {(
                isPharma(state?.foodlaCategory, rootCategory)
                || isCmrHazardCategory(state?.foodlaCategory, rootCategory)
                || (isSuperuser && !isFood)
              ) && isNotStore &&
                <CmrFields state={state} setState={setState} />
              }

              { (
                isToy(state?.foodlaCategory, rootCategory)
                || isElectric(state?.foodlaCategory, rootCategory)
                || isJewelry(state?.foodlaCategory, rootCategory)
                || isGoods(state?.foodlaCategory, rootCategory)
                || isMedical(state?.foodlaCategory, rootCategory)
                || isChemical(state?.foodlaCategory, rootCategory)
                || isDetergent(state?.foodlaCategory, rootCategory)
                || isCosmetic(state?.foodlaCategory, rootCategory)
                || isSunscreen(state?.foodlaCategory, rootCategory)
                || (isSuperuser && !isFood)
              ) && isNotStore && (
                <ReachSubstance
                  state={state}
                  setState={setState}
                  rootCategory={rootCategory}
                />
              )}

              {(isToy(state?.foodlaCategory, rootCategory) ||
                isChemical(state?.foodlaCategory, rootCategory) ||
                isDetergent(state?.foodlaCategory, rootCategory)) &&
                <ReachSubstancesFields state={state} setState={setState}/>
              }

              {state?.reachSubstancesAnnex19072006 && (isToy(state?.foodlaCategory, rootCategory) ||
                isChemical(state?.foodlaCategory, rootCategory) ||
                isDetergent(state?.foodlaCategory, rootCategory)) && (
                <>
                  <InputLabel
                    heading="Ange ämne(n)"
                    text="Kom ihåg att ladda upp ett tillstånd för användning i din Foodla profil. Vänligen märk ditt dokument på lämpligt sätt, t. ex. “Tillstånd för använding av kromtrioxid.”"
                    link={producerPage}
                    required
                  />
                  <InputTextField
                    state={state}
                    setState={setState}
                    inputName="regulatedSubstance"
                    value={state.regulatedSubstance}
                    placeholder="Ex. Kromtrioxid. Listad separerad med komma"
                    onChange={handleChange}
                  />

                  <span className={classes.note}>
                    **För varje listat ämne måste du ladda upp ett tillstånd för användning under certifieringar i <a href={producerPage} target="_blank" rel="noopener noreferrer" className={classes.noteLink}>din Foodla-profil</a>.
                  </span>
                </>
              )}

              {(isGoods(state?.foodlaCategory, rootCategory) ||
                isJewelry(state?.foodlaCategory, rootCategory) ||
                isToy(state?.foodlaCategory, rootCategory) ||
                isElectric(state?.foodlaCategory, rootCategory)) && 
                <HazardousSubstancesFields state={state} setState={setState} />
              }

              {isMedical(state?.foodlaCategory, rootCategory) &&
                <MedicalTechnologyFields state={state} setState={setState} />
              }

              <QualityReviewFields
                state={state}
                setState={setState}
                rootCategory={rootCategory}
                userRole={userRole}
              />

              {isSuperuser && (
                <ProducerInfo state={state} setState={setState} />
              )}

              {isSuperuser && (
                <BulkImportFile product={product} />
              )}

              {!isLocal && !isDev && !isCreatingNew && (
                <Chat productId={state.id} />
              )}

              {isSuperuser && (
                <CoopExportJson
                  state={state}
                  producer={product?.producer}
                  images={sortedImages}
                  setCoopExportProduct={setCoopExportProduct}
                />
              )}
              {/* {isSuperuser && (
                <CoopProductJson state={state} isSuperuser={isSuperuser} images={sortedImages} />
              )} */}

              {/* {isSuperuser && (
                <ApoteaProductJson
                  state={state}
                  isSuperuser={isSuperuser}
                  images={sortedImages}
                  rootCategory={rootCategory}
                />
              )} */}
              {isSuperuser && (
                <ApoteaXml
                  state={state}
                  images={sortedImages}
                />
              )}
              {isSuperuser && (
                <IcaProductJson
                  state={state}
                  images={sortedImages}
                />
              )}
              {isSuperuser && !isCreatingNew && (
                <Approvements
                  productId={state.id}
                />
              )}

              {!product?.wasApproved && product?.adminStatus !== SUPERUSER_APPROVAL.ADMIN_APPROVED && (
                <FormControlLabel
                  label={(
                    <InputLabel
                      heading="Spara som utkast"
                      text="'Spara som utkast' låter dig spara din produkt och komma tillbaka för att slutföra och publicera den senare, så att inga detaljer går förlorade innan vår granskning."
                      labelStyles={{ marginTop: '4px' }}
                    />
                  )}
                  control={(
                    <Switch
                      id={`ProductForm-SaveAsDraft-Switch-${state.isDraft ? 'Enable' : 'Disable'}`}
                      color="primary"
                      checked={!!state.isDraft}
                      onChange={(event) => setState({ ...state, isDraft: event.target.checked })}
                    />
                  )}
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                />
              )}

              <Review
                state={state}
                setState={setState}
                userRole={userRole}
                isAiChangedState={isAiChangedState}
                isCreatingNew={isCreatingNew}
                product={product}
              />

              <SubmitButton
                state={state}
                isFood={isFood}
                userRole={userRole}
                isCreatingNew={isCreatingNew}
                approved={approved}
                disabled={isSubmitButtonDisabled}
                showEANError={(!state.ignoreEANLengthError && eanLengthError) || eanWrongCoopStoreError || icaStoreProcucerEanError}
                showNonUniqEANError={nonUniqError}
                showCategoryError={showCategoryError}
                isNoHasUnpaidInvoices={(
                  !unpaidInvoiceData.loading &&
                  !unpaidInvoiceData.error &&
                  !unpaidInvoiceData.list.length
                )}
              />
              {markedForRemoval && !showMarkedForRemovalComponent && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                  <span style={{ fontWeight: 'bold', fontSize: 16, color: COLORS.mainRed }}>
                    Producer has requested this product to be removed
                  </span>
                </div>
              )}
              {isSuperuser && false && (
                <Button
                  onClick={onDelete}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.deleteButton}
                >
                  <span className={confirmDelete ? classes.confirmDeleteText : classes.deleteText}>
                    {confirmDelete ? 'Click again if you are sure!' : 'Delete product'}
                  </span>
                </Button>
              )}
            </Card>
          </form>
        </>
      )}
      <AIReviewer state={state} userRole={userRole} setState={setState} />
    </>
  );
};

export default ProductForm;
