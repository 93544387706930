import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import axios from 'axios';
import { path } from "ramda";

import IProduct, { IAiReview } from 'models/product';
import useLayoutContext from 'hooks/useLayoutContext';

import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SmartToyIcon from '@material-ui/icons/Toys'; // Using Toys as a substitute for SmartToy

import { KeycloakContext } from 'components/Secured';
import { restEndpoints } from 'constants/domain';
import { APPLICATION_ROLES } from '../../constants';

const DRAWER_WIDTH = 350;

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 1300,
    color: theme.palette.common.white,
    width: 60,
    height: 60,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  sidebar: {
    position: 'fixed',
    right: 0,
    top: 0, // Will be adjusted dynamically
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    boxShadow: theme.shadows[4],
    zIndex: 1000,
    transform: 'translateX(100%)',
    transition: 'transform 0.3s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarOpen: {
    transform: 'translateX(0)',
  },
  sidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  refreshButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  refreshIcon: {
    marginRight: theme.spacing(1),
  },
  aiContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    overflowY: 'auto',
    flex: 1,
    paddingBottom: theme.spacing(12), // Add extra padding at the bottom to prevent FAB overlap
  },
  section: {
    marginBottom: theme.spacing(1.5),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  highlight: {
    backgroundColor: '#fffde7',
    padding: theme.spacing(1),
    borderLeft: `4px solid ${theme.palette.warning.main}`,
    marginBottom: theme.spacing(1.5),
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease',
  },
  overlayVisible: {
    opacity: 1,
    visibility: 'visible',
  },
}));

interface AIReviewerProps {
  state: IProduct;
  userRole: string;
  setState: React.Dispatch<React.SetStateAction<IProduct>>;
}

const AIReviewer: React.FC<AIReviewerProps> = ({ state, userRole, setState }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx) as string;
  const classes = useStyles();
  const theme = useTheme();
  const { setRightbarWidth } = useLayoutContext();

  const keepRef = useRef({ state });
  keepRef.current.state = state;

  const [open, setOpen] = useState(false);
  const [appBarHeight, setAppBarHeight] = useState(64); // Default height
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [aiReviewData, setAiReviewData] = useState(state.aiReview);

  const isSuperuser = userRole === 'SUPERUSER' || userRole === APPLICATION_ROLES.SUPERUSER;
  const isProductionMode = process.env.REACT_APP_AUTH_ENV === 'prod';

  // Media query for responsive behavior
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // 'md' is typically 960px

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoadAIAnalysis = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const formData = new FormData();
      formData.append('product', JSON.stringify(keepRef.current.state));
    
      const { data } = await axios.post<{ data: IAiReview }>(
        restEndpoints.aiProductReview,
        formData,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAiReviewData(data?.data || null);
      setState({ ...keepRef.current.state, aiReview: data?.data });
    } catch (error: any) {
      console.error('Product review error:', error);
      setIsError(true);
    } finally {
      setIsRefreshing(false);
    }
  }, [token, setState]);

  // Find the app bar height and adjust the sidebar position
  useEffect(() => {
    const appBar = document.querySelector('header') || document.querySelector('.MuiAppBar-root');
    if (appBar) {
      setAppBarHeight(appBar.clientHeight);
    }
  }, []);

  useEffect(() => {
    // Handle loading state when first opening sidebar
    if (open && !isCalled && !keepRef.current.state?.aiReview) {
      setIsLoading(true);
      setIsCalled(true);
      handleLoadAIAnalysis().then(() => {
        setIsLoading(false);
      });
    }
  }, [open, isCalled, handleLoadAIAnalysis]);

  // Apply the shift effect based on desktop vs mobile
  useEffect(() => {
    if (open && isDesktop) setRightbarWidth(DRAWER_WIDTH);

    return () => {
      // Cleanup when component unmounts
      setRightbarWidth(0);
    };
  }, [open, isDesktop, setRightbarWidth]);

  if (isProductionMode && !isSuperuser) {
    return null;
  }

  return (
    <>
      {/* Dark overlay for mobile view */}
      {!isDesktop && (
        <div className={`${classes.overlay} ${open ? classes.overlayVisible : ''}`} onClick={handleClose} />
      )}

      <Fab className={classes.fab} color="primary" aria-label="AI Reviewer" onClick={() => setOpen(!open)}>
        {open ? <CloseIcon /> : <SmartToyIcon />}
      </Fab>

      <Box
        className={`${classes.sidebar} ${open ? classes.sidebarOpen : ''}`}
        style={{ top: `${appBarHeight}px`, height: `calc(100% - ${appBarHeight}px)` }}
      >
        <div className={classes.sidebarHeader}>
          <Typography variant="h6">Produktgransking</Typography>
          <IconButton className={classes.closeButton} onClick={handleClose} edge="end">
            <CloseIcon />
          </IconButton>
        </div>

        <Divider />

        <div className={classes.aiContent} style={{ display: isLoading ? 'none' : undefined }}>
          <div className={classes.highlight}>
            <Typography variant="subtitle1">Produkt: {state?.title || 'No title provided'}</Typography>
            <Typography variant="subtitle1">
              Kategori: {state?.foodlaCategory?.name || 'Ingen kategori satt'}
            </Typography>
          </div>

          {!!aiReviewData && (
            <>
              {aiReviewData.aiReviewIdentifiedIssues?.length && (
                <div className={classes.section}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Identifierade brister
                  </Typography>
                  <ul>
                    {aiReviewData.aiReviewIdentifiedIssues.map((rec, index) => (
                      <li key={index}>
                        <Typography>{rec}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {aiReviewData.aiReviewRecommendations?.length && (
                <div className={classes.section}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Rekommenderade åtgärder
                  </Typography>
                  <ul>
                    {aiReviewData.aiReviewRecommendations.map((rec, index) => (
                      <li key={index}>
                        <Typography>{rec}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}

          {!aiReviewData && (
            <Box flexGrow={1} px={1} py={3} color={isError ? '#FF0000' : undefined}>
              <Box display="flex" alignItems="center" textAlign="center" height="100%">
                {isError
                  ? 'Det gick inte att hämta AI-analysen. Vänligen försök igen genom att klicka på “Uppdatera AI-analysen.'
                  : 'Ingen AI-analys tillgänglig för denna produkt. Klicka på uppdatera för att generera en analys.'}
              </Box>
            </Box>
          )}

          <div className={classes.refreshButton}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleLoadAIAnalysis}
              disabled={isRefreshing}
              startIcon={
                isRefreshing ? <CircularProgress size={20} /> : <RefreshIcon className={classes.refreshIcon} />
              }
            >
              {isRefreshing ? 'Granskar...' : 'Granska produkten'}
            </Button>
          </div>
        </div>

        {isLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AIReviewer;
