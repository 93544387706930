import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './useStyles';
import IProduct from 'models/product';
import { APPLICATION_ROLES, SUPERUSER_APPROVAL } from 'components/constants-ts';
import { CheckEnergyError, isEnergyError } from './fields/nutrition';
import TagLabel from 'components/TagLabel';
import CheckRequireError, { checkRequireError } from './CheckRequireError';
import { isBlank } from 'utils/helpers-ts';

export const checkEanErrorForIcaStoreProducer = (state?: IProduct) => {
  const isProducerInICAGroup = state?.producerIsStore && state?.producerParentOrg === 'ica';

  if (isProducerInICAGroup && !isBlank(state?.EAN)) {
    if (
      !(
        state?.EAN?.trim().startsWith('2000') ||
        state?.EAN?.trim().startsWith('2095') ||
        state?.EAN?.trim().startsWith('2096') ||
        state?.EAN?.trim().startsWith('2097')
      )
    ) {
      return 'Det verkar som ni har angett en EAN-kod som inte tillhör det range som ICA Online har godkänt för butiksproducerade artiklar online. För att vi ska kunna släppa igenom produkten till ica.se, vänligen vårda om produkten för försäljning på en EAN-kod som inleds med följande siffror och ange samma EAN-kod på produkten i Foodla: “2000…”, “2095…”, “2096…”, “2097...”. Om artikeln kommer från en extern leverantör så hjälper vi till att få upp artikeln via leverantören. Gå till “My Products”-sidan i Foodla och klicka på “BJUD IN LEVERANTÖR” uppe i högra hörnet.'
    }
  }

  return '';
}

interface ISubmitButtonProps {
  isNoHasUnpaidInvoices?: boolean;
  state?: IProduct;
  userRole?: string; // TODO enum:
  isCreatingNew?: boolean;
  approved?: boolean;
  disabled?: boolean;
  showEANError?: string;
  showNonUniqEANError?: string;
  isFood?: boolean;
  showCategoryError?: boolean;
}

export const SubmitButton = ({
  isNoHasUnpaidInvoices,
  state,
  userRole,
  isCreatingNew,
  approved,
  disabled,
  showEANError,
  showNonUniqEANError,
  isFood,
  showCategoryError,
}: ISubmitButtonProps) => {
  const classes = useStyles();
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;
  const isProducer = userRole === APPLICATION_ROLES.PRODUCER || userRole === APPLICATION_ROLES.STORE;
  const isLogistician = userRole === APPLICATION_ROLES.LOGISTICIAN;
  
  const disable = state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED
  || (state?.adminStatus !== SUPERUSER_APPROVAL.ADMIN_APPROVED && (isProducer || isLogistician));
  
  const getSubmitButtonClass = (adminStatus?: string, userRole?: string, isCreatingNew?: boolean) => {
    if ((userRole === APPLICATION_ROLES.PRODUCER || userRole === APPLICATION_ROLES.STORE) || isCreatingNew) return classes.submitApprove;
    if (adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED) return classes.submitApprove;
    if (adminStatus === SUPERUSER_APPROVAL.ADMIN_DISAPPROVED) return classes.submitDisapprove;
    return classes.submitAwait;
  };

  const hasRequireError = checkRequireError(state);
  const hasEnergyError = isEnergyError(state);

  const getDisabled = () => {
    const result = disabled
    || (!state?.isDraft && hasRequireError)
    || hasEnergyError
    || (isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && state?.isDraft)
    || (isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && showCategoryError)
    || (disable && !!showEANError)
    || !!showNonUniqEANError 
    || (userRole !== APPLICATION_ROLES.PRODUCER && !isNoHasUnpaidInvoices)
    || ((
      state?.showRed
      || state?.showTempError 
      || (isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && !approved)
    ));
    return !!result;
  };

  let draftErrror = '';
  if (isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && state?.isDraft) {
    draftErrror = 'Draft products cannot be approved';
  }

  const button = (
    <>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        id={isCreatingNew ? 'create-product-button' : 'update-product-button'}
        disabled={getDisabled()}
        className={getSubmitButtonClass(state?.adminStatus, userRole, isCreatingNew)}
      >
        {isCreatingNew ? 'SKAPA PRODUKT' : 'UPPDATERA PRODUKT'}
      </Button>
      {(!state?.isDraft && hasRequireError) && <CheckRequireError state={state} />}
    </>
  );
  if (!isCreatingNew && showCategoryError && isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED) {
    const title = 'Fill category field or select granular category';
    return (
      <Tooltip title={title}>
        <div>
          {button}
          {!!draftErrror && <TagLabel space type='error'>*{draftErrror}</TagLabel>}
          <TagLabel space type='error'>*{title}</TagLabel>
          <CheckEnergyError state={state} />
        </div>
      </Tooltip>
    );
  } else if (
    (!!showEANError || !!showNonUniqEANError) ||
    (isFood && (state?.showRed || state?.showTempError)) ||
    state?.showDimensionsError
  ) {
    const title = `Fill required ${state?.showRed ? 'nutritional' : ''}${state?.showRed && state?.showTempError ? ' and ' : ''}${state?.showTempError ? 'temperature' : ''}${(state?.showRed || state?.showTempError) ? ' and ' : ''}${state?.showDimensionsError ? 'dimension' : ''} fields`;
    return (
      <Tooltip title={title}>
        <div>
          {button}
          {!!draftErrror && <TagLabel space type='error'>*{draftErrror}</TagLabel>}
          {state?.showRed && (
            <TagLabel space type='error'>*Fill required nutritional fields</TagLabel>
          )}
          {state?.showTempError && (
            <TagLabel space type='error'>*Fill required temperature fields</TagLabel>
          )}
          {state?.showDimensionsError && (
            <TagLabel space type='error'>*Fill required dimension fields</TagLabel>
          )}
          {showEANError && (
            <TagLabel space type='error'>*{showEANError}</TagLabel>
          )}
          {showNonUniqEANError && (
            <TagLabel space type='error'>*{showNonUniqEANError}</TagLabel>
          )}
          <CheckEnergyError state={state} />
        </div>
      </Tooltip>
    );
  } else if (isSuperuser && state?.adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED && !approved) {
    return (
      <Tooltip title="All boxes needs to be reviewed and marked with a green check-mark before the product can be marked as Approved">
        <div>
          {button}
          {!!draftErrror && <TagLabel space type='error'>*{draftErrror}</TagLabel>}
          <TagLabel space type='error'>All boxes needs to be reviewed and marked with a green check-mark before the product can be marked as Approved</TagLabel>
          <CheckEnergyError state={state} />
        </div>
      </Tooltip>
    );
  } else if (draftErrror) {
    return (
      <Tooltip title={draftErrror}>
        <div>
          {button}
          <TagLabel space type='error'>*{draftErrror}</TagLabel>
          <CheckEnergyError state={state} />
        </div>
      </Tooltip>
    );
  } else if (hasEnergyError) {
    return (
      <Tooltip title={'Det verkar som om att du angivit värden för kJ och kcal som inte stämmer överens. Stäm gärna av så att värdena stämmer (1 kcal = 4,184 kJ) eller hör av dig till support@foodla.nu så hjälper vi till!'}>
        <div>
          {button}
          <CheckEnergyError state={state} />
        </div>
      </Tooltip>
    );
  } else {
    return button;
  }
};

export default SubmitButton;
