import React from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';

import ProductUpdateForm from '../../components/product/ProductUpdateForm';

import { PRODUCT_INFO_FOR_UPDATE_QUERY } from '../../graphql/queries';
import { APPLICATION_ROLES } from '../../components/constants';
import { isDefined } from '../../utils/helpers';
import { transformCategoryRead } from 'utils/products';
import DraftProductAlert from 'components/product/ProductUpdateForm/DraftProductAlert';

const ApprovalForm = ({ match }) => {
  const { id } = match.params;
  return (
    <Query query={PRODUCT_INFO_FOR_UPDATE_QUERY} fetchPolicy='no-cache' variables={{ id }}>
      {({ data, loading, error, networkStatus, refetch }) => {
        console.log('!!!product', data?.product);
        return (
          <div>
            <DraftProductAlert product={data?.product} />

            <div>
              {loading && <CircularProgress size={70} variant="indeterminate" />}
              {error && (
                <div style={{ padding: 20, textAlign: 'center'}}>
                  <h3>No product found :(</h3>
                </div>
              )}
              {!loading && isDefined(data) && (
                <ProductUpdateForm
                  userRole={APPLICATION_ROLES.PRODUCER}
                  product={transformCategoryRead(data?.product)}
                  producerData={{}} 
                  refetchQuery={refetch}
                  refetchVariables={{ id }}
                  productLoading={loading}
                />
              )}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default ApprovalForm;
