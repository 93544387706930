import {
  isChemical,
  isComplementarySupplements,
  isCosmetic,
  isDetergent,
  isEgg,
  isElectric,
  isFeedCategory,
  isFish,
  isFoodSupplements,
  isGoods,
  isJewelry,
  isMedical,
  isNewFood,
  isNewNonFood,
  isOTC,
  isSpecialFoodNutrition,
  isSunscreen,
  isToy,
} from 'components/product/ProductUpdateForm/fields/category';
import { IReviewColumn } from '../type';
import { eanValidator, ingredientValidator } from '../utils/fieldValidator';

export const COLUMN_DETAILS: IReviewColumn[] = [
  {
    columnName: 'Produktnamn',
    field: 'title',
    type: 'text',
    required: true,
    minWidth: 200,
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'title'),
  },
  {
    columnName: 'EAN-kod (13 eller 8 siffror)',
    field: 'EAN',
    type: 'text',
    required: true,
    minWidth: 200,
    valueValidator: eanValidator,
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'EAN'),
  },
  {
    field: 'EAN2',
    type: 'text',
    readonly: true,
    minWidth: 200,
  },
  {
    columnName: 'Beskriv produkten med max 150 ord',
    field: 'short_text',
    type: 'text',
    required: true,
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'short_text'),
  },
  {
    columnName: 'Innehållsförteckning',
    field: 'non_food_ingredients',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => {
      return (
        isToy(product?.foodlaCategory, rootCategory) ||
        isElectric(product?.foodlaCategory, rootCategory) ||
        isJewelry(product?.foodlaCategory, rootCategory) ||
        isGoods(product?.foodlaCategory, rootCategory) ||
        isMedical(product?.foodlaCategory, rootCategory) ||
        isChemical(product?.foodlaCategory, rootCategory) ||
        isDetergent(product?.foodlaCategory, rootCategory) ||
        isCosmetic(product?.foodlaCategory, rootCategory) ||
        isSunscreen(product?.foodlaCategory, rootCategory) ||
        isComplementarySupplements(product?.foodlaCategory, rootCategory) ||
        isOTC(product?.foodlaCategory, rootCategory)
      );
    },
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'non_food_ingredients'),
  },
  {
    columnName: 'Fiskemetod',
    field: 'fishCatchMethod',
    conditionFunc: (product, rootCategory) => !!isFish(product?.foodlaCategory, rootCategory, product),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'fishCatchMethod'),
  },
  {
    columnName: 'Förvaringsanvisning',
    field: 'trade_item_temperature_information',
    type: 'text',
    required: true,
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'trade_item_temperature_information'),
  },
  {
    columnName: 'Användningsinstruktioner och dosering',
    field: 'dosageAndUsageInstructions',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => {
      const isNonFood = isNewNonFood(product?.foodlaCategory, rootCategory);
      return (
        isNonFood ||
        isSpecialFoodNutrition(product?.foodlaCategory, rootCategory) ||
        isFoodSupplements(product?.foodlaCategory, rootCategory)
      );
    },
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'dosageAndUsageInstructions'),
  },
  {
    columnName: 'Ursprungsland och härkomstplats',
    field: 'place_of_item_activity',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => {
      const isFood = isNewFood(product?.foodlaCategory, rootCategory);
      return isFood || isFeedCategory(product?.foodlaCategory, rootCategory);
    },
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'place_of_item_activity'),
  },
  {
    columnName: 'Varumärke',
    field: 'brand_food',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isNewFood(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'brand_food'),
  },
  {
    columnName: 'Varumärke',
    field: 'brand',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => !isNewFood(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'brand'),
  },
  {
    columnName: 'Fodersammansättning',
    field: 'animalFoodIngredients',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'animalFoodIngredients'),
  },
  {
    columnName: 'Utfodringsinstruktioner',
    field: 'feedingInstructions',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'feedingInstructions'),
  },
  {
    columnName: 'Godkännandenummer för äggpackeri',
    field: 'eggPackerApprovalNumber',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isEgg(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'eggPackerApprovalNumber'),
  },
  {
    columnName: 'Ålderskategori',
    field: 'animalAgeGroup',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'animalAgeGroup'),
  },
  {
    columnName: 'Godkännandenummer ansvarig för märkning',
    field: 'responsibleLabelingApprovalNumber',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'responsibleLabelingApprovalNumber'),
  },
  {
    columnName: 'Fodertillsatser',
    field: 'feedAdditives',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'feedAdditives'),
  },
  {
    columnName: 'Analytiska beståndsdelar',
    field: 'analyticalConstituentsFeed',
    type: 'text',
    required: true,
    conditionFunc: (product, rootCategory) => isFeedCategory(product?.foodlaCategory, rootCategory),
    checkCanApprove: product => !!product?.approve?.find(x => x.field === 'analyticalConstituentsFeed'),
  },
  {
    columnName: 'Vitamin A',
    field: 'vitaminA',
    rootCategories: 'food',
  },
  {
    columnName: 'Ingrediensförteckning',
    field: 'ingredient_statement',
    type: 'text',
    valueValidator: ingredientValidator,
  },
];
