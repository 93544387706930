import { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { KeycloakContext } from 'components/Secured';
import InputLabel from 'components/inputfields/InputLabel';
import InputTextField from 'components/inputfields/InputTextField';
import CopyEmailInviteProducer, { CopyEmailData } from './CopyEmailInviteProducer';
import { useTheme } from '@material-ui/core/styles';


const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

type FormDataType = {
  companyName: string;
  firstName: string;
  email: string;
  phone?: string;
};

const defaultFormData: FormDataType = {
  companyName: '',
  firstName: '',
  email: '',
};

const InviteProducer = () => {
  const keycloakCtx = useContext(KeycloakContext);

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [errors, setErrors] = useState<Partial<FormDataType>>({});
  // Success state is now handled in the CopyEmailInviteProducer component
  const [openCopyEmailModal, setOpenCopyEmailModal] = useState(false);
  const [copyEmailData, setCopyEmailData] = useState<CopyEmailData | undefined>();

  const mapEmailData = () => {
    const storeFirstName = keycloakCtx.keycloak?.tokenParsed?.given_name || '';

    const phoneText = formData.phone ? `\n\n Telefonnummer till kontakt: ${formData.phone}` : '';
    const subject = `Vi på ${storeFirstName} önskar sälja dina artiklar online med hjälp av Foodla!`;
    const body =
      `Hej ${formData.firstName},\n\n` +
      `Vi på ${storeFirstName} vill bjuda in er att gå med i Foodla och börja sälja era produkter online genom vår butik. När du som leverantör laddar upp produkterna i Foodla kan vi aktivera dessa i vår online-butik.\n\n` +
      `Foodla (som är med i kopian på detta mejl) kommer hjälpa er vidare med processen. På så sätt kan både vi och andra butiker runt om i Sverige erbjuda era produkter till våra e-handelskunder. Svara gärna på detta mail med en tid för att prata med Foodla kring detta.\n\n` +
      `Vi ser fram emot att kunna erbjuda era produkter snart!\n\n` +
      `Vänliga hälsningar,\n${storeFirstName}${phoneText}`;

    const to = formData.email;
    const cc = 'support@foodla.nu';

    return { to, cc, subject, body };
  };

  const handleCopyEmail = () => {
    // Get the email data and open the copy modal as the primary action
    const emailData = mapEmailData();
    setCopyEmailData(emailData);
    setOpenCopyEmailModal(true);
  };
  
  // Function to handle closing the copy modal
  const handleCloseCopyModal = () => {
    setOpenCopyEmailModal(false);
  };
  
  const handleOpenMailClient = () => {
    // Secondary action to try opening the email client
    const { to, cc, subject, body } = mapEmailData();
    
    // Create a mailto link with proper encoding
    const params = [
      `cc=${encodeURIComponent(cc)}`,
      `subject=${encodeURIComponent(subject)}`,
      `body=${encodeURIComponent(body)}`
    ];
    
    const mailHref = `mailto:${encodeURIComponent(to)}?${params.join('&')}`;
    
    try {
      // First, try to use a hidden anchor with target="_blank"
      const mailtoLink = document.createElement('a');
      mailtoLink.setAttribute('href', mailHref);
      mailtoLink.setAttribute('target', '_blank'); // Try to open in new tab
      mailtoLink.style.display = 'none';
      
      // Add to DOM, click it, and remove it
      document.body.appendChild(mailtoLink);
      mailtoLink.click();
      
      // Remove the link element
      setTimeout(() => {
        document.body.removeChild(mailtoLink);
      }, 100);
      
      // If clicking didn't work (user still on page), try window.open as fallback
      setTimeout(() => {
        // Only show success message if user is still here
        if (document.contains(mailtoLink)) {
          // If we're still here, try window.open as fallback
          try {
            window.open(mailHref, '_blank');
          } catch (e) {
            console.error('Fallback window.open failed:', e);
            // If both approaches failed, show alert
            alert('Det gick inte att öppna din e-postklient. Använd kopiera mall istället.');
          }
        }
      }, 300);
    } catch (error) {
      console.error('Failed to open email client:', error);
      
      // Last resort fallback - direct window.open
      try {
        window.open(mailHref, '_blank');
      } catch (e) {
        alert('Det gick inte att öppna din e-postklient. Använd kopiera mall istället.');
      }
    }
  };

  const checkErrors = useCallback(() => {
    const newErrors: Partial<FormDataType> = {};

    if (!formData.companyName) {
      newErrors.companyName = 'Vänligen, fyll i detta fält';
    }

    if (!formData.firstName) {
      newErrors.firstName = 'Vänligen, fyll i detta fält';
    }

    if (!formData.email) {
      newErrors.email = 'Vänligen, fyll i detta fält';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Vänligen, ange en giltig e-postadress';
    }
    return newErrors;
  }, [formData]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name || '';
    const newErrors = checkErrors();
    setErrors({ ...errors, [name]: (newErrors as any)[name] });
  };

  useEffect(() => {
    setErrors({});
    setFormData(defaultFormData);
    setCopyEmailData(undefined);
  }, [open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box flexShrink={0}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Bjud in leverantör
        </Button>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
        <DialogTitle>Bjud in leverantör till Foodla</DialogTitle>

        <DialogContent>
          <div>
            <p style={{ marginTop: 0 }}>
              Fyll i uppgifterna för den leverantör du vill bjuda in och skicka. Din mailtjänst öppnas med en färdig
              mall med Foodla på CC, som du kan justera.
            </p>
            <InputLabel heading="Leverantörsnamn" />
            <InputTextField<any>
              inputName="companyName"
              value={formData.companyName}
              state={formData}
              forceRed={!!errors.companyName}
              errorText={errors.companyName}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Förnamn till kontakt" />
            <InputTextField<any>
              inputName="firstName"
              value={formData.firstName}
              state={formData}
              forceRed={!!errors.firstName}
              errorText={errors.firstName}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Email till kontakt" />
            <InputTextField<any>
              inputName="email"
              value={formData.email}
              state={formData}
              forceRed={!!errors.email}
              errorText={errors.email}
              setState={setFormData}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputLabel heading="Telefon till kontakt (valfri)" />
            <InputTextField<any>
              inputName="phone"
              value={formData.phone}
              state={formData}
              setState={setFormData}
              onChange={handleChange}
            />
            <p style={{ marginBottom: 4 }}>
              När du klickar på "Kopiera e-postmall" kan du kopiera texten och klistra in i ditt e-postprogram.
            </p>
            
            <p style={{ marginTop: 16, marginBottom: 4 }}>
              Har du en e-postklient installerad? Klicka här för att öppna mailet direkt i klienten:
            </p>
            <Button
              disabled={!isEmpty(checkErrors())}
              size="small"
              variant="outlined"
              onClick={handleOpenMailClient}
              style={{ marginRight: '8px' }}
            >
              Öppna e-postklient
            </Button>
          </div>
        </DialogContent>

        <DialogActions>
          <Box display="flex" gridGap={16} px={2} py={1}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Stäng
            </Button>
            <Button disabled={!isEmpty(checkErrors())} variant="contained" color="primary" onClick={handleCopyEmail}>
              Kopiera e-postmall
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {!!copyEmailData && (
        <CopyEmailInviteProducer
          open={openCopyEmailModal}
          data={copyEmailData}
          onClose={handleCloseCopyModal}
        />
      )}
    </Box>
  );
};

export default InviteProducer;