import IProduct from 'models/product';
import { IReviewColumn } from '../type';
import { IFoodlaCategoryOption } from 'models/category';
import { getParentCategoriesData } from 'components/product/ProductUpdateForm/fields/category';

export const matchColumnCategories = (params: {
  product: IProduct;
  column: IReviewColumn;
  rootCategory?: IFoodlaCategoryOption;
}) => {
  const { product, column, rootCategory } = params;
  const foodlaCategory = product.foodlaCategory;

  let { rootCategories: roots, qualityGroupCategories: qualityGroups, parentCategories: parents } = column;

  // not require to match category
  if (!roots?.length && !qualityGroups?.length && !parents?.length) return true;
  if (!foodlaCategory || !rootCategory) return false;

  if (typeof roots === 'string') roots = [roots];
  if (typeof qualityGroups === 'string') qualityGroups = [qualityGroups];
  if (typeof parents === 'string') parents = [parents];

  const [root, qualityGroup, parent] = getParentCategoriesData(foodlaCategory, rootCategory);

  // if categories and rootCategories defined same time use both, so it will be category or rootCategory
  // Only need to match one of categories
  return (
    roots?.includes(root.id as 'food' | 'nonfood') ||
    qualityGroups?.includes(qualityGroup.name) ||
    parents?.includes(parent.name)
  );
};
