import { FC } from 'react';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TagLabel from 'components/TagLabel';
import dangerousOutlinedIconImg from 'assets/icons/DangerousOutlined.png';

export type TImageTranscriber = {
  recognizeData?: string;
  all_text_and_digits?: string;
  image_name?: string;
  confidence_score?: number;
  certainty_ceiling?: string;
  advisory_warnings: string[];
  deductions: {
    type: string;
    reason: string;
    severity: 'low' | 'med' | 'high';
    points_lost: number;
  }[];
};

interface ImageTranscriberProps {
  hideHeader?: boolean;
  data?: TImageTranscriber;
}

export const Accordion = styled(MuiAccordion)`
  margin: 16px 0 !important;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  &:before {
    display: none;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  font-weight: 600;
  min-height: auto !important;
  & .MuiAccordionSummary-content {
    margin: 16px 0;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)<{ borderless?: boolean; noSpace?: boolean }>`
  ${props => !props.borderless && 'border: 1px solid rgba(0, 0, 0, 0.23);'}
  border-radius: 4px;
  margin: 16px;
  margin-top: 0;
  padding: ${props => props.borderless ? '0' : '16px'};
`;

const getConfidenceScore = (data?: TImageTranscriber) => {
  const confidenceScore = data?.confidence_score;
  if (isNil(confidenceScore)) return null;
  if (typeof confidenceScore === 'number') return confidenceScore;
  return Number(confidenceScore);
};

const ImageTranscriber: FC<ImageTranscriberProps> = ({ hideHeader, data }) => {
  const confidenceScore = getConfidenceScore(data);

  if (!data?.all_text_and_digits) return null;

  return (
    <Box>
      {!!data.image_name && !hideHeader && <Box fontWeight={700}>{data.image_name}</Box>}

      {!isNil(confidenceScore) && (
        <Box mt={1}>
          {confidenceScore <= 50 && (
            <TagLabel type="error" icon={<img src={dangerousOutlinedIconImg} alt="" style={{ height: 22 }} />}>
              Bilden var svår att läsa av och diverse fel kan förekomma. Vi rekommenderar att försöka med en bättre
              bild. Se riktlinjer ovan eller klicka på “Se exempel och tips” nedan.
            </TagLabel>
          )}
          {confidenceScore > 50 && confidenceScore < 85 && (
            <TagLabel type="warn" icon={<InfoOutlined fontSize="small" />}>
              Denna bild var delvis svår att tyda och fel kan förekomma. Vi rekommenderar att granska att informationen
              stämmer eller ta en bättre bild.
            </TagLabel>
          )}
        </Box>
      )}

      {!!data.deductions?.length && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>Faktorer som påverkar kvaliteten</AccordionSummary>
          <AccordionDetails borderless>
            <Box>
              <Box display="flex" flexWrap="wrap" gridGap={4}>
                {data.deductions.map((deduction, index) => (
                  <TagLabel key={index} type="info" label={deduction.reason} />
                ))}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {!!data.advisory_warnings?.length && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>Viktigt att notera</AccordionSummary>
          <AccordionDetails borderless>
            <Box>
              <TagLabel type="warn">
                <ul style={{ margin: 0, padding: 0, paddingLeft: 16 }}>
                  {data.advisory_warnings.map((warnning, index) => (
                    <li key={index}>{warnning}</li>
                  ))}
                </ul>
              </TagLabel>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {!!data.all_text_and_digits && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>Fullständig produktinformation</AccordionSummary>
          <AccordionDetails>
            <div style={{ whiteSpace: 'pre-wrap' }}>{data.all_text_and_digits}</div>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default ImageTranscriber;
