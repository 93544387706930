import { useParams } from 'react-router-dom';

import useCategoriesData from 'hooks/useCategoriesData';

import ProducersProducts from './ProducersProducts';
import QualityGroupList from './QualityGroupList';
import QualityGroupReview from './QualityGroupReview';

const BulkReview = () => {
  const { producerId, qualityGroupId } = useParams<{ producerId?: string; qualityGroupId?: string }>();

  const { loading: loadingCategory, rootCategory } = useCategoriesData();

  // Step 1
  if (!producerId) {
    return <ProducersProducts />;
  }

  // Step 2
  if (!qualityGroupId) {
    return <QualityGroupList loadingCategory={loadingCategory} rootCategory={rootCategory} />;
  }

  // Step 3
  return <QualityGroupReview loadingCategory={loadingCategory} rootCategory={rootCategory} />;
};

export default BulkReview;
