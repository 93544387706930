import { FC } from 'react';
import isNil from 'lodash/isNil';

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Box,
  CircularProgress,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorInfoIcon from 'components/product/BulkImport/components/ErrorInfoIcon';
import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';
import { getMenuItemStyle } from 'components/product/ProductUpdateForm/fields/review';
import { SUPERUSER_APPROVAL } from 'components/constants-ts';

import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';

import { IdCell } from './IdColumn';

import { IReviewColumn } from '../../type';
import { matchColumnCategories } from '../../utils/columnValidator';
import { commonValidator } from '../../utils/fieldValidator';

// Styles
const useStyles = makeStyles(() => ({
  tableCell: {
    height: 0, // set height: 0 to "td" to make div height: 100% work
  },
  bodyCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    gap: 8,
    height: '100%',
  },
  approveWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
}));

interface QualityGroupReviewTableProps {
  loading?: boolean;
  products: IProduct[];
  setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
  columns: IReviewColumn[];
  rootCategory?: IFoodlaCategoryOption;
}

const QualityGroupReviewTable: FC<QualityGroupReviewTableProps> = ({
  loading,
  products,
  setProducts,
  columns,
  rootCategory,
}) => {
  const classes = useStyles();

  const handleChange = (id: string, changedProduct: Partial<IProduct>) => {
    setProducts(oldState =>
      oldState.map(oldProduct => {
        if (oldProduct.id !== id) return oldProduct;
        return { ...oldProduct, ...changedProduct };
      })
    );
  };

  return (
    <TableContainer style={{ position: 'relative', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            {columns.map(({ columnName, field, minWidth }) => {
              return (
                <TableCell key={field} style={{ fontWeight: 'bold' }}>
                  <div style={{ minWidth: minWidth || 100, whiteSpace: 'nowrap' }}>{columnName || field}</div>
                </TableCell>
              );
            })}
            <TableCell style={{ fontWeight: 'bold' }}>Review Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {products.map((product, index) => {
            return (
              <TableRow key={product.id}>
                <IdCell columns={columns} product={product}>
                  {index + 1}
                </IdCell>

                {columns.map(column => {
                  const key = `${product.id}_${column.field}`;
                  let textValue = product[column.field];
                  const canApprove = column.checkCanApprove?.(product, rootCategory);

                  if (column.field === 'foodlaCategory') {
                    textValue = product[column.field]?.name;
                  }

                  let matchedCondition = false;

                  // check conditions
                  if (column.conditionFunc) {
                    if (column.conditionFunc?.(product, rootCategory)) {
                      matchedCondition = true;
                    }
                  } else {
                    if (matchColumnCategories({ product, column, rootCategory })) {
                      matchedCondition = true;
                    }
                  }

                  if (!matchedCondition || isNil(textValue)) {
                    textValue = '';
                  }

                  const approveButton = matchedCondition && canApprove && (
                    <div className={classes.approveWrapper}>
                      <ThreeWaySwitch
                        size="small"
                        name={column.field}
                        state={product}
                        setState={({ approve }) => handleChange(product.id || '', { approve })}
                      />
                    </div>
                  );

                  // Don't care about error of field which doesn't have type
                  if (!column.type) {
                    return (
                      <TableCell key={key} className={classes.tableCell}>
                        <div className={classes.bodyCell}>
                          {approveButton}
                          <div style={{ flexGrow: 1 }}>{textValue}</div>
                        </div>
                      </TableCell>
                    );
                  }

                  // Validate field
                  const commonErrorMessages = commonValidator(product, column).errorMessages;
                  const errorMessages = column.valueValidator?.(product)?.errorMessages || [];
                  // EAN's [valueValidator] already checked
                  if (column.field !== 'EAN') {
                    errorMessages.push(...commonErrorMessages);
                  }

                  const errorIconElement = !!errorMessages.length && (
                    <ErrorInfoIcon
                      clickToTrigger
                      messageButtlet
                      messageColor="#FF0000"
                      customIcon={<WarningIcon color="error" fontSize="small" />}
                      errors={errorMessages.map(message => ({ message }))}
                    />
                  );

                  if (column.type === 'text') {
                    return (
                      <TableCell key={key} className={classes.tableCell}>
                        <div className={classes.bodyCell}>
                          {approveButton}
                          <TextField
                            variant="outlined"
                            size="small"
                            error={!!errorMessages.length}
                            value={textValue}
                            InputProps={{
                              readOnly: column.readonly,
                              endAdornment: <div style={{ width: 20 }}>{errorIconElement}</div>,
                            }}
                            onChange={event => {
                              handleChange(product.id || '', { [column.field]: event.target.value });
                            }}
                          />
                        </div>
                      </TableCell>
                    );
                  }

                  return null;
                })}

                <TableCell className={classes.tableCell}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      variant="outlined"
                      name="adminStatus"
                      value={product.adminStatus}
                      style={getMenuItemStyle(product.adminStatus)}
                      onChange={event => {
                        handleChange(product.id || '', { adminStatus: event.target.value as string });
                      }}
                    >
                      <MenuItem
                        style={getMenuItemStyle(SUPERUSER_APPROVAL.ADMIN_APPROVED)}
                        value={SUPERUSER_APPROVAL.ADMIN_APPROVED}
                      >
                        Approved Review
                      </MenuItem>
                      <MenuItem
                        style={getMenuItemStyle(SUPERUSER_APPROVAL.AWAITING_APPROVAL)}
                        value={SUPERUSER_APPROVAL.AWAITING_APPROVAL}
                      >
                        Under Review
                      </MenuItem>
                      <MenuItem
                        style={getMenuItemStyle(SUPERUSER_APPROVAL.ADMIN_DISAPPROVED)}
                        value={SUPERUSER_APPROVAL.ADMIN_DISAPPROVED}
                      >
                        Failed Review
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            );
          })}

          {loading && (
            <TableRow>
              <TableCell colSpan={3} className={classes.tableCell}>
                <Box display="flex" justifyContent="center" py={10} height="100%">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QualityGroupReviewTable;
