import React, { useRef, useState } from 'react';

import ErrorStrongIcon from 'components/icons/ErrorStrongIcon';
import ErrorOutlineIcon from 'components/icons/ErrorOutlineIcon';
import LightTooltip from 'components/lightTooltip';
import { ClickAwayListener, makeStyles } from '@material-ui/core';

import { STATUS_COLORS, STATUS_COLORS_OPACITY } from '../constants';

const useCustomStyles = makeStyles(() => ({
  uploadErrorIconWrapper: {
    textAlign: 'center',
    lineHeight: 1,
    cursor: 'pointer',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    maxHeight: '50vh',
    overflow: 'auto',
    padding: '8px 16px',
  },
  uploadErrorName: {
    margin: 0,
    padding: '4px 8px',
    borderTop: `3px solid ${STATUS_COLORS.ERROR}`,
    background: STATUS_COLORS_OPACITY.ERROR,
  },
  uploadErrorContent: {
    position: 'relative',
    margin: 0,
    marginTop: 4,
    fontSize: 12,
    borderRadius: 4,
  },
  messageButtlet: {
    display: 'inline-block',
    left: -10,
    top: 1,
    fontSize: 20,
    transform: 'translateY(2px)',
    height: '12px',
    marginRight: 4,
  },
}));

export type TErrorInfoData = {
  label?: React.ReactNode;
  message?: React.ReactNode;
  messages?: React.ReactNode[];
};

export interface ErrorInfoIconProps {
  type?: 'error' | 'warn';
  customIcon?: React.ReactNode;
  clickToTrigger?: boolean;
  messageButtlet?: boolean;
  messageColor?: string;
  errors: TErrorInfoData[];
}

const ErrorInfoIcon = ({
  type = 'error',
  clickToTrigger,
  messageButtlet,
  messageColor,
  errors,
  customIcon,
}: ErrorInfoIconProps) => {
  const customClasses = useCustomStyles();

  const keepRef = useRef({ isHoverPopper: false, isHoverTarget: false });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setTimeout(() => {
      const { isHoverPopper, isHoverTarget } = keepRef.current;
      if (!isHoverPopper && !isHoverTarget) {
        setOpen(false);
      }
    }, 0);
  };

  if (!errors.length) return null;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <LightTooltip
          open={open}
          arrow
          nospace
          placement="top"
          disableHoverListener={clickToTrigger}
          title={
            <div className={customClasses.contentWrapper} style={{ color: messageColor }}>
              {errors
                .filter(({ label, message, messages }) => label || message || messages?.length)
                .map(({ label, message, messages }, index) => (
                  <div key={index} style={{ padding: '2px 0' }}>
                    {label && <p className={customClasses.uploadErrorName}>{label}</p>}
                    {message && (
                      <p className={customClasses.uploadErrorContent}>
                        {messageButtlet && <span className={customClasses.messageButtlet}>•</span>}
                        {message}
                      </p>
                    )}

                    {messages?.map((message, index) => (
                      <p key={index} className={customClasses.uploadErrorContent}>
                        {messageButtlet && <span className={customClasses.messageButtlet}>•</span>}
                        {message}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          }
          PopperProps={{
            onMouseOver: () => (keepRef.current.isHoverPopper = true),
            onMouseLeave: () => {
              keepRef.current.isHoverPopper = false;
              !clickToTrigger && handleClose();
            },
            style: { pointerEvents: 'auto' },
          }}
          onOpen={() => setOpen(true)}
          onClose={handleClose}
        >
          <div
            className={customClasses.uploadErrorIconWrapper}
            onClick={() => setOpen(!open)}
            onMouseEnter={() => (keepRef.current.isHoverTarget = true)}
            onMouseLeave={() => (keepRef.current.isHoverTarget = false)}
          >
            {customIcon || (
              <>
                {type === 'error' && <ErrorStrongIcon width={24} height={24} />}
                {type === 'warn' && <ErrorOutlineIcon width={24} height={24} style={{ color: 'orange' }} />}
              </>
            )}
          </div>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ErrorInfoIcon;
