import { FC, HTMLAttributes, useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import Button from 'components/button';

import ConfirmModal from 'components/ConfirmModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 24px',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #C5C9D8',
    boxShadow: '0px -4px 16px 0px rgba(156, 163, 188, 0.32)',
  },
}));

interface FooterWrapperProps extends HTMLAttributes<HTMLDivElement> {
  onCancel?: () => void;
}

const FooterWrapper: FC<FooterWrapperProps> = ({ children, onCancel }) => {
  const classes = useStyles();

  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [fixedElement, setFixedElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const mainElement = document.getElementById('page-main') as HTMLElement;
    if (!mainElement || !fixedElement) return;

    const observer = new ResizeObserver(() => {
      const { left } = mainElement.getBoundingClientRect();
      fixedElement.style.width = `${mainElement.offsetWidth}px`;
      fixedElement.style.left = `${left}px`;
    });
    observer.observe(mainElement);
    return () => observer.disconnect();
  }, [fixedElement]);

  return (
    <div ref={setFixedElement} className={classes.wrapper}>
      <Box sx={{ fontSize: 16 }}></Box>
      <Box display="flex" gridGap={16}>
        {onCancel && (
          <Button variant="outlined" onClick={() => setShowConfirmReset(true)}>
            Avbryt
          </Button>
        )}

        {children}
      </Box>

      <ConfirmModal
        open={!!showConfirmReset}
        content="All changes will be lost. Do you want to continue?"
        onCancel={() => setShowConfirmReset(false)}
        onOk={onCancel}
      />
    </div>
  );
};

export default FooterWrapper;
