import React from 'react';
import Button from '@material-ui/core/Button';

import XRegExp from 'xregexp';

import VisionTab from '../tab';
import { DESCRIPTIVE_SIZE_UNITS_MAP } from '../../constants-ts';
import { PARSE_INGREDIENTS } from '../../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import omitBy from 'lodash/omitBy';
import { isNull } from 'lodash';
import { transformCategoryRead } from 'utils/products';

// TODO add parse ingredients
// /gis/s
// ingredienser[:|.]\s*(?<ingredients>.+?)\s*(?:\.|näringsvärde)
// energi:?\s*(?<kj>\d+)\s*kj(?:\/|\()?(?<kcal>\d+)\s*kcal?
// (?<!mätt[e|a]t\s*)fett\s+(?<fett>(?:\d+,)?\d+)\s*[g|\s]
// varav\s+mätt[ae]t\s+fett\s+(?<varav_mattat_fett>(?:\d+,)?\d+)\s*(?:g|\s)
// kolhydrat\s+(?<kolhydrat>(?:\d+,)?\d+)\s*(?:g|\s)
// varav\s+sockerarter\s+(?<varav_sockerarter>(?:\d+,)?\d+)\s*(?:g|\s)
// protein\s+(?<protein>(?:\d+,)?\d+)\s*(?:g|\s)
// salt\s+(?<salt>(?:\d+,)?\d+)\s*(?:g|\s)
// TODO fruktmängd\s+(?<fruktmangd>(?:\d+,)?\d+)\s*(?:g|\s)

const parse = ({text, title, regex, postfix}) => {
    if (text && text.trim() !== '' && regex) {
        let newRegex = regex;
        if (typeof regex !== 'string') {
          newRegex = regex.source;
          // console.log('!!!!', regex);
          // console.log('!!!!', newRegex);
        }
        
        const rx = new XRegExp(newRegex, 'gsiu');
        const result = rx.exec(text);
        // console.log('!rx', title, result);
        const val1 = result?.[1];
        // reset regex https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically
        // regex.lastIndex = 0;
        if (val1) {
            return val1;
            // return (
            //     <p><b>{title}: </b>{val1}{postfix && ` ${postfix}`}</p>
            // );
        }
    }
    return undefined;
    // return (
    //     <p><b>{title}: </b><span style={{color: 'red'}}>NOT FOUND</span></p>
    // );
};


const multiRegexParsing = ({ text, regexs, outputNum = 1 }) => {
  let found = false;
  let val = undefined;
  if (text && text.trim() !== '') {
    regexs.forEach((item, i) => {
      if (!found) {
        let newRegex = item.regex;
        if (newRegex && typeof item.regex !== 'string') {
          newRegex = item.regex.source;
          // console.log('!!!!', regex);
          // console.log('!!!!', newRegex);
        }

        const rx = new XRegExp(newRegex, 'gsiu');
        const result = rx.exec(text);
        let tmp = result?.[1];
        if (tmp || tmp === 0) {
          val = tmp;
          found = true;
        }
      }
    });
  }
  return val;
};

const parseEAN = (text) => {
  const regex = 'ean\\s*:\\s*(\\d+)\\s+';
  return parse({
    text,
    title: 'EAN',
    regex,
  });
};

const parseIngredients = (text) => {
    const regex = '(?:ingredienser(?:\\s+100\\s*g)?|ingredients|zutaten|ingrediender)[:.;\\s]\\s*(.+?)\\s*(?:(?:(?<!\\d)\\.(?<!\\d))|(?:näringsvärde)|fruktmängd|(?:,?\\s*per\\s+100\\s*g)|(?:\\s*kött\\s+till\\s+100\\s*g(?:ram)?\\s+korv))';
    return parse({
        text,
        title: 'Ingredienser',
        regex,
        // postfix: 'g',
    });
};

const parseFett = (text) => {
    // fix whitespace after mattat
    // const regex = /(?<!mätt[e|a]t\s)fett[\.\s]+((?:\d+,)?\d+)\s*[g|\n]/;
    const regex = '(?<!mätt[e|a]t\\s)fett[.\\s]+((?:\\d+,)?\\d+)\\s*(?:g|%|\\s+(?!kj))';
    return parse({
        text,
        title: 'Fett',
        regex,
        postfix: 'g',
    });
};

const parseVaravMattatFett = (text) => {
    // fix whitespace after mattat
    const regex = /(?:saturated fat|(?:varav\s+)?mätt[e|a]t(?:\s+fett)?|gesättigte\s+fettsäuren)[.\s]+((?:\d+,)?\d+)\s*[g|\s|%]/;
    // console.log('!!!', regex.toString());
    return parse({
        text,
        title: 'Varav mättat fett',
        regex,
        postfix: 'g',
    });
};

const parseKolhydrat = (text) => {
    const regex = /(?:carbohydrates|kolhydrat|kolhydrater|kohlenhydrate)[\s.]+((?:\d+[,.])?\d+)\s*[g\s%]/;
    return parse({
        text,
        title: 'Kolhydrat',
        regex,
        postfix: 'g',
    });
};

const parseProtein = (text) => {
    // ((?:\d+,)?\d+)\s*g\s+protein
    // 21G PROTEIN PER 100 GRAM 
    // https://foodlab2b.imgix.net/Anton/Products/1624573566280__DSC4917%20Kopie(1).jpg
    const regex = /protein[\s.]+((?:\d+,)?\d+)\s*[g|\s|%]/;
    return parse({
        text,
        title: 'Protein',
        regex,
        postfix: 'g',
    });
};

const parseSalt = (text) => {
    const regex = /salt[\s.]+((?:\d+,)?\d+)\s*[g|\s|%]/;
    return parse({
        text,
        title: 'Salt',
        regex,
        postfix: 'g',
    });
};

const parseEnergiKj = (text) => {
  const regexs = [
    { regex: /(\d+)\s*kj/ },
    { regex: /[:,.]\s*kj((?:\d+[,.])?\d+)/ },
    { regex: /(?:(?:\d+[,.])?\d+)\s*kcal\s*\/\s*((?:\d+[,.])?\d+)\s*k[ij]?/ },
    { regex: /energi\s+((?:\d+[,.])?\d+)\s*k[ji1]/ },
    { regex: /[^0-9]\s*kj\s*((?:\d+[,.])?\d+)/ },
  ];
  const val = multiRegexParsing({ text, regexs });
  return val;
};

const parseEnergiKcal = (text) => {
  const regexs = [
    { regex: /[^0-9]\s*kj\s*(?:(?:\d+[,.])?\d+)\s+kcal\s*((?:\d+[,.])?\d+)/ },
    { regex: /(\d+)\s*kcal?/ },
    { regex: /[.,:]\s*kcal\s*((?:\d+[,.])?\d+)/ },
  ];
  const val = multiRegexParsing({ text, regexs });
  return val;
};

const parseVaravSockerarter = (text) => {
  // TODO \s*socker\s+per\s+100\s*g\s*[.:]\s*(?:(?:\d+[,.])?\d+)\s*g\s+varav\s+((?:\d+[,.])?\d+)\s*g
  // const regex = /varav\s+sockerarter[\s.<]+((?:\d+,)?\d+)[g\s]?/;
  const regexs = [
    { regex: /(?:(?:varav\s+)?socker-?\s*arter|sugars|sockerarter)[\s.<]+((?:\d+[,.])?\d+)\s*[g\n|%]?/ },
    { regex: /\s*socker\s+per\s+100\s*g\s*[.:]\s*(\d+)\s*g/ },
  ];
  const val = multiRegexParsing({ text, regexs });
  return val;
};

const parseNettokvantitet = (text) => {
  const regexs = [
    { regex: /(?:net\s+weight|nettovi[lk]t|nettogewicht)\s+((?:\d+,)?\d+)\s*(g|gram|kg|ml|cl|dl|l|liter|st|påsar|bit)\s/gisu },
    { regex: /(?:netto|\s|^)vikt\s+(g|gram|kg|ml|cl|dl|l|liter|st|påsar|bit)\s*:?\s*((?:\d+,)?\d+)/gisu, reverse: true },
    // (?:netto|\s|^)vikt\s+(kg)\s+
    // (?:netto|\s|^)vikt(?:\s+(kg)\s*:\s*)?\s+((?:\d+,)?\d+)
    { regex: /^\s*((?:\d+[,.])?\d+)\s*(g|gram|kg|ml|cl|dl|l|liter|st|påsar|bit)(?:\s*\/?\s*(?:\d+[,.])\d+\s*oz\s*)?$/gimu },
  ];
  let enhet = undefined;
  let mangd = undefined;
  let found = false;
  if (text && text.trim() !== '') {
    regexs.forEach((item, i) => {
      if (!found) {
        const result = item.regex.exec(text);
        console.log('!netto', i, result);
        let val1, val2;
        if (item.reverse) {
          val1 = result?.[2];
          val2 = result?.[1];
        } else {
          val1 = result?.[1];
          val2 = result?.[2];
        }
        if (val1 && val2) {
        val2 = val2.toLowerCase();
          let enh;
          switch(val2) {
            case 'g':
            case 'gram':
              enh = 'GRAM';
              break;
            case 'kg':
              enh = 'KILOGRAM';
              break;
            case 'ml':
              enh = 'MILLILITER';
              break;   
            case 'cl':
              enh = 'CENTILITER';
              break;    
            case 'dl':
              enh = 'DECILITER';
              break;   
            case 'l':
            case 'liter':
              enh = 'LITER';
              break;   
            case 'st':
              enh = 'ITEM';
              break;   
            case 'påsar':
              enh = 'BAGS';
              break;   
            case 'bit':
              enh = 'PIECE';
              break;   
            default:
              enh = undefined;
              break;
            // TODO: units
          }
          if (enh) {
            enhet = enh;
            mangd = val1
            found = true;
          }
        }
      }
    });
  }
  return { mangd, enhet };
}

const parseItem = ({ value, name, convert = true }) => {
  let v = value;
  console.log('!v', 1, name, v);
  if (v || v === 0 || v === false) {
    if (convert) {
      v = v.replace(',', '.');
      console.log('!v', 2, name, v);
      v = parseFloat(v);
      console.log('!v', 3, name, v);
    }
    const item = {};
    item[name] = v;
    return { item, hasItem: true };
  }
  return { item: undefined, hasItem: false };
}

const parseAll = (text) => {
    let items = {};
    let hasItems = false;

    const ingredient_statement = parseIngredients(text);
    const { hasItem: ingredientsHasItem, item: ingredientsItem } = parseItem({ value: ingredient_statement, items, name: 'ingredient_statement', convert: false });
    if (ingredientsHasItem) {
      items = { ...items, ...ingredientsItem };
      hasItems = true;
    }

    const ean_statement = parseEAN(text);
    const { hasItem: eanHasItem, item: eanItem } = parseItem({ value: ean_statement, items, name: 'EAN', convert: false });
    if (eanHasItem) {
      items = { ...items, ...eanItem };
      hasItems = true;
    }

    const fett = parseFett(text);
    const { hasItem: fettHasItem, item: fettItem } = parseItem({ value: fett, items, name: 'fett' });
    if (fettHasItem) {
      items = { ...items, ...fettItem };
      hasItems = true;
    }

    const mattatFett = parseVaravMattatFett(text);
    const { hasItem: varavMattatFettHasItem, item: varavMattatFettItem } = parseItem({ value: mattatFett, items, name: 'mattatFett' });
    if (varavMattatFettHasItem) {
      items = { ...items, ...varavMattatFettItem };
      hasItems = true;
    }

    const kolhydrat = parseKolhydrat(text);
    const { hasItem: kolhydratHasItem, item: kolhydratItem } = parseItem({ value: kolhydrat, items, name: 'kolhydrat' });
    if (kolhydratHasItem) {
      items = { ...items, ...kolhydratItem };
      hasItems = true;
    }

    const protein = parseProtein(text);
    const { hasItem: proteinHasItem, item: proteinItem } = parseItem({ value: protein, items, name: 'protein' });
    if (proteinHasItem) {
      items = { ...items, ...proteinItem };
      hasItems = true;
    }

    const salt = parseSalt(text);
    const { hasItem: saltHasItem, item: saltItem } = parseItem({ value: salt, items, name: 'salt' });
    if (saltHasItem) {
      items = { ...items, ...saltItem };
      hasItems = true;
    }

    const energi_kj = parseEnergiKj(text);
    const { hasItem: energiKjHasItem, item: energiKjItem } = parseItem({ value: energi_kj, items, name: 'energi_kj' });
    if (energiKjHasItem) {
      items = { ...items, ...energiKjItem };
      hasItems = true;
    }

    const energi_kcal = parseEnergiKcal(text);
    const { hasItem: energiKcalHasItem, item: energiKcalItem } = parseItem({ value: energi_kcal, items, name: 'energi_kcal' });
    if (energiKcalHasItem) {
      items = { ...items, ...energiKcalItem };
      hasItems = true;
    }

    const sockerarter = parseVaravSockerarter(text);
    const { hasItem: varavSockerarterHasItem, item: varavSockerarterItem } = parseItem({ value: sockerarter, items, name: 'sockerarter' });
    if (varavSockerarterHasItem) {
      items = { ...items, ...varavSockerarterItem };
      hasItems = true;
    }

    const { mangd, enhet } = parseNettokvantitet(text);
    const { hasItem: mangdHasItem, item: mangdItem } = parseItem({ value: mangd, items, name: 'descriptive_size_amount' });
    if (mangdHasItem) {
      items = { ...items, ...mangdItem };
      hasItems = true;
    }
    const { hasItem: enhetHasItem, item: enhetItem } = parseItem({ value: enhet, items, name: 'descriptive_size_unit', convert: false });
    if (enhetHasItem) {
      items = { ...items, ...enhetItem };
      hasItems = true;
    }
    
    return { items, hasItems };
};

const createIngredientComponent = ({ item, name, title, postfix }) => {
  if (item && name in item && (item[name] || item[name] === 0)) {
    return (
      <p><b>{title}: </b>{item[name]}{postfix && ` ${postfix}`}</p>
    );
  } else {
    return (
      <p><b>{title}: </b><span style={{color: 'red'}}>NOT FOUND</span></p>
    );
  }
};

const createIngredientComponents = (item, autoFillButton) => {
  const components = [];
  transformCategoryRead(item);

  // TODO: fix
  // if (item && item.foodlaCategory) {
  //   components.push(createIngredientComponent({
  //     item: item.foodlaCategory, name: 'name',  title: 'Kategori',
  //   }));
  // }
  components.push(createIngredientComponent({
    item, name: 'title',  title: 'Produktnamn',
  }));
  components.push(createIngredientComponent({
    item, name: 'ingredient_statement',  title: 'Ingredienser',
  }));
  if (autoFillButton) {
    components.push(autoFillButton);
  }
  components.push(createIngredientComponent({
    item, name: 'EAN',  title: 'EAN',
  }));
  components.push(createIngredientComponent({
    item, name: 'short_text',  title: 'Produktbeskrivning',
  }));
  components.push(createIngredientComponent({
    item, name: 'consumer_size_to_order_size_ratio',  title: 'B-packstorlek (Kfp/Dfp)',
  }));
  components.push(createIngredientComponent({
    item, name: 'min_temperature',  title: 'Min temperature',
  }));
  components.push(createIngredientComponent({
    item, name: 'max_temperature',  title: 'Max temperature',
  }));

  let kj, kcal;
  if (item && 'energi_kj' in item && item.energi_kj) {
    kj = `${item.energi_kj} kJ`;
  } else {
    kj = <span style={{color: 'red'}}>NOT FOUND (kJ)</span>;
  }

  if (item && 'energi_kcal' in item && item.energi_kcal) {
    kcal = `${item.energi_kcal} kcal`;
  } else {
    kcal = <span style={{color: 'red'}}>NOT FOUND (kcal)</span>;
  }
  components.push(
    <p><b>Energi: </b> {kj}/{kcal}</p>
  );

  components.push(createIngredientComponent({
    item, name: 'fett',  title: 'Fett', postfix: 'g',
  }));
  components.push(createIngredientComponent({
    item, name: 'mattatFett',  title: 'Varav mättat fett', postfix: 'g',
  }));
  components.push(createIngredientComponent({
    item, name: 'kolhydrat',  title: 'Kolhydrat', postfix: 'g',
  }));
  components.push(createIngredientComponent({
    item, name: 'sockerarter',  title: 'Varav sockerarter', postfix: 'g',
  }));
  components.push(createIngredientComponent({
    item, name: 'protein',  title: 'Protein', postfix: 'g',
  }));
  components.push(createIngredientComponent({
    item, name: 'salt',  title: 'Salt', postfix: 'g',
  }));

  if (item && 'descriptive_size_amount' in item && (item.descriptive_size_unit || item.descriptive_size_unit === 0)) {
    let unit = item.descriptive_size_unit;
    let tmp = DESCRIPTIVE_SIZE_UNITS_MAP.find((x) => x.enum === unit);
    if (tmp) unit = tmp.value;
    

    components.push(
      <p><b>Nettokvantitet: </b>{item.descriptive_size_amount} {unit}</p>
    );
  } else {
    components.push(
      <p><b>Nettokvantitet: </b><span style={{color: 'red'}}>NOT FOUND</span></p>
    );
  }

  return components;
};
 
const Ingredients = ({ visionTab, image, info, isNew, state, setState }) => {
  const { data, loading, error, refetch } = useQuery(
    PARSE_INGREDIENTS,
    {
      variables: {
        text: info?.fullTextAnnotation?.text,
      }
    }
    // {
    //   // refetchQueries: [],
    //   fetchPolicy: 'no-cache',
    //   onCompleted: data => {
    //     setInfo(data?.getVisionByUrl);
    //   },
    // }
  );
  let items;
  let hasItems;
  if (data?.parseIngredients) {
    items = data?.parseIngredients?.items;
    // items = items.map(transformCategoryRead);
    hasItems = data?.parseIngredients?.hasItems;
  }
  if (items) {
    items = omitBy(items, isNull);
  }

  // const { items, hasItems } = parseAll(info?.fullTextAnnotation?.text);
  console.log('!rx items', items);

  const onAutofill = () => {
    let changeUnit = false;
    if (items.EAN && items.EAN.trim() !== '') {
      if (
        items?.EAN && (
          items.EAN.trim().startsWith('23')
          || items.EAN.trim().startsWith('2095')
          || items.EAN.trim().startsWith('2096')
          || items.EAN.trim().startsWith('2097')
          || items.EAN.trim().startsWith('2000')
        )
      ) {
        changeUnit = true;
      }
    } else if (state.EAN && state.EAN.trim() !== '') {
      if (
        state?.EAN && (
          state.EAN.trim().startsWith('23')
          || state.EAN.trim().startsWith('2095')
          || state.EAN.trim().startsWith('2096')
          || state.EAN.trim().startsWith('2097')
          || state.EAN.trim().startsWith('2000')
        )
      ) {
        changeUnit = true;
      }
    }
    if (changeUnit) {
      items.descriptive_size_unit = 'GRAM_APPROXIMATE';
    }
    // console.log('!rx items2', items);

    setState({...state, ...items});
  };
  return (
    <VisionTab
      index="ingredients"
      tabValue={visionTab}
      image={image}
      pictureWidth={6}
      info={info}
      isNew={isNew}
    >
      <p>
        <b>Full text:</b>{' '}
        <span>{info?.fullTextAnnotation?.text}</span>
      </p>
      
      {createIngredientComponents(
        items,
        <Button
          // component={Link}
          // to="/orders"
          variant="outlined"
          disabled={!hasItems}
          // size="medium"
          onClick={onAutofill}
          color="primary"
          // startIcon={<OrdersIcon />}
        >
          Autofill information
        </Button>
      )}
      {/* {parseIngredients(info?.fullTextAnnotation?.text)}
      {parseFett(info?.fullTextAnnotation?.text)}
      {parseVaravMattatFett(info?.fullTextAnnotation?.text)}
      {parseKolhydrat(info?.fullTextAnnotation?.text)}
      {parseProtein(info?.fullTextAnnotation?.text)}
      {parseSalt(info?.fullTextAnnotation?.text)}
      {parseEnergi(info?.fullTextAnnotation?.text)} */}
    </VisionTab>
  );
};

export default Ingredients;
