import { FC, createContext, useMemo, useState } from 'react';

export type TUploadedProductStatus = { dataId: string; productId?: string; error?: string };

export type TLayoutContextValue = {
  isCheckingProducer: boolean;
  setCheckingProducer: React.Dispatch<React.SetStateAction<boolean>>;
  rightbarWidth: number;
  setRightbarWidth: React.Dispatch<React.SetStateAction<number>>;
};

export const contextDefaultValue: TLayoutContextValue = {
  isCheckingProducer: false,
  setCheckingProducer: () => {},
  rightbarWidth: 0,
  setRightbarWidth: () => {},
};

export const LayoutContext = createContext<TLayoutContextValue>(contextDefaultValue);

export const LayoutProvider: FC = ({ children }) => {
  const [isCheckingProducer, setCheckingProducer] = useState(false);
  const [rightbarWidth, setRightbarWidth] = useState(0);

  const value = useMemo(
    () => ({
      isCheckingProducer,
      setCheckingProducer,
      rightbarWidth,
      setRightbarWidth,
    }),
    [isCheckingProducer, setCheckingProducer, rightbarWidth, setRightbarWidth]
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
