import { gql } from 'apollo-boost';

export const S3_SIGNED_REQUEST_MUTATION = gql`
  mutation S3_SIGNED_REQUEST($input: S3SignedRequestInput!) {
    getS3SignRequest(input: $input) {
      requests {
        signedRequest
        url
        pictureUrl
        picturePath
        fileName
      }
    }
  }
`;

export const MUTATION_ADD_PRODUCT = gql`
  mutation ADD_PRODUCT($input: ProductInput!, $producerUsername: String) {
    createProduct(input: $input, producerUsername: $producerUsername) {
      success
      productTitle
      productId
      message
    }
  }
`;

export const MUTATION_ADD_MESSAGE = gql`
  mutation ADD_MESSAGE($input: MessageInput!) {
    createMessage(input: $input) {
      text
      created_at
      updated_at
      name
      isAdmin
      isProducer
    }
  }
`;

export const MUTATION_SET_EXPORTED_PRODUCTS = gql`
  mutation SET_EXPORTED_PRODUCTS($oneType: Boolean, $nonFood: Boolean){
    setExportedProducts(oneType: $oneType, nonFood: $nonFood){
      success
      error
    }
  }
`;

export const MUTATION_REMOVE_PRODUCT_FROM_EXPORT = gql`
  mutation removeProductFromExport($id: ID!){
    removeProductFromExport(id: $id){
      success
      error
    }
  }
`;

export const MUTATION_SUPERUSER_APPROVE = gql`
  mutation SUPERUSER_APPROVE($input: ProductUpdateInput!, $productNotes: [ProductNoteInput], $id: String!, $forceApprove: Boolean) {
    updateProduct(input: $input, forceApprove: $forceApprove) {
      success
      message
    }
    updateProductNotes(productId: $id, input: $productNotes) {
      success
      message
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT_NOTES = gql`
  mutation UPDATE_PRODUCT_NOTES($productNotes: [ProductNoteInput], $id: String!) {
    updateProductNotes(productId: $id, input: $productNotes) {
      success
      message
    }
  }
`

export const MUTATION_UPDATE_OUT_OF_STOCK = gql`
  mutation SUPERUSER_APPROVE($input: ProductUpdateInput!) {
    updateProduct(input: $input, ignoreApprove: true) {
      success
      message
    }
  }`
;

export const MUTATION_PRODUCER_UPDATE_PRODUCT = gql`
  mutation MUTATION_PRODUCER_UPDATE_PRODUCT($input: ProductUpdateInput!) {
    updateProduct(input: $input) {
      success
      message
    }
  }
`;

export const MUTATION_SUPERUSER_ADD_PRODUCER = gql`
  mutation CREATE_PRODUCER($input: ProducerInput!) {
    createProducer(input: $input) {
      name
      success
      message
      entityId
    }
  }
`;

export const MUTATION_SUPERUSER_UPDATE_PRODUCER = gql`
  mutation UPDATE_PRODUCER($input: ProducerInput!) {
    updateProducer(input: $input) {
      name
      success
      message
      producer {
        profiles
      }
    }
  }
`;

export const MUTATION_SUPERUSER_ORGANIZATION_INPUT = gql`
  mutation SUPERUSER_CREATE_ORGANIZATION($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      success
      message
      name
    }
  }
`;

export const MUTATION_SUPERUSER_ORGANIZATION_EDIT = gql`
  mutation SUPERUSER_CREATE_ORGANIZATION($input: CreateOrganizationInput!) {
    updateOrganization(input: $input) {
      success
      message
      name
    }
  }
`;

export const MUTATION_SUPERUSER_UPDATE_ORGANIZATION = gql`
  mutation SUPERUSER_CREATE_ORGANIZATION($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      success
      message
      name
    }
  }
`;

export const TAG_PRODUCT = gql`
  mutation TagProduct($input: TagInput!) {
    tagProduct(input: $input) {
      id
      state
      oldState
    }
  }
`;

export const MUTATION_UPDATE_PRODUCER_PROFILE = gql`
  mutation MUTATION_UPDATE_PRODUCER_PROFILE($input: UpdateProfileInfo!) {
    updateProducerProfileInfo(input: $input) {
      name
      success
      message
    }
  }
`;

export const MUTATION_GET_VISION = gql`
  mutation MUTATION_GET_VISION($image: String!) {
    getVision(image: $image) {
      error {
        message
      }
      localizedObjectAnnotations {
        languageCode
        name
        score
        boundingPoly {
          normalizedVertices {
            x
            y
          }
        }
      }
      labelAnnotations {
        # mid
        locale
        description
        score
        confidence
        topicality
        boundingPoly {
          normalizedVertices {
            x
            y
          }
        }
      }
      logoAnnotations {
        # mid
        locale
        description
        score
        confidence
        topicality
        boundingPoly {
          vertices {
            x
            y
          }
        }
      }
      imagePropertiesAnnotation {
        dominantColors {
          colors {
            color {
              red
              green
              blue
              alpha
            }
            score
            pixelFraction
          }
        }
      }
      safeSearchAnnotation {
        adult
        spoof
        medical
        violence
        racy
        adultConfidence
        spoofConfidence
        medicalConfidence
        violenceConfidence
        racyConfidence
        nsfwConfidence
      }
      fullTextAnnotation {
        pages {
          blocks {
            paragraphs {
              words {
                symbols {
                  # property
                  # boundingBox
                  text
                  confidence
                }
                # property
                # boundingBox
                confidence
              }
              # property
              boundingBox {
                vertices {
                  x
                  y
                }
              }
              confidence
            }
            # property
            boundingBox {
              vertices {
                x
                y
              }
            }
            blockType
            confidence
          }
          width
          height
          confidence
        }
        text
      }
    }
  }
`;

export const MUTATION_GET_VISION_BY_URL = gql`
  mutation MUTATION_GET_VISION_BY_URL($url: String!) {
    getVisionByUrl(url: $url) {
      error {
        message
      }
      chatGPT {
        recognizeData
        title
        EAN
        short_text
        ingredient_statement
        non_food_ingredients
        animalFoodIngredients
        feedAdditives
        feedingInstructions
        analyticalConstituentsFeed
        dosageAndUsageInstructions
        trade_item_temperature_information
        nutritional_unit
        energi_kj
        energi_kcal
        fett
        mattatFett
        kolhydrat
        sockerarter
        fiber
        protein
        salt
        descriptive_size_amount
        descriptive_size_unit
        minTemperature
        maxTemperature
        gross_weight_num
        place_of_item_activity
        brand_food
        brand
        classification
      }
      localizedObjectAnnotations {
        languageCode
        name
        score
        boundingPoly {
          normalizedVertices {
            x
            y
          }
        }
      }
      labelAnnotations {
        # mid
        locale
        description
        score
        confidence
        topicality
        boundingPoly {
          normalizedVertices {
            x
            y
          }
        }
      }
      logoAnnotations {
        # mid
        locale
        description
        score
        confidence
        topicality
        boundingPoly {
          vertices {
            x
            y
          }
        }
      }
      imagePropertiesAnnotation {
        dominantColors {
          colors {
            color {
              red
              green
              blue
              alpha
            }
            score
            pixelFraction
          }
        }
      }
      safeSearchAnnotation {
        adult
        spoof
        medical
        violence
        racy
        adultConfidence
        spoofConfidence
        medicalConfidence
        violenceConfidence
        racyConfidence
        nsfwConfidence
      }
      fullTextAnnotation {
        pages {
          blocks {
            paragraphs {
              words {
                symbols {
                  # property
                  # boundingBox
                  text
                  confidence
                }
                # property
                # boundingBox
                confidence
              }
              # property
              boundingBox {
                vertices {
                  x
                  y
                }
              }
              confidence
            }
            # property
            boundingBox {
              vertices {
                x
                y
              }
            }
            blockType
            confidence
          }
          width
          height
          confidence
        }
        text
      }
    }
  }
`;

export const MUTATION_ACCEPT_PRODUCT_UPDATE = gql`
  mutation ACCEPT_PRODUCT_UPDATE($productId: String!) {
    acceptProductUpdate(productId: $productId) {
      success
      message
    }
  }
`;

export const MUTATION_REMOVE_PRODUCT = gql`
  mutation REMOVE_PRODUCT($productId: String!) {
    removeProduct(productId: $productId) {
      success
      message
    }
  }
`;

export const MUTATION_RESTORE_PRODUCT = gql`
  mutation RESTORE_PRODUCT($productId: String!) {
    restoreProduct(productId: $productId) {
      success
      message
    }
  }
`;
