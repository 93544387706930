import { FC, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TablePagination,
  CircularProgress,
  makeStyles,
  Card,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import IProduct, { IFoodlaCategory } from 'models/product';
import IProducer from 'models/producer';
import { IFoodlaCategoryOption } from 'models/category';
import { GET_PRODUCER, GET_PRODUCER_PRODUCTS_DETAIL } from 'graphql/queries';
import { getParentCategoriesData } from 'components/product/ProductUpdateForm/fields/category';

// Styles
const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

type TQualityCategoryGroup = {
  qualityCategoryCategory: IFoodlaCategory;
  products: Pick<IProduct, 'id' | 'foodlaCategory'>[];
};

interface QualityGroupListProps {
  loadingCategory?: boolean;
  rootCategory?: IFoodlaCategoryOption;
}

const QualityGroupList: FC<QualityGroupListProps> = ({ loadingCategory, rootCategory }) => {
  const classes = useStyles();
  const history = useHistory();
  const { producerId } = useParams<{ producerId?: string }>();

  const { data: producerData } = useQuery(GET_PRODUCER, {
    variables: { id: producerId },
  });

  const { loading, data } = useQuery(GET_PRODUCER_PRODUCTS_DETAIL, {
    variables: { id: producerId },
  });

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const producer = useMemo<IProducer>(() => {
    return producerData?.producer;
  }, [producerData]);

  const productsDetailList = useMemo<Pick<IProduct, 'id' | 'foodlaCategory'>[]>(() => {
    if (loading || loadingCategory) return [];
    return data?.producerProductsDetail || [];
  }, [data, loading, loadingCategory]);

  const { groupList, unknowProducts } = useMemo<{
    groupList: TQualityCategoryGroup[];
    unknowProducts: Pick<IProduct, 'id' | 'foodlaCategory'>[];
  }>(() => {
    if (!rootCategory) return { groupList: [], unknowProducts: [] };

    const result: Record<string, TQualityCategoryGroup> = {};
    const unknowProducts: Pick<IProduct, 'id' | 'foodlaCategory'>[] = [];

    productsDetailList.forEach(product => {
      if (!product.foodlaCategory) {
        return unknowProducts.push(product);
      }
      const parentCategoriesData = getParentCategoriesData(product.foodlaCategory, rootCategory) || [];

      const qualityCategoryCategory = parentCategoriesData[1];
      if (!qualityCategoryCategory) {
        return unknowProducts.push(product);
      }

      if (!result[qualityCategoryCategory.id]) {
        result[qualityCategoryCategory.id] = { qualityCategoryCategory, products: [] };
      }
      result[qualityCategoryCategory.id].products.push(product);
    });

    const groupList = Object.values(result).map(item => item);

    return { groupList, unknowProducts };
  }, [productsDetailList, rootCategory]);

  const dataTable = useMemo(() => {
    const { page, limit } = pagination;
    return groupList.slice((page - 1) * limit, page * limit);
  }, [groupList, pagination]);

  return (
    <div>
      <Box display="flex" alignItems="center" gridGap={4} mb={3}>
        <Box className={classes.link} onClick={() => history.push('/superuser/bulk-review')}>
          Butiker och Leverantörer
        </Box>
        <ArrowForwardIosIcon style={{ fontSize: 14 }} />
        <Box fontWeight={700}>Kategorier</Box>
      </Box>

      <Card>
        <h3 style={{ margin: 16 }}>Kategorier {producer?.name ? `- ${producer.name}` : ''}</h3>

        <TableContainer style={{ position: 'relative' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={200} style={{ fontWeight: 'bold' }}>
                  Karetogi
                </TableCell>
                <TableCell width={200} style={{ fontWeight: 'bold' }}>
                  Antal produkter att godkänna
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataTable.map(({ qualityCategoryCategory, products }) => {
                return (
                  <TableRow
                    key={qualityCategoryCategory.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/superuser/bulk-review/${producerId}/${qualityCategoryCategory.id}`)}
                  >
                    <TableCell>{qualityCategoryCategory.name}</TableCell>
                    <TableCell>{products.length}</TableCell>
                  </TableRow>
                );
              })}

              {!!unknowProducts.length && (
                <TableRow role="checkbox">
                  <TableCell>Unknown</TableCell>
                  <TableCell>{unknowProducts.length}</TableCell>
                </TableRow>
              )}

              {(loading || loadingCategory) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box display="flex" justifyContent="center" py={10} height="100%">
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={groupList.length}
          rowsPerPage={pagination.limit}
          page={pagination.page - 1}
          onPageChange={(_, pageIndex) => setPagination({ ...pagination, page: pageIndex + 1 })}
          onRowsPerPageChange={event => setPagination({ page: 1, limit: +event.target.value })}
        />
      </Card>
    </div>
  );
};

export default QualityGroupList;
