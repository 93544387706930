import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { getCountryCode } from './helpers-ts';
import {
  ALL_HEADERS,
  ORDERED_FIELDS,
  ALWAYS_DISPLAY_FOOD_FIELD_SET,
  ALWAYS_DISPLAY_NON_FOOD_FIELD_SET,
  MANDATORY_FOOD_FIELD_SET,
  MANDATORY_FOOD_NUTRITIONAL_FIELD_SET,
  MANDATORY_NON_FOOD_FIELD_SET,
  PRODUCT_BOOLEAN_FIELD,
  PRODUCT_BOOLEAN_FIELD_VALUES,
  PRODUCT_DATE_FIELD,
  PRODUCT_ENUM_FIELD,
  PRODUCT_ENUM_FIELD_VALUES,
  PRODUCT_NUMBER_FIELD,
  PRODUCT_EAN_FIELDS,
  NON_FOOD_CATEGORY_LIST,
  FOOD_CATEGORY_LIST,
} from 'components/constants-ts';
import { NON_FOOD_CATEGORY_ID } from 'components/constants-ts';
import IProduct from 'models/product';
import { TExcelDataRow } from 'models/excel';
import { getItems as getSearchTerms } from 'components/product/ProductUpdateForm/fields/search-terms';
import { getEAN2, isNonFoodProduct, isValidEAN } from './products';

const EXCEL_ERROR_VALUES = ['#N/A', '#REF!', '#NAME?', '#DIV/0!', '#NULL!', '#VALUE!', '#NUM!'];

export interface IImageFileSource {
  id: string;
  source: string;
  name: string;
  fileName: string;
  extension: string;
  file?: File;
  aiImageData?: IImageFileSource;
  convertedFile?: File;
  uploaded?: boolean;
  uploadError?: string;
  generateAiError?: string;
  uploadAiError?: string;
  url?: string;
  assignProductId?: string;
}

export interface IProductExcel extends IProduct {
  /**
   * Support for exporting excel
   */
  _editingData?: IProduct;
  _savedData?: IProduct;
  _revertedSavedData?: IProduct;
  _excelData: Record<string, Date | string | number | null>;
}

export type DataType = 'string' | 'number' | 'boolean' | 'date';

export interface IImportColumn extends IProduct {
  _id: string;
  title: string;
  field: keyof IProduct;
  dataType: DataType;
  isEnumType: boolean;
  order?: number;
  isMissing?: boolean;
  isNutritionalFood?: boolean;
  /**
   * Boolean;
   *
   * True: will be included to export file
   *
   * - all columns of excel file
   */
  exportable?: boolean;
  isError?: boolean;
  isMandatory?: boolean;
  isEmptyColumn?: boolean;
  numberOfCell?: number;
  numberOfEmptyCell?: number;
  numberOfWrongTypeCell?: number;
  numberOfInvalidValueCell?: number;
  firstErrorCellId?: string | null;
}

export type TParseExcelDataToProductReturn = {
  columnList: IImportColumn[];
  dataList: IProductExcel[];
};

export const HEADER_MAPPING_STATUS = {
  NO_MAPPING: 'no',
  MAPPING_SUCCESS: 'success',
  MAPPING_FAILED: 'failed',
};

export const STATUS_COLORS = {
  ERROR: '#F95E5D',
  WARNING: '#FFFF00',
  SUCCESS: '#9DC683',
  NORMAL: '#FFFFFF',
};

export const STATUS_COLORS_OPACITY = {
  ERROR: '#FFEFEE',
  WARNING: '#FFFFEC',
  SUCCESS: '#F5F9F2',
  NORMAL: '#FFFFFF',
};

export const VALID_DATE_FORMATS = [
  'dd.MM.yy',
  'dd.MM.yyyy',
  'dd/MM/yy',
  'dd/MM/yyyy',
  'dd-MM-yy',
  'dd-MM-yyyy',
  'dd MMM yy',
  'dd MMM yyyy',
  'dd MMM, yy',
  'dd MMM, yyyy',
];

export const parseDateString = (value: string) => {
  let validDate: Date | undefined;

  VALID_DATE_FORMATS.forEach(format => {
    if (validDate) return;

    const parsedDate = dateFns.parse(value, format, new Date());
    if (!dateFns.isValid(parsedDate)) return;

    validDate = parsedDate;
  });

  return validDate;
};

export const computeBaseItems = (dataList: IProductExcel[]) => {
  const computedDataList = _.cloneDeep(dataList);

  // base items
  for (let index = 0; index < computedDataList.length; index++) {
    const mappedData = computedDataList[index];
    const {
      transportEANForStorePack,
      consumer_size_to_order_size_ratio,
      transportQtyOfNextLowerItem,
      palletLayerQtyOfNextLowerItem,
      palletQtyOfNextLowerItem,
    } = mappedData;

    // Transport Pack/Qty of base item
    if (transportEANForStorePack && consumer_size_to_order_size_ratio && transportQtyOfNextLowerItem) {
      mappedData.transportQtyOfBaseItem = consumer_size_to_order_size_ratio * transportQtyOfNextLowerItem;
    }

    // Pall/Pallet Layer/Qty of base item
    if (transportEANForStorePack) {
      if (mappedData.transportQtyOfBaseItem && palletLayerQtyOfNextLowerItem) {
        mappedData.palletLayerQtyOfBaseItem = mappedData.transportQtyOfBaseItem * palletLayerQtyOfNextLowerItem;
      }
    } else {
      if (consumer_size_to_order_size_ratio && palletLayerQtyOfNextLowerItem) {
        mappedData.palletLayerQtyOfBaseItem = consumer_size_to_order_size_ratio * palletLayerQtyOfNextLowerItem;
      }
    }

    // Pall/Pallet /Qty of base item
    if (mappedData.palletLayerQtyOfBaseItem && palletQtyOfNextLowerItem) {
      mappedData.palletQtyOfBaseItem = mappedData.palletLayerQtyOfBaseItem * palletQtyOfNextLowerItem;
    }
  }

  return computedDataList;
};

const getMadatorySet = (data: IProductExcel, isIncludeNutritionalFood = false) => {
  const isFood = !isNonFoodProduct(data);
  const madatorySet = new Set(isFood ? MANDATORY_FOOD_FIELD_SET : MANDATORY_NON_FOOD_FIELD_SET);

  if (isFood && isIncludeNutritionalFood) {
    MANDATORY_FOOD_NUTRITIONAL_FIELD_SET.forEach(field => madatorySet.add(field));
  }

  return madatorySet;
};

export const getCellValue = (column: IImportColumn, data: IProductExcel) => {
  const { field } = column;
  if (column.field === 'foodlaCategory') {
    return data.foodlaCategory?.name;
  }
  if (column.dataType === 'date') {
    const value = data[field] as Date;
    if (_.isNil(value)) return null;
    return value;
  }
  if (!_.isNil(data[field])) return data[field] as string | number;
  return null;
};

export const convertBooleanValue = (column: IImportColumn, data: IProductExcel) => {
  const { field, dataType } = column;
  const checkingValue = getCellValue(column, data);
  if (dataType !== 'boolean' || _.isNil(checkingValue)) return checkingValue;

  const value = _.toString(checkingValue).trim().toLowerCase();

  let booleanConvertValue = PRODUCT_BOOLEAN_FIELD_VALUES[field]?.find(item => item.valueList.includes(value));
  if (booleanConvertValue) return booleanConvertValue.convertTo;

  booleanConvertValue = PRODUCT_BOOLEAN_FIELD_VALUES.all.find(item => item.valueList.includes(value));
  if (booleanConvertValue) return booleanConvertValue.convertTo;

  return null;
};

export const convertEnumValue = (column: IImportColumn, data: IProductExcel) => {
  const { field, isEnumType } = column;
  const checkingValue = getCellValue(column, data);
  if (!isEnumType || _.isNil(checkingValue)) return checkingValue;

  const allowedValues = PRODUCT_ENUM_FIELD_VALUES[field]?.find(
    item => _.toString(item.value) === _.toString(checkingValue)
  );
  if (allowedValues) return allowedValues.convertTo || allowedValues.value;
  return null;
};

export const isWrongTypeCell = (column: IImportColumn, data: IProductExcel) => {
  const { dataType, isEnumType } = column;

  const checkingValue = getCellValue(column, data);

  if (_.isNil(checkingValue)) return false;
  if (PRODUCT_EAN_FIELDS.includes(column.field)) {
    return !isValidEAN(checkingValue as string);
  }
  if (isEnumType) {
    return _.isNil(convertEnumValue(column, data));
  }
  if (dataType === 'date') {
    return !checkingValue || !dateFns.isDate(checkingValue as number);
  }
  if (dataType === 'number') {
    const regex = /^([-+]?\d+(?:[,.]\d+)?)$/gimu;
    const valueToString = _.toString(checkingValue).trim();
    return !regex.test(valueToString);
  }
  if (dataType === 'boolean') {
    return _.isNil(convertBooleanValue(column, data));
  }
  return false;
};

export const isEmptyCell = (column: IImportColumn, data: IProductExcel) => {
  const value = getCellValue(column, data);
  return _.isNil(value) || value === '';
};

export const isInvalidValueCell = (column: IImportColumn, data: IProductExcel) => {
  const value = getCellValue(column, data);
  if (column.field === 'country_of_manufacturing_string') {
    return !getCountryCode(String(value || ''));
  }
  return false;
};

export const isErrorCell = (column: IImportColumn, data: IProductExcel) => {
  return (
    isWrongTypeCell(column, data) ||
    isInvalidValueCell(column, data) ||
    (column.isMandatory && isEmptyCell(column, data))
  );
};

export const getErrorRowCells = (columnList: IImportColumn[], data: IProductExcel) => {
  const errorList: { message: string; column: IImportColumn; data: IProductExcel }[] = [];

  columnList.forEach(column => {
    if (!isErrorCell(column, data)) return;
    const isEmpty = isEmptyCell(column, data);
    errorList.push({ message: isEmpty ? 'Missing value' : 'Invalid value', column, data });
  });

  return errorList;
};

const insertColumnList = (columnList: IImportColumn[], additionalColumnList: IImportColumn[], index: number) => {
  let columnIndex = index;
  if (columnIndex < 0) columnIndex = 0;

  return [...columnList.slice(0, columnIndex), ...additionalColumnList, ...columnList.slice(columnIndex)];
};

export const checkEnumType = (field: keyof IProduct): boolean => {
  return PRODUCT_ENUM_FIELD.includes(field);
};

export const getDataType = (field: keyof IProduct): DataType => {
  if (PRODUCT_DATE_FIELD.includes(field)) return 'date';
  if (PRODUCT_NUMBER_FIELD.includes(field)) return 'number';
  if (PRODUCT_BOOLEAN_FIELD.includes(field)) return 'boolean';
  return 'string';
};

export class ImportExcelColumnHelper {
  constructor(column: IImportColumn, excelHelper: ImportExcelHelper) {
    this.column = column;
    this.excelHelper = excelHelper;
    this.numberOfCell = excelHelper.dataList.length;
  }

  color = STATUS_COLORS.NORMAL;
  isError = false;
  isMandatory = false;
  isEmptyColumn = false;
  numberOfCell = 0;
  numberOfEmptyCell = 0;
  numberOfWrongTypeCell = 0;
  numberOfInvalidValueCell = 0;
  firstErrorCellId: string | null = null;

  private column: IImportColumn;
  private excelHelper: ImportExcelHelper;
  private emptyCellList: IProductExcel[] = [];
  private wrongTypeCellList: IProductExcel[] = [];
  private invalidValueCellList: IProductExcel[] = [];

  mapColumn(): IImportColumn {
    this.setIsMandatoryField();
    this.setEmptyCellList();
    this.setWrongTypeCellList();
    this.setInvalidValueCellList();
    this.setIsError();
    this.setFirstErrorCellId();
    this.setColor();

    const {
      color,
      isError,
      isMandatory,
      isEmptyColumn,
      numberOfCell,
      numberOfEmptyCell,
      numberOfWrongTypeCell,
      numberOfInvalidValueCell,
      firstErrorCellId,
    } = this;

    return {
      ...this.column,
      color,
      isError,
      isMandatory,
      isEmptyColumn,
      numberOfCell,
      numberOfEmptyCell,
      numberOfWrongTypeCell,
      numberOfInvalidValueCell,
      firstErrorCellId,
    };
  }

  setIsMandatoryField() {
    const { dataList, hasNutritionalFoodFilled } = this.excelHelper;
    const { field } = this.column;

    if (!field) return false;

    this.isMandatory = dataList.some(data => {
      const madatorySet = getMadatorySet(data, hasNutritionalFoodFilled);
      return madatorySet.has(field);
    });
  }

  setEmptyCellList() {
    const { dataList } = this.excelHelper;
    const column = this.column;

    this.emptyCellList = dataList.filter(data => isEmptyCell(column, data));
    this.numberOfEmptyCell = this.emptyCellList.length;
    this.isEmptyColumn = this.emptyCellList.length === dataList.length;
  }

  setWrongTypeCellList() {
    const { dataList } = this.excelHelper;
    const column = this.column;

    this.wrongTypeCellList = dataList.filter(data => isWrongTypeCell(column, data));
    this.numberOfWrongTypeCell = this.wrongTypeCellList.length;
  }

  setInvalidValueCellList() {
    const { dataList } = this.excelHelper;
    const column = this.column;

    this.invalidValueCellList = dataList.filter(data => isInvalidValueCell(column, data));
    this.numberOfInvalidValueCell = this.invalidValueCellList.length;
  }

  setIsError() {
    this.isError =
      this.numberOfWrongTypeCell > 0 ||
      this.numberOfInvalidValueCell > 0 ||
      (this.isMandatory && this.numberOfEmptyCell > 0);
  }

  setFirstErrorCellId() {
    if (!this.isError) {
      this.firstErrorCellId = null;
    } else {
      this.firstErrorCellId = _.toString(
        Math.min(
          ...(this.isMandatory ? this.emptyCellList : []).map(({ id }) => Number(id)),
          ...this.wrongTypeCellList.map(({ id }) => Number(id)),
          ...this.invalidValueCellList.map(({ id }) => Number(id))
        )
      );
    }
  }

  setColor() {
    const { field } = this.column;
    if (!field) {
      this.color = STATUS_COLORS.NORMAL;
    } else if (this.isError) {
      this.color = STATUS_COLORS.ERROR;
    } else {
      this.color = STATUS_COLORS.SUCCESS;
    }
  }
}

export class ImportExcelHelper {
  constructor(columnList: IImportColumn[], dataList: IProductExcel[]) {
    this.initialColumnList = columnList;
    this.columnList = columnList;
    this.dataList = dataList;
  }

  columnList: IImportColumn[];
  dataList: IProductExcel[];
  hasNutritionalColumn = false;
  hasNutritionalFoodFilled = false;

  private initialColumnList: IImportColumn[];

  init() {
    this.setHasNutrionalFood();
    this.setHasNutritionalValue();
    this.columnList = this.addNutritionalColumns();

    return { columnList: this.columnList };
  }

  getColumnHelper(column: IImportColumn) {
    return new ImportExcelColumnHelper(column, this);
  }

  setHasNutrionalFood() {
    this.hasNutritionalColumn = this.initialColumnList.some(({ field }) =>
      MANDATORY_FOOD_NUTRITIONAL_FIELD_SET.has(field)
    );
  }

  setHasNutritionalValue() {
    this.hasNutritionalFoodFilled = false;

    if (!this.hasNutritionalColumn) return;

    const foodDataList = this.dataList.filter(data => !isNonFoodProduct(data));

    MANDATORY_FOOD_NUTRITIONAL_FIELD_SET.forEach(field => {
      const hasValue = foodDataList.some(data => data[field] || data[field] === 0);
      if (hasValue) {
        this.hasNutritionalFoodFilled = true;
      }
    });
  }

  addNutritionalColumns() {
    if (!this.hasNutritionalColumn) return this.initialColumnList;

    let lastIndexOfNutritionalField = 0;
    const additionalNutritionalFieldSet = new Set(MANDATORY_FOOD_NUTRITIONAL_FIELD_SET);

    // add [isNutritionalFood] to columnList
    const mappedColumnList = this.initialColumnList.map(column => {
      if (!MANDATORY_FOOD_NUTRITIONAL_FIELD_SET.has(column.field)) return column;
      return { ...column, isNutritionalFood: true };
    });

    // get additional field set
    mappedColumnList.forEach(({ field }, colunIndex) => {
      // existed in excel
      if (additionalNutritionalFieldSet.has(field)) {
        additionalNutritionalFieldSet.delete(field);
        lastIndexOfNutritionalField = colunIndex;
      }
    });

    // get additional columns
    const additionalColumnList: IImportColumn[] = [];
    additionalNutritionalFieldSet.forEach(additionalField => {
      const [title] = Object.entries(ALL_HEADERS).find(([_, field]) => field === additionalField) || [''];
      additionalColumnList.push({
        _id: uuid(),
        isMissing: true,
        title,
        field: additionalField,
        isNutritionalFood: true,
        isEnumType: checkEnumType(additionalField),
        dataType: getDataType(additionalField),
      });
    });

    return insertColumnList(mappedColumnList, additionalColumnList, lastIndexOfNutritionalField + 1);
  }
}

export const getFooldaCategoryByName = (name: string) => {
  return [...NON_FOOD_CATEGORY_LIST, ...FOOD_CATEGORY_LIST].find(category => category.name === name);
};

export const parseExcelDataToProduct = (excelData: TExcelDataRow[] = []): TParseExcelDataToProductReturn => {
  let excelTitleList = excelData[0] as string[];
  const excelDataList = excelData.slice(1);
  const parsedValues: TParseExcelDataToProductReturn = { columnList: [], dataList: [] };

  if (!excelTitleList) return parsedValues;
  excelTitleList = excelTitleList.map(title => _.toString(title));

  excelDataList.forEach((_, index) => parsedValues.dataList.push({ id: `${index + 1}`, _excelData: {} }));

  excelTitleList.forEach((title, columnIndex) => {
    const field = ALL_HEADERS[title] || '';

    parsedValues.columnList.push({
      _id: uuid(),
      field,
      title,
      exportable: true,
      isEnumType: checkEnumType(field),
      dataType: getDataType(field),
    });

    excelDataList.forEach((row, rowIndex) => {
      if (field) {
        // has value when excel has duplicated columns
        const oldValue = parsedValues.dataList[rowIndex][field];
        let value = row[columnIndex] as never;
        // don't get new value when this field was filled
        if (oldValue || oldValue === 0) {
          value = oldValue as never;
        }
        (parsedValues.dataList[rowIndex][field] as any) = value;
      }
      parsedValues.dataList[rowIndex]._excelData[title] = row[columnIndex];
    });
  });

  // update brand_food and transform foodlaCategory
  parsedValues.dataList.forEach(row => {
    row.foodlaCategory = getFooldaCategoryByName(row.foodlaCategory as unknown as string);

    if (!row.foodlaCategory || isNonFoodProduct(row)) return;
    row.brand_food = row.brand;
  });

  // search_terms
  const searchTermsField = 'search_terms';
  const isExcelHasSearchTerms = parsedValues.columnList.find(({ field }) => field === searchTermsField);
  const [searchTermsTitle] = Object.entries(ALL_HEADERS).find(([_, field]) => field === searchTermsField) || [''];
  // add search_terms column
  if (!isExcelHasSearchTerms && searchTermsTitle) {
    parsedValues.columnList.push({
      _id: uuid(),
      field: searchTermsField,
      title: searchTermsTitle,
      isEnumType: false,
      isMissing: true,
      dataType: getDataType(searchTermsField),
    });
  }
  // add search_terms value
  for (let index = 0; index < parsedValues.dataList.length; index++) {
    const mappedData = parsedValues.dataList[index];
    mappedData[searchTermsField] = getSearchTerms(mappedData);
  }

  // purchasingDataSupplierArticleNo
  for (let index = 0; index < parsedValues.dataList.length; index++) {
    const mappedData = parsedValues.dataList[index];
    mappedData.purchasingDataSupplierArticleNo = mappedData.article;
  }

  // convert EAN to string
  for (let index = 0; index < parsedValues.dataList.length; index++) {
    const mappedData = parsedValues.dataList[index];
    mappedData.EAN = _.toString(mappedData.EAN).trim();
  }

  // EAN2
  const ean2Field = 'EAN2';
  const indexOfEAN = parsedValues.columnList.findIndex(({ field }) => field === 'EAN');
  const [ean2Title] = Object.entries(ALL_HEADERS).find(([_, field]) => field === ean2Field) || [''];
  if (indexOfEAN > -1) {
    const newColumn: IImportColumn = {
      _id: uuid(),
      field: ean2Field,
      title: ean2Title,
      isEnumType: false,
      isMissing: true,
      dataType: getDataType(ean2Field),
    };

    // add EAN2 column
    parsedValues.columnList = insertColumnList(parsedValues.columnList, [newColumn], indexOfEAN + 1);

    // add EAN2 value
    for (let index = 0; index < parsedValues.dataList.length; index++) {
      const mappedData = parsedValues.dataList[index];
      const ean = _.toString(mappedData.EAN).trim();
      mappedData.EAN2 = getEAN2(ean) || undefined;
    }
  }

  // base items
  parsedValues.dataList = computeBaseItems(parsedValues.dataList);

  return parsedValues;
};

const loadExcelFile = (file: File): Promise<Excel.Workbook | null> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    const workbook = new Excel.Workbook();

    reader.onload = async () => {
      try {
        await workbook.xlsx.load(reader.result as Buffer);
        resolve(workbook);
      } catch {
        resolve(null);
      }
    };

    try {
      reader.readAsArrayBuffer(file);
    } catch {
      resolve(null);
    }
  });
};

export const getCorrectColumnPosition = (columnList: IImportColumn[], checkingColumn: IImportColumn) => {
  let correctPosition = -1;
  const missingColumnIndex = ORDERED_FIELDS.findIndex(field => field === checkingColumn.field);
  // reversed list of all field, which are stand in before the missing field
  const reversedLeftFieldList = ORDERED_FIELDS.slice(0, missingColumnIndex).reverse();

  // find closest field, which is existing in excel file and stand before the missing field
  reversedLeftFieldList.forEach(leftField => {
    if (correctPosition > -1) return;
    correctPosition = columnList.findIndex(({ field }) => field === leftField);
  });

  return correctPosition;
};

export const correctMissingColumnPositions = (columnList: IImportColumn[]) => {
  const missingColumnList = columnList.filter(({ isMissing }) => isMissing);
  const excelColumnList = columnList.filter(({ isMissing }) => !isMissing);

  const result = [...excelColumnList];

  missingColumnList.forEach(missingColumn => {
    let correctPosition = getCorrectColumnPosition(result, missingColumn);

    if (correctPosition === -1) {
      result.push(missingColumn);
    } else {
      result.splice(correctPosition + 1, 0, missingColumn);
    }
  });

  return result;
};

export const addMissingColumns = (params: {
  productType: string;
  columnList: IImportColumn[];
  dataList: IProductExcel[];
}) => {
  const { productType, columnList, dataList } = params;
  if (!productType) return columnList;

  const result = columnList.filter(({ isMissing }) => !isMissing);

  const isFood = productType !== NON_FOOD_CATEGORY_ID;
  const alwaysDisplayFieldSet = isFood ? ALWAYS_DISPLAY_FOOD_FIELD_SET : ALWAYS_DISPLAY_NON_FOOD_FIELD_SET;

  alwaysDisplayFieldSet.forEach(field => {
    if (result.find(col => col.field === field)) return;

    const [title] = Object.entries(ALL_HEADERS).find(entry => {
      if (field === 'brand_food') return entry[1] === 'brand';
      return entry[1] === field;
    }) || [''];

    const firstErrorCell = dataList.find(data => _.isNil(data[field]));

    result.push({
      _id: uuid(),
      isMissing: true,
      title,
      field,
      dataType: getDataType(field),
      isMandatory: true,
      isEnumType: false,
      isError: true,
      firstErrorCellId: firstErrorCell?.id || '1',
      color: firstErrorCell ? STATUS_COLORS.ERROR : STATUS_COLORS.NORMAL,
    });
  });

  return correctMissingColumnPositions(result);
};

export const writeExcelFile = async (
  columnList: IImportColumn[],
  dataList: IProductExcel[],
  oldFile: File,
  { fileName = 'export_file' } = {}
) => {
  const workbook = await loadExcelFile(oldFile);

  if (!workbook) return;

  const worksheet = workbook.worksheets[0];

  const fillExcelCell = ({ color, col, row }: { col: number; row: number; color?: string }) => {
    const oldStyles = worksheet.getRow(row).getCell(col).style;
    let fill: Excel.Fill = { type: 'pattern', pattern: 'none' };
    if (color) {
      fill.pattern = 'solid';
      fill.fgColor = { argb: color.replace('#', '') };
    }
    worksheet.getRow(row).getCell(col).style = { ...oldStyles, fill };
  };

  const updateExcelCellValue = (params: { col: number; row: number; value: any; field: keyof IProduct }) => {
    const { col, row, value, field } = params;
    if (_.isNil(value)) return;

    // try to convert value to number if possible
    if (PRODUCT_NUMBER_FIELD.includes(field)) {
      let numberValue: string = _.toString(value).trim();
      if (/^\d+$/.test(numberValue)) {
        worksheet.getRow(row).getCell(col).value = parseFloat(numberValue);
        return;
      }
    }

    worksheet.getRow(row).getCell(col).value = value as string | Date;
  };

  // insert missing column to excel file
  columnList.forEach((column, index) => {
    if (column.isMissing) {
      worksheet.spliceColumns(index + 1, 0, [column.title]);
      worksheet.getColumn(index + 1).width = 30;
    }
  });

  // fill color for header cells
  columnList.forEach(({ color }, index) => {
    fillExcelCell({ row: 1, col: index + 1, color: color === STATUS_COLORS.NORMAL ? undefined : color });
  });

  // fill color and update new value for body cells
  dataList.forEach((data, rowIndex) => {
    columnList.forEach((column, colIndex) => {
      const isError = isErrorCell(column, data);
      const row = rowIndex + 2;
      const col = colIndex + 1;
      fillExcelCell({ row, col, color: isError ? STATUS_COLORS.ERROR : undefined });

      const updatedValue = data._editingData?.[column.field];
      updateExcelCellValue({ row, col, value: updatedValue, field: column.field });
    });
  });

  try {
    // write the content using writeBuffer
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName}.xlsx`);
  } catch {}
};

export const removeSpecialCharactors = (value: string) => {
  const specialCharactorCodes = [8203, 8204, 8205, 8206, 8207];
  if (typeof value !== 'string') return value;

  return value
    .split('')
    .map(character => (specialCharactorCodes.includes(character.charCodeAt(0)) ? '' : character))
    .join('');
};

export const readExcelFile = async (file: File, sheetIndex = 0): Promise<TExcelDataRow[]> => {
  const workbook = await loadExcelFile(file);

  if (!workbook) return [];

  const workSheet = workbook.worksheets[sheetIndex];
  const excelData: TExcelDataRow[] = [];

  const productDateHeaders = Object.entries(ALL_HEADERS)
    .filter(([, field]) => PRODUCT_DATE_FIELD.includes(field))
    .map(([title]) => title);

  workSheet.eachRow((row, rowNumber) => {
    const headers = excelData[0] || [];

    const _cells = (row as any)._cells;
    const values = row.values as unknown as Excel.CellValue[];
    const rowData: TExcelDataRow = [];
    for (let colNumber = 1; colNumber < values?.length; colNumber++) {
      const value = values[colNumber] as any;

      const numFmt = _.toString(_cells[colNumber - 1]?.numFmt);
      const isPercent = rowNumber !== 1 && numFmt.endsWith('%');
      const isDate = rowNumber !== 1 && productDateHeaders.includes(headers[colNumber - 1] as string);

      if (_.isNil(value)) {
        rowData.push(null);
      } else if (isDate) {
        let dateValue = value;
        if (parseDateString(value)) {
          dateValue = parseDateString(value)!;
        }
        rowData.push(dateValue);
      } else if (isPercent) {
        if (typeof value === 'number') {
          rowData.push(`${Number(value) * 100}%`);
        } else {
          rowData.push(value);
        }
      } else if (typeof value !== 'object') {
        rowData.push(removeSpecialCharactors(value as string));
      } else if (!_.isNil(value.result)) {
        rowData.push(value.result);
      } else if (value.richText?.length) {
        const newValue = value.richText.map(({ text }: { text: string }) => text).join('');
        rowData.push(removeSpecialCharactors(newValue as string));
      } else {
        console.error(`!!!Error Unknown cell value (${typeof value}): ${value}`);
        rowData.push(null);
      }
    }
    excelData.push(rowData);
  });

  const result: TExcelDataRow[] = [];

  for (let rowIndex = 0; rowIndex < excelData.length; rowIndex++) {
    // remove empty row
    if (!excelData[rowIndex]?.length) continue;
    const row = [...excelData[rowIndex]];

    // fill null to 'error' cell
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      if (EXCEL_ERROR_VALUES.includes(String(row[colIndex]))) {
        row[colIndex] = null;
      }
    }

    // check valid row
    if (row.some(cell => cell !== null && String(cell).trim() !== '')) {
      result.push(row);
    }
  }

  return result;
};
