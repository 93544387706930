import React, { useEffect, useMemo } from 'react';
import isNil from 'lodash/isNil';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  map,
} from 'ramda';

import InputLabel from '../../../inputfields/InputLabel';
import InputTextField from '../../../inputfields/InputTextField';
import ListDropDown from '../../../inputfields/ListDropDown';
import {
  APPLICATION_ROLES,
  getDescriptiveSizeEnumFromValue,
  getDescriptiveSizeValueFromEnum,
} from '../../../constants';
import { DESCRIPTIVE_SIZE_UNITS_MAP } from '../../../constants-ts';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import DescriptiveSize from '../DescriptiveSize';
import {
  descriptiveSizeVariants,
} from '../constants';
import { toSafeFloat } from '../../../../utils/helpers';
import { checkErrorGrossWeightAndNetWeight } from './gross-weight';
import useViewport from 'hooks/useViewports';
import { isBlank } from 'utils/helpers-ts';
import { FormHelperText } from '@material-ui/core';

export const checkDescriptiveError = (data, options = {}) => {
  const { isCoopStoreProduct } = options;
  if (!isCoopStoreProduct && checkErrorGrossWeightAndNetWeight(data)) {
    return `Net weight must be smaller than gross weight (currently ${data.gross_weight_num} gram)`;
  }
  return '';
}

export const Descriptive = ({ state, setState, approve, userRole, isFood, isCoopStoreProduct, isCreatingNew }) => {
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const { smUp } = useViewport();
  
  const [focused, setFocused] = React.useState(false);

  const isPackProduct = useMemo(() => {
    return state?.EAN?.trim().startsWith('23')
    || state?.EAN?.trim().startsWith('2095')
    || state?.EAN?.trim().startsWith('2096')
    || state?.EAN?.trim().startsWith('2097')
    || state?.EAN?.trim().startsWith('2000');
  }, [state]);

  const requireError = useMemo(() => {
    if (isCreatingNew && !focused && !state.isDraft) {
      return '';
    }

    if (
      isBlank(state.descriptive_size_unit) ||
      isNil(state.descriptive_size_amount) ||
      state.descriptive_size_amount <= 0
    ) {
      return 'Vänligen fyll i detta fält eller spara som utkast.';
    }
    return '';
  }, [isCreatingNew, focused, state]);

  const unitWarn = useMemo(() => {
    if (isPackProduct && state.descriptive_size_unit) {
      const approximateUnits = DESCRIPTIVE_SIZE_UNITS_MAP.filter((x) => x.isEan2);
      if (!approximateUnits.some((item) => item.enum === state.descriptive_size_unit)) {
        return 'Produktens EAN-kod börjar med "23", "2095", "2096", "2097" eller "2000", vilket vanligtvis innebär att enheten bör vara "gram ungefärlig vikt". Kontrollera noggrant att den valda enheten stämmer.';
      }
    }
  }, [state, isPackProduct]);

  const error = useMemo(() => {
    return checkDescriptiveError(state, { isCoopStoreProduct });
  }, [state, isCoopStoreProduct]);

  useEffect(() => {
    if (isPackProduct) {
      // wait for set EAN2 completed
      setTimeout(() => {
        setState((oldState) => ({ ...oldState, descriptive_size_unit: 'GRAM_APPROXIMATE' }));
      }, 100);
    }
  }, [isPackProduct, setState]);

  const handleDescriptiveSizeAmountChange = event => {
    // Enforce float if descriptive_size_amount
    const stateName = event.target.name;
    let stateValue = event.target.value;

    stateValue = 0;
    try {
      stateValue = toSafeFloat(event.target.value || 0);
    } catch (e) {
      // Show error
    }
    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    setState({ ...state, [stateName]: stateValue });
  };
  const handleDescriptiveSizeUnitChange = event => {
    const enumValue = getDescriptiveSizeEnumFromValue(event.target.value);
    let descriptive_size_amount_extra = state.descriptive_size_amount_extra;
    if (event.target.value !== "GRAM_APPROXIMATE") {
      if (descriptive_size_amount_extra && Array.isArray(descriptive_size_amount_extra) && descriptive_size_amount_extra.length > 0) {
        descriptive_size_amount_extra = [];
      }
    }
    setState({ ...state, [event.target.name]: enumValue, descriptive_size_amount_extra });
  };

  return (
      <>
        <InputLabel
          heading="Nettokvantitet"
          text="Produkten ska märkas med information om volym, storlek eller vikt beroende på typ av produkt (för t.ex. vätskor, livsmedel, varor). Läs mer om nettokvantitet för livsmedel här."
          link="https://kontrollwiki.livsmedelsverket.se/artikel/45/nettokvantitet"
          required
        />
          <div style={{ display: 'flex', flexDirection: smUp ? 'row' : 'column', flex: 1 }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <InputTextField
                fullWidth
                id={'descriptive_size_amount'}
                forceRed={
                  !!error ||
                  (!!requireError &&
                    (isNil(state.descriptive_size_amount) || state.descriptive_size_amount <= 0)
                  )
                }
                label="Mängd"
                margin="normal"
                inputName={'descriptive_size_amount'}
                value={state.descriptive_size_amount}
                variant="outlined"
                disabled={state?.isMagazine}
                onChange={handleDescriptiveSizeAmountChange}
                onFocus={() => setFocused(true)}
              style={{ flex: smUp ? 0.3 : 0.5 }}
              />
              <ListDropDown
                label="Enhet"
                disabled={state?.isMagazine}
                listData={map(({ value }) => ({ value }), DESCRIPTIVE_SIZE_UNITS_MAP)}
                handleChange={handleDescriptiveSizeUnitChange}
                value={getDescriptiveSizeValueFromEnum(state.descriptive_size_unit)}
                targetName="descriptive_size_unit"
                style={{ marginLeft: 15, paddingTop: 5, flex: smUp ? 0.7 : 0.5, maxWidth: '100%', overflow: 'hidden' }}
                forceRed={!!requireError && isBlank(state.descriptive_size_unit)}
                onOpen={() => setFocused(true)}
              />
            </div>
            {isSuperuser && approve && state?.approve?.find((x) => x.field === 'nettokvantitet') && (
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <ThreeWaySwitch
                  name="nettokvantitet"
                  state={state}
                  setState={setState}
                  style={{ marginLeft: 15, marginTop: smUp ? 5 : 0, flexShrink: 0, maxHeight: 56, justifySelf: 'end' }}
                />
              </div>
            )}
            </div>
            
            <FormHelperText error={requireError || error || unitWarn} style={{ margin: '-6px 14px 0 14px' }}>
              {requireError || error || unitWarn || 'Obligatoriskt för att aktivera produkten, men kan sparas som utkast utan att fyllas i.'}
            </FormHelperText>

            {state.descriptive_size_amount_extra && state.descriptive_size_amount_extra.map((dsa, index) => (
               <DescriptiveSize index={index} state={state} setState={setState} />
            ))}
            {state.descriptive_size_unit === "GRAM_APPROXIMATE" && isFood && (
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <AddCircleIcon style={{color: "rgb(0, 163, 71)"}} fontSize="small" />
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  const newState = {...state, descriptive_size_amount_extra: [...state.descriptive_size_amount_extra, descriptiveSizeVariants[0]]};
                  setState(newState);
                }}> Extra fält för fler nettokvantiteter av samma produkt med samma EAN</a>
              </div>
            )}
      </>
  );
}

export default Descriptive;
