import { useEffect, useState } from "react";

import IProduct from "models/product";
import { IFoodlaCategoryOption } from "models/category";
import ListDropDown from "components/inputfields/ListDropDown";
import InputLabel from "components/inputfields/InputLabel";
import { TAX_CLASSIFICATION } from "components/constants-ts";
import { isBeverages, isMagazine, isSnusOrTobacco } from "./category";

interface IBasicDataProps {
  state: IProduct;
  setState: React.Dispatch<React.SetStateAction<IProduct>>;
  isFood: boolean;
  rootCategory?: IFoodlaCategoryOption;
}

const BasicData = ({ state, setState, isFood, rootCategory }: IBasicDataProps) => {
  const [mounted, setMounted] = useState(false);

  const isSnusOrTobaccoCategory = isSnusOrTobacco(state.foodlaCategory, rootCategory);
  const isMagazineCategory = isMagazine(state.foodlaCategory, rootCategory);
  const isBeveragesCategory = isBeverages(state.foodlaCategory, rootCategory);

  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      const stateValue = event.target.value;

      const value = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: value });
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    if (isFood) {
      if (isSnusOrTobaccoCategory) {
        setState(oldState => ({ ...oldState, basicDataTaxClassification: 25 }));
      } else if (isBeveragesCategory && state.alcoholPercentage && Number(state.alcoholPercentage) > 0) {
        setState(oldState => ({ ...oldState, basicDataTaxClassification: 25 }));
      } else {
        setState(oldState => ({ ...oldState, basicDataTaxClassification: 12 }));
      }
    } else {
      if (isMagazineCategory) {
        setState(oldState => ({ ...oldState, basicDataTaxClassification: 6 }));
      } else {
        setState(oldState => ({ ...oldState, basicDataTaxClassification: 25 }));
      }
    }
  }, [
    mounted,
    isFood,
    setState,
    isSnusOrTobaccoCategory,
    isMagazineCategory,
    isBeveragesCategory,
    state.alcoholPercentage,
  ]);

  return (
    <>
      <InputLabel
        heading="Produktens skattesats"
      />
      <ListDropDown
        disableLabel={true}
        listData={TAX_CLASSIFICATION}
        value={state?.basicDataTaxClassification}
        targetName="basicDataTaxClassification"
        handleChange={(e) => {
          handleDropdownChange(e);
        }}
      />
  </>
)};

export default BasicData;
