import _ from 'lodash';
import * as dateFns from 'date-fns';

import IProduct from 'models/product';
import { getCountryCode } from 'utils/helpers-ts';
import {
  PRODUCT_BOOLEAN_FIELD_VALUES,
  PRODUCT_ENUM_FIELD_VALUES,
  animalCategoriesForFeed,
  securityNotices,
  hazardStatements,
  sunProtectionFactor,
  categoryWeightloss,
  preparedForm,
  signalWords,
  recyclingType,
  eggWeightClass,
  catchArea,
  fishCatchMethod,
  fishProductionMethod,
  DESCRIPTIVE_SIZE_UNITS_MAP,
  NUTRITIONAL_UNITS_MAP,
  additionalCharacteristics,
  approvalDietFeed,
  complianceMetalsSkinContact,
  confirmGuidanceSwedishElectronics,
  complianceBiocid,
  complianceSunscreenRegulation,
  complianceBiocidalTreated,
  PRODUCT_CLASSIFICATIONS,
} from 'components/constants-ts';
import { checkDecimal } from 'components/product/ProductUpdateForm/fields/gross-dimensions';
import { productMarkings } from 'components/product/ProductUpdateForm/fields/labels';
import { hazardSymbols } from 'components/product/ProductUpdateForm/fields/hazard-fields';

import { IReviewColumn } from '../type';

export const convertStringToBoolean = (valueParam: string, field: keyof IProduct) => {
  if (_.isNil(valueParam)) return null;

  const value = _.toString(valueParam).trim().toLowerCase();

  if (field) {
    const booleanOption = PRODUCT_BOOLEAN_FIELD_VALUES[field]?.find(item => item.valueList.includes(value));
    if (booleanOption) return booleanOption.convertTo;
  }

  const booleanOption = PRODUCT_BOOLEAN_FIELD_VALUES.all.find(item => item.valueList.includes(value));
  if (booleanOption) return booleanOption.convertTo;

  return null;
};

export const convertBooleanValue = (column: IReviewColumn, data: IProduct) => {
  const checkingValue = getCellValue(column, data);
  if (column.type !== 'boolean' || checkingValue === undefined) return checkingValue;
  return convertStringToBoolean(String(checkingValue), column.field);
};

export const getEnumValue = (column: IReviewColumn, data: IProduct) => {
  const mapEnumValue = (
    enumDataList: { value: string; displayName: string }[],
    dataType: 'array' | 'string',
    options?: {
      mapValue?: (data: any) => string;
      mapReturnData?: (data: any) => any;
    }
  ) => {
    const { mapValue, mapReturnData } = options || {};

    if (_.isNil(data[column.field])) return;
    const value = mapValue ? mapValue(data[column.field]) : data[column.field];
    if (_.isNil(value)) return;

    const validEnumList: string[] = [];
    let checkingEnumValue = String(value).toLowerCase() as unknown as string;

    enumDataList.forEach(enumData => {
      if (checkingEnumValue.includes(enumData.displayName.toLowerCase())) {
        checkingEnumValue.replace(enumData.displayName.toLowerCase(), '');
        validEnumList.push(enumData.value);
      } else if (checkingEnumValue.includes(enumData.value.toLowerCase())) {
        checkingEnumValue.replace(enumData.value.toLowerCase(), '');
        validEnumList.push(enumData.value);
      }
    });

    if (dataType === 'array') {
      return mapReturnData ? mapReturnData(validEnumList) : validEnumList;
    } else if (dataType === 'string') {
      return mapReturnData ? mapReturnData(validEnumList[0]) : validEnumList[0];
    }
  };

  if (column.field === 'descriptive_size_unit') {
    const descriptiveSizeUnitsEnumList = DESCRIPTIVE_SIZE_UNITS_MAP.map(item => ({
      value: item.enum,
      displayName: item.value,
    }));
    return mapEnumValue(descriptiveSizeUnitsEnumList, 'string');
  }
  if (column.field === 'nutritional_unit') {
    const nutritionalUnitsEnumList = NUTRITIONAL_UNITS_MAP.map(item => ({
      value: item.enum,
      displayName: item.value,
    }));
    return mapEnumValue(nutritionalUnitsEnumList, 'string');
  }
  if (column.field === 'classification') {
    return PRODUCT_CLASSIFICATIONS.find(item => item.toLowerCase() === data.classification?.toLowerCase());
  }

  if (column.field === 'securityNotices') {
    const securityNoticesEnumList = securityNotices
      .reduce(
        (all, { listData }) => [
          ...all,
          ...listData.map(({ value }) => ({ value, displayName: value.replace('_', ' + ') })),
        ],
        [] as { value: string; displayName: string }[]
      )
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(securityNoticesEnumList, 'array');
  }
  if (column.field === 'hazardStatements') {
    const hazardStatementsEnumList = hazardStatements
      .reduce(
        (all, { listData }) => [
          ...all,
          ...listData.map(({ value }) => ({ value, displayName: value.replace('_', ' + ') })),
        ],
        [] as { value: string; displayName: string }[]
      )
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(hazardStatementsEnumList, 'array');
  }
  if (column.field === 'animalCategoriesForFeed') {
    return mapEnumValue(animalCategoriesForFeed, 'array');
  }
  if (column.field === 'labels') {
    const labelsEnumList = productMarkings.reduce(
      (all, { listData }) => [...all, ...listData],
      [] as { value: string; displayName: string }[]
    );
    return mapEnumValue(labelsEnumList, 'array', {
      mapReturnData: (list: string[]) => list.map(val => ({ field: val, state: true })),
    });
  }
  if (column.field === 'eggWeightClass') {
    const eggWeightClassEnumList = eggWeightClass
      .map(({ value }) => ({
        value,
        displayName: value.replace('_', '/'),
      }))
      .sort((prev, next) => (next.value.includes('_') ? 1 : 0) - (prev.value.includes('_') ? 1 : 0));
    return mapEnumValue(eggWeightClassEnumList, 'string');
  }
  if (column.field === 'hazardSymbols') return mapEnumValue(hazardSymbols, 'array');
  if (column.field === 'sunProtectionFactor') return mapEnumValue(sunProtectionFactor, 'string');
  if (column.field === 'categoryWeightloss') return mapEnumValue(categoryWeightloss, 'string');
  if (column.field === 'preparedForm') return mapEnumValue(preparedForm, 'string');
  if (column.field === 'signalWords') return mapEnumValue(signalWords, 'string');
  if (column.field === 'recyclingType') return mapEnumValue(recyclingType, 'string');
  if (column.field === 'catchArea') return mapEnumValue(catchArea, 'string');
  if (column.field === 'fishCatchMethod') return mapEnumValue(fishCatchMethod, 'string');
  if (column.field === 'fishProductionMethod') return mapEnumValue(fishProductionMethod, 'string');
  if (column.field === 'additionalCharacteristics') return mapEnumValue(additionalCharacteristics, 'string');
  if (column.field === 'approvalDietFeed') return mapEnumValue(approvalDietFeed, 'string');
  if (column.field === 'complianceMetalsSkinContact') return mapEnumValue(complianceMetalsSkinContact, 'string');
  if (column.field === 'confirmGuidanceSwedishElectronics')
    return mapEnumValue(confirmGuidanceSwedishElectronics, 'string');
  if (column.field === 'complianceBiocid') return mapEnumValue(complianceBiocid, 'string');
  if (column.field === 'complianceBiocidalTreated') return mapEnumValue(complianceBiocidalTreated, 'string');
  if (column.field === 'complianceSunscreenRegulation') return mapEnumValue(complianceSunscreenRegulation, 'string');

  const checkingValue = getCellValue(column, data);
  if (column.type !== 'enum' || _.isNil(checkingValue)) return checkingValue;

  const allowedValues = PRODUCT_ENUM_FIELD_VALUES[column.field]?.find(
    item => _.toString(item.value) === _.toString(checkingValue)
  );
  if (!allowedValues) return undefined;
  if (allowedValues) return allowedValues.convertTo || allowedValues.value;
  return null;
};

export const getCellValue = (column: IReviewColumn, data: IProduct) => {
  const { field } = column;
  if (column.field === 'foodlaCategory') {
    return data.foodlaCategory?.name;
  }
  if (column.type === 'date') {
    const value = data[field] as Date;
    if (_.isNil(value)) return null;
    return value;
  }
  if (!_.isNil(data[field])) return data[field] as string | number;
  return null;
};

export const isWrongTypeCell = (column: IReviewColumn, data: IProduct) => {
  const checkingValue = getCellValue(column, data);

  if (_.isNil(checkingValue)) return false;
  if (column.type === 'enum') {
    return _.isNil(getEnumValue(column, data));
  }
  if (column.type === 'date') {
    return !checkingValue || !dateFns.isDate(checkingValue as number);
  }
  if (column.type === 'number') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const regex = /^([-+]?\d+(?:[,.]\d+)?)$/gimu;
    const valueToString = _.toString(checkingValue).trim();
    return !regex.test(valueToString);
  }
  if (column.type === 'boolean') {
    return _.isNil(convertBooleanValue(column, data));
  }
  return false;
};

export const isRequiredCell = (column: IReviewColumn, data: IProduct) => {
  if (column.field === 'regulatedSubstance') {
    return convertStringToBoolean(
      data.reachSubstancesAnnex19072006 as unknown as string,
      'reachSubstancesAnnex19072006'
    );
  }
  if (column.field === 'informationHazardousSubstances') {
    return convertStringToBoolean(data.hazardousSubstancesOver1 as unknown as string, 'hazardousSubstancesOver1');
  }
  if (column.field === 'explanationNoUDI') {
    return !convertStringToBoolean(data.checkUniqueIdentification as unknown as string, 'checkUniqueIdentification');
  }
  if (column.field === 'CMRSubstances') {
    return convertStringToBoolean(data.cmrSubstance as unknown as string, 'cmrSubstance');
  }

  // Nutritional
  if (['fett', 'mattatFett', 'kolhydrat', 'sockerarter', 'protein', 'salt'].includes(column.field)) {
    const isHasKjValue = !_.isNil(data.energi_kj) && !!String(data.energi_kj).trim();
    const isHasKcalValue = !_.isNil(data.energi_kcal) && !!String(data.energi_kcal).trim();
    return isHasKjValue || isHasKcalValue;
  }

  // Signal Words
  if (['hazardSymbols', 'hazardStatements', 'securityNotices'].includes(column.field)) {
    return ['fara', 'varning'].includes(String(data.signalWords || '').toLowerCase());
  }

  return column.required;
};

export const isInvalidValueCell = (column: IReviewColumn, data: IProduct) => {
  const value = getCellValue(column, data);

  if (['gross_weight_num', 'gross_weight', 'gross_height', 'gross_width', 'gross_depth'].includes(column.field)) {
    return checkDecimal(value as string | number);
  }

  if (column.field === 'country_of_manufacturing_string') {
    return !getCountryCode(String(value || ''));
  }
  // not check when value is [null] or [undefined] or [empty]
  // -> this case should be check by [isEmptyCell]
  if (!_.isNil(value) && !!String(value) && column.type === 'enum') {
    const enumValue = getEnumValue(column, data);
    return _.isNil(enumValue);
  }
  return false;
};

export const isEmptyCell = (column: IReviewColumn, data: IProduct, options?: { byTitle?: boolean }) => {
  if (column.field === 'foodlaCategory') {
    return _.isNil(data.foodlaCategory);
  }
  const value = getCellValue(column, data);
  return _.isNil(value) || value === '';
};

export const isErrorCell = (column: IReviewColumn, data: IProduct) => {
  let result =
    isWrongTypeCell(column, data) ||
    isInvalidValueCell(column, data) ||
    (isRequiredCell(column, data) && isEmptyCell(column, data));

  if (!result) return false;

  return true;
};
