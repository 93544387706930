import { isDefined } from './helpers';
import { head } from 'ramda';
import isNil from 'lodash/isNil';

/**
 * Extracts and returns the wanted search param. Empty string returned if not found.
 *
 * @param {String} parameterName The search parameter to find in URL.
 * @param {String} url The URL in which to find the parameter.
 */
export const extractURLParam = (parameterName: string, url: string) => {
    try {
        const urlObj = new URL(url);
        const theParam = urlObj.searchParams.get(parameterName);

        return theParam || '';
    } catch (e) {
        return '';
    }
};

/**
 * Updates a parameters value or adds it as a new parameter.
 *
 * @param {String} parameterName To set value for
 * @param {String} value The value to set.
 * @param {Object} location the window.location object.
 */
// TODO: Don't take the location obj. Take the URL.
export const addOrChangeQueryParam = (parameterName: string, value: string | null, location: any) => {
    try {
        const urlArr = location.href.split('?');
        const origin = urlArr[0];
        const searchParams = urlArr[1];

        let searchParamString = '?';
        const allParams = searchParams ? searchParams.split('&') : [];

        // Url has search params and our parameter is included somewhere in the searchparams.
        if (searchParams && searchParams.includes(parameterName)) {
            for (let i = 0; i < allParams.length; i++) {
                const currentParam = allParams[i];
                searchParamString = currentParam.includes(parameterName)
                    ? searchParamString.concat(`${parameterName}=${value}`)
                    : searchParamString.concat(currentParam);
                if (i !== allParams.length - 1)
                    // More params to come, append &-sign.
                    searchParamString = searchParamString.concat('&');
            }
        }
        // Url has searchparams but wanted parameter name is not included.
        else if (searchParams && !searchParams.includes(parameterName)) {
            searchParamString = searchParamString.concat(`${searchParams}&${parameterName}=${value}`);
        }
        // Url doesn't have any search params.
        else {
            searchParamString = searchParamString.concat(`${parameterName}=${value}`);
        }
        return origin.concat(searchParamString);
    } catch (e) {
        return location?.href;
    }
};

/**
 * Pushes new URL to history. Does not refresh browser.
 *
 * @param {String} newUrl to push to state.
 * @param {Object} window The window object.
 */
export const pushToWindowHistory = (newUrl: string, window: any, caller = 'UNKNOWN') => {
    window.history.pushState({ path: newUrl, caller }, '', newUrl);
};

/**
 * Splits the URL by '?' and returns the first part of that array.
 *
 * @param {String} url
 */
export const removeUrlParams = (url: string) => {
    if (!isDefined(url)) return url;
    try {
        return head(url.split('?'));
    } catch (e) {
        return url;
    }
};

export const getBackendHost = (urlPath: string) => {
    const host = process.env.REACT_APP_B2B_SERVICES;
    if (host && host.trim() !== '') {
        const result = new URL(urlPath, host.trim());
        return result.toString();
    }
    return undefined;
};

export const getUrlParams = (url: string): Record<string, string> => {
  if (!url) return {};

  const urlObj = new URL(url);
  return Object.fromEntries(urlObj.searchParams);
};

export const updateUrlParams = (url: string, params: Record<string, string | number | undefined>) => {
  if (!url) return '';

  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    if (isNil(value)) {
      urlObj.searchParams.delete(key);  
    } else {
      urlObj.searchParams.set(key, value.toString());
    }
  });
  return urlObj.toString();
};